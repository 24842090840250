// aws-config.js

import AWS from 'aws-sdk';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_S3,
    secretAccessKey: process.env.REACT_APP_ACCESS_SECRET_KEY_S3,
    region: "us-east-1",
});

const s3 = new AWS.S3();

export default s3;



