import React, { useState, useEffect, useContext } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Button } from "@material-ui/core";
import numeral from "numeral";
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import approve from "../../assets/images/approve.svg"
import cancel from "../../assets/images/cancel.svg"
import { /* getAuthenticatedHeaders, */ FormatPhone, formatCurrency } from '../common/functions';
import moment from 'moment';
import { AiOutlineFilePdf } from "react-icons/ai";
import AxioInstance from '../../axios/AxiosConfig';


export default function ModalClientes({ id, open, modal }) {

    const [copiar, setCopiar] = useState(false)
    const [browserName, setBrowserName] = useState("");
    const [data, setData] = useState([]);

    const [showModal, setShowModal] = useState(open);
    const [sistemaOP, setSistemaOP] = useState("");

    const [color, setColor] = useState("");
    const [colorText, setColorText] = useState("");
    const { getAuthenticatedHeaders, others, isSuperUser } = useContext(AuthContext)


    useEffect(() => {
        DataRequest(id)

    }, [])

    const DataRequest = async () => {
        try {
            const response = await AxioInstance.post(`/api/profile/detail`, {
                headers: await getAuthenticatedHeaders(),
                id: id
            });

            if (response.data.status === 200) {
                setData(response.data.data.detail_profile)

            } else {
                return []
            }
        } catch (error) {

            return []

        }
    }

    const title = `REF-${id}`;

    const today = new Date();
    var ampm = today.getHours() >= 12 ? 'pm' : 'am';


    function formatDate(x) {
        let currentTimestamp = x - 18000000

        /* let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(currentTimestamp) */
        let date = moment(x).format('DD/MM/YYYY HH:mm A')

        return date;
    }
    const bankName = (id) => {

        let banco = ''
        if (id == 0) {
            banco = 'Bancolombia'
        } else if (id == 1) {
            banco = 'Banco Bogota'
        } else if (id == 2) {
            banco = ' Davivienda'
        } else if (id == 3) {
            banco = 'BBVA'
        } else if (id == 4) {
            banco = 'Banco Occidente'
        } else if (id == 5) {
            banco = 'Colpatria'
        } else if (id == 6) {
            banco = 'Banco Popular'
        } else if (id == 7) {
            banco = 'Helm Bank'
        } else if (id == 8) {
            banco = 'Banco Agrario'
        } else if (id == 9) {
            banco = 'Banco BCSC'
        } else if (id == 10) {
            banco = 'Banco CorpBanca'
        } else if (id == 11) {
            banco = 'Banco Citibank'
        } else if (id == 12) {
            banco = 'Banco AV Villas'
        } else if (id == 13) {
            banco = 'Banco ProCredit'
        } else if (id == 14) {
            banco = 'Banco Pichincha'
        } else if (id == 15) {
            banco = 'Banco Bancoomeva'
        } else if (id == 16) {
            banco = 'Banco Santander'
        } else if (id == 17) {
            banco = 'Banco Falabella'
        } else if (id == 18) {
            banco = 'Nequi'
        } else if (id == 19) {
            banco = 'Banco DaviPlata'
        } else if (id == 20) {
            banco = 'Banco Finandina'
        } else if (id == 21) {
            banco = 'Banco Itaú'
        } else if (id == 22) {
            banco = 'Banco Itaú CorpBanca'
        } else if (id == 23) {
            banco = 'Lulo Bank'
        } else if (id == 24) {
            banco = 'Coltefinanciera'
        } else if (id == 25) {
            banco = "Ualá"
        } else if (id == 26) {
            banco = "Banco Cooperativo Coopcentral"
        } else if (id == 27) {
            banco = "Rappi pay"
        }
        return banco
    }
    const imgBank = (item) => {
        let image = ''

        if ((item) === ('Bancolombia') || bankName(item) === ('Bancolombia')) image = require("../../assets/payment_methods/bancolombia.png");
        if ((item) === ('Banco Bogota') || bankName(item) === ('Banco Bogota')) image = require("../../assets/payment_methods/bancobogota.jpg");
        if ((item) === ('Davivienda') || bankName(item) === ('Davivienda')) image = require("../../assets/payment_methods/bancodavivienda.png");
        if ((item) === ('BBVA') || bankName(item) === ('BBVA')) image = require("../../assets/payment_methods/bbva.png");
        if ((item) === ('Banco Occidente') || bankName(item) === ('Banco Occidente')) image = require("../../assets/payment_methods/bancooccidente.png");
        if ((item) === ('Colpatria') || bankName(item) === ('Colpatria')) image = require("../../assets/payment_methods/colpatria.png");
        if ((item) === ('Banco Popular') || bankName(item) === ('Banco Popular')) image = require("../../assets/payment_methods/bancopopular.png");
        if ((item) === ('Helm Bank') || bankName(item) === ('Helm Bank')) image = require("../../assets/payment_methods/bancohelm.png");
        if ((item) === ('Banco Agrario') || bankName(item) === ('Banco Agrario')) image = require("../../assets/payment_methods/bancoagrario.png");
        if ((item) === ('Banco BCSC') || bankName(item) === ('Banco BCSC')) image = require("../../assets/payment_methods/bancocajasocial.png");
        if ((item) === ('Banco CorpBanca') || bankName(item) === ('Banco CorpBanca')) image = require("../../assets/payment_methods/corpbanca.png");
        if ((item) === ('Banco Citibank') || bankName(item) === ('Banco Citibank')) image = require("../../assets/payment_methods/citibank.jpg");
        if ((item) === ('Avvillas') || bankName(item) === ('Banco AV Villas')) image = require("../../assets/payment_methods/bancoavvillas.png");
        if ((item) === ('Banco ProCredit') || bankName(item) === ('Banco ProCredit')) image = require("../../assets/payment_methods/bancoprocredit.png");
        if ((item) === ('Banco Pichincha') || bankName(item) === ('Banco Pichincha')) image = require("../../assets/payment_methods/bancopichincha.png");
        if ((item) === ('Banco Bancoomeva') || bankName(item) === ('Banco Bancoomeva')) image = require("../../assets/payment_methods/bancoomeva.jpg");
        if ((item) === ('Banco Santander') || bankName(item) === ('Banco Santander')) image = require("../../assets/payment_methods/bancosantander.png");
        if ((item) === ('Banco Falabella') || bankName(item) === ('Banco Falabella')) image = require("../../assets/payment_methods/bancofalabella.png");
        if ((item) === ('Nequi') || (item) === ('Nequi')) image = require("../../assets/payment_methods/nequi.png");
        if ((item) === ('Banco DaviPlata') || bankName(item) === ('Banco DaviPlata')) image = require("../../assets/payment_methods/bancodaviplata.png");
        if ((item) === ('Banco Finandina') || bankName(item) === ('Banco Finandina')) image = require("../../assets/payment_methods/bancofinandina.png");
        if ((item) === ('Banco Itaú') || bankName(item) === ('Banco Itaú')) image = require("../../assets/payment_methods/itau.png");
        if ((item) === ('Banco Itaú CorpBanca') || bankName(item) === ('Banco Itaú CorpBanca')) image = require("../../assets/payment_methods/corpbanca.png");
        if ((item) === ('Ualá') || bankName(item) === ('Ualá')) image = require("../../assets/images/uala.png");
        if ((item) === ('Rappipay') || bankName(item) === ('Rappi pay')) image = require("../../assets/payment_methods/rappiPay.png");
        if ((item) === ('deposito') || bankName(item) === ('deposito')) image = require("../../assets/images/pagos-gde.png");

        return (image)
    }
    const copy = (text) => {

        var id = "el-id-del-textarea";
        var existsTextarea = document.getElementById(id);

        if (!existsTextarea) {

            var textarea = document.createElement("textarea");
            textarea.id = id;
            // Coloca el textarea en el borde superior izquierdo
            textarea.style.position = 'fixed';
            textarea.style.top = 0;
            textarea.style.left = 0;
            textarea.style.textTransform = "lowercase"
            // Asegurate que las dimensiones del textarea son minimas, normalmente 1px 
            // 1em no funciona porque esto generate valores negativos en algunos exploradores
            textarea.style.width = '1px';
            textarea.style.height = '1px';

            // No se necesita el padding
            textarea.style.padding = 0;

            // Limpiar bordes
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';

            // Evitar el flasheo de la caja blanca al renderizar
            textarea.style.background = 'transparent';
            document.querySelector("body").appendChild(textarea);

            existsTextarea = document.getElementById(id);
        } else {

        }

        existsTextarea.value = text;
        existsTextarea.select();

        try {
            var status = document.execCommand('copy');
            if (!status) {

            } else {
                setCopiar(true)
            }
        } catch (err) {

        }

    }
    const typeDocument = (id) => {
        let type = ''
        if (id == 0) {
            type = 'CC'
        } else if (id == 1) {
            type = 'CE'
        } else if (id == 2) {
            type = 'TI'
        } else if (id == 3) {
            type = 'NIT'
        } else if (id == 4) {
            type = 'PSP'
        }
        return type
    }
    function CalcularDv(nit) {

        var arreglo,
            x,
            y,
            z,
            i,
            nit1,
            dv1;
        nit1 = nit.toString()
        if (isNaN(nit1)) {
            document.form1.dv.value = "X";
            alert('Numero del Nit no valido, ingrese un número sin puntos, ni comas, ni guiones, ni espacios');
        } else {
            arreglo = new Array(16);
            x = 0;
            y = 0;
            z = nit1.length;
            arreglo[1] = 3;
            arreglo[2] = 7;
            arreglo[3] = 13;
            arreglo[4] = 17;
            arreglo[5] = 19;
            arreglo[6] = 23;
            arreglo[7] = 29;
            arreglo[8] = 37;
            arreglo[9] = 41;
            arreglo[10] = 43;
            arreglo[11] = 47;
            arreglo[12] = 53;
            arreglo[13] = 59;
            arreglo[14] = 67;
            arreglo[15] = 71;
            for (i = 0; i < z; i++) {
                y = (nit1.substr(i, 1));
                x += (y * arreglo[z - i]);

            }
            y = x % 11
            if (y > 1) {
                dv1 = 11 - y;
            } else {
                dv1 = y;
            }

            return dv1;
        }
    }

    const typeDocumentPhoto = (id) => {
        let type = ''
        if (id == 1) {
            type = 'Cédula (Frente)'
        } else if (id == 2) {
            type = 'Cédula (Reverso)'
        } else if (id == 3) {
            type = 'RUT'
        } else if (id == 4) {
            type = 'Cédula (Frente)	'
        } else if (id == 5) {
            type = 'Cédula (Reverso)'
        } else if (id == 6) {
            type = 'RUT'
        } else if (id == 7) {
            type = 'Cámara de Comercio'
        } else if (id == 12 || id == 13) {
            type = 'Selfie'
        }
        return type
    }
    const validateCedula = (docs) => {
        const cedula = docs?.filter(item => {
            return (item?.type_document == 1 || item?.type_document == 2 || item?.type_document == 4 || item?.type_document == 5) && item?.document_status === 2
        })
        return cedula?.length >= 2
    }
    const getPhotoProfile = () => {
        function verificaFormatoURL(url) {
            var expresionRegular = /uploads\/.*\.jpg$/;
            return expresionRegular.test(url);
        }

        if (verificaFormatoURL(data?.profile_picture)) {
            return data?.profile_picture;
        }

        let urlSelfie = data?.urls_documents?.find(item => item?.type_document === 12 || item?.type_document === 13)?.url;

        return urlSelfie || false;
    };

    return (
        <>
            {showModal && data.length !== 0 ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">


                        <div className="relative w-80 my-6 mx-auto  ">

                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => modal(false)}
                                >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                                <div className="relative px-6 pb-1 flex-auto">
                                    {copiar && <div className="copiado-modal">Copiado

                                    </div>}

                                    <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
                                        {title}
                                    </h3>
                                    <div className=" justify-between  flex-row">
                                        {
                                            <div className='flex flex-row  items-center py-3 justify-between' >
                                                <div className='flex flex-row '>
                                                    {/*                                                     {data.profile_picture && <img style={{ width: 100, height: 100, marginRight: 20, borderRadius: 50 }} src={data.profile_picture} />}
 */}

                                                    {getPhotoProfile()
                                                        ?
                                                        <a href={getPhotoProfile()} style={{ width: 100, height: 100, marginRight: 20, borderRadius: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='bg-gray-600 overflow-hidden'>
                                                            <img style={{ width: '100%', height: '100%' }} src={getPhotoProfile()} />
                                                        </a>
                                                        :
                                                        <div style={{ width: 100, height: 100, marginRight: 20, borderRadius: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='bg-gray-600'>
                                                            <p className='text-white' style={{ fontSize: 56 }} >{data?.user.first_name?.charAt(0)}{data?.user.last_name?.charAt(0)}</p>
                                                        </div>}
                                                    <div>
                                                        <p className='text-gray-700 text-xs'>Nombre</p>
                                                        <p className="text-gray-700 text-base">{data.user.first_name} {data.user.last_name}</p>
                                                        <p className='text-gray-700 text-xs'>usuario</p>
                                                        <p className="text-gray-700 text-base">{(data.user.username)}</p>

                                                    </div>
                                                </div>
                                                <div className={`border-green-500`} style={{ borderWidth: 1.5 }}>
                                                    <p className={` text-green-500 px-2 py-1 text-sm ml-0`}>{"NIVEL"} {data.level}</p>
                                                </div>
                                            </div>}

                                    </div>
                                    <hr />
                                    <div className="my-4 py-2 mb-1 text-gray-600 text-lg leading-relaxed  border-solid text-gray-700 rounded">


                                        <div className="flex items-center px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                Documento
                                            </p>
                                            <button style={{ width: "70%" }} className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor items-center flex justify-end">
                                                <img className='mr-1' src={validateCedula(data.urls_documents) ? approve : cancel} width={15} height={15} />
                                                {typeDocument(data.document_type)} {data.document_number}
                                            </button>
                                        </div>

                                        <div className="flex items-center px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                Celular
                                            </p>
                                            <button style={{ width: "70%" }} className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor items-center flex justify-end">
                                                <img className='mr-1' src={data.phone_verified ? approve : cancel} width={15} height={15} /> {FormatPhone(data.phone)}
                                            </button>
                                        </div>
                                        <div className="flex items-center px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                Correo
                                            </p>
                                            <button style={{ width: "70%" }} className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor items-center flex justify-end">
                                                <img className='mr-1' src={data.phone_verified ? approve : cancel} width={15} height={15} /> {(data.user.email)}
                                            </button>
                                        </div>

                                        {data.urls_documents.length > 0 && data.urls_documents.map((item, index) => (
                                            <div className="flex items-center px-1 leading-7 margin-10px">
                                                <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                    {typeDocumentPhoto(item.type_document)}
                                                </p>
                                                <button style={{ width: "70%" }} className="rounded-full text-sm w-6/12 text-right button-outline-off  items-center flex justify-end">
                                                    {item.type_document === 3 || item.type_document === 6 || item.type_document === 7 ?
                                                        <div className="flex flex-row">

                                                            <img className='mr-1' src={item.document_status === 2 ? approve : cancel} width={15} height={15} />
                                                            <a target='_blank' href={item.url}>
                                                                <p className='links'>Evidencia</p>
                                                            </a>

                                                        </div>
                                                        :
                                                        <div className="flex flex-row">

                                                            <img className='mr-1' src={item.document_status === 2 ? approve : cancel} width={15} height={15} />
                                                            <a target='_blank' href={item.url}>
                                                                <p className='links'>Evidencia</p>
                                                            </a>
                                                        </div>
                                                    }
                                                </button>

                                            </div>
                                        ))}
                                        <div className="flex items-center px-1 leading-7 margin-10px">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                IP
                                            </p>
                                            <button style={{ width: "70%" }} className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor ">
                                                {data.ip}
                                            </button>

                                        </div>
                                        {
                                            data?.referal_code && data?.referal_code !== ''
                                                ?
                                                <div className="flex items-center px-1 leading-7 margin-10px">
                                                    <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                        Referido
                                                    </p>
                                                    <button style={{ width: "70%" }} className="rounded-full text-sm w-6/12 text-right button-outline-off copy-cursor ">
                                                        {data?.referal_code}
                                                    </button>

                                                </div>
                                                :
                                                null
                                        }
                                        <hr />

                                        {<div className="flex items-center px-1 leading-7 margin-10px margin-top-10px">
                                            <p className="rounded-full mr-4 text-sm w-6/12 text-left">
                                                Creación
                                            </p>
                                            <p className="rounded-full text-sm w-6/12 text-right">
                                                {moment(data.date_created).format('YYYY-MM-DD HH:mm:ss A')}
                                            </p>
                                        </div>}



                                    </div>
                                </div>
                                <hr style={{ width: 480, background: 'white', borderColor: 'white' }} />
                                {<div className=" justify-center items-center">
                                    <p className=" text-center py-4 text-gray-700  text-sm">{ }</p>
                                </div>}
                                <div className="text-2xl centrar-contenido">

                                </div>
                                <div className="items-center justify-end p-2 border-gray-300 rounded-b">
                                    <p className="text-gray-700 text-center text-xs font-hairline">

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" fixed inset-0 z-40 " style={{
                        background: 'rgba(47, 46, 44, 0.5)',
                        backdropFilter: 'blur(10px)',
                    }}></div>
                </>
            ) : <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">


                    <div className="relative w-pop-up my-6 mx-auto max-w-md">

                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => modal(false)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    ×
                                </span>
                            </button>
                            <div className="relative px-6 pb-1 flex-auto">
                                {copiar && <div className="copiado-modal">Copiado

                                </div>}

                                <h3 className="text-3xl font-bold text-trixel-popup centrar-contenido">
                                    {title}
                                </h3>
                                <div style={{ padding: '24px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                    {
                                        <CircularProgress color="red" size={75} />
                                    }
                                </div>



                            </div>
                        </div>
                    </div>
                </div>


                <div className=" fixed inset-0 z-40 " style={{
                    background: 'rgba(47, 46, 44, 0.5)',
                    backdropFilter: 'blur(10px)',
                }}></div>


            </>
            }
        </>
    );
}
