import React, { useContext, } from 'react'
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

// views
import Resumen from '../../containers/pages/Resumen';
import Transacciones from '../../containers/pages/Transacciones';
import Retirar from '../../containers/pages/Retirar';
import Reporte from '../../containers/pages/Reporte';
import Colaboradores from '../../containers/pages/Colaboradores';
import Ayuda from '../../containers/pages/Ayuda';
import Integracion from '../../containers/pages/Integracion';
import Consultar from '../../containers/pages/Consultar';
import Recarga from "../../containers/pages/Recarga"
import Documentos from "../../containers/pages/Documentos"
import Validacion from "../../containers/pages/Validacion"
import Clientes from "../../containers/pages/Clientes"
import ReportesDate from "../../containers/pages/ReportesDate"
import Debitar from "../../containers/pages/Debitar"
import BlackList from "../../containers/pages/BlackList"
import Cambios from "../../containers/pages/Cambios"


import MutualSidebar from '../Sidebar/MutualSidebar';
import Nav from '../NavigationBar';
import Enviar from '../../containers/pages/Enviar';
import Operacion from '../../containers/pages/Operacion';
import Retiros from '../../containers/pages/Retiros';
import CargarUsuarios from '../../containers/pages/CargarUsuarios';
import { AuthContext } from '../../context/AuthContext';
import Alertas from '../../containers/pages/Alertas';
import Tarifas from "../../containers/pages/Tarifas"
import ClientesMutu from '../../containers/pages/mutu/ClientesMutu';
import EnviosMutu from '../../containers/pages/mutu/EnviosMutu';
import RetirosMutu from '../../containers/pages/mutu/RetirosMutu';
import RecargasMutu from '../../containers/pages/mutu/RecargasMutu';
import ReporteMutu from '../../containers/pages/mutu/ReporteMutu';


const NoMatch = () => {
    let location = useLocation();
    return (
        <div className="flex md:flex-row flex-wrap p-4">
            <div className="w-full bg-white p-4 text-center ">
                No encontramos nada para <code>{location.pathname}</code>
            </div>
        </div>
    );
}

const Mutual = (props) => {
    let { path, url } = useRouteMatch();
    const { isSuperUser, isOperador } = useContext(AuthContext)
    const user = props.user;
    const pathname = props.pathname

    const cobrus = props.cobrus;
    const totalBalance = props.totalBalance;
    const isCanViewDashboard = props.user.mother_account ? props.user.permission_dashboard : true;
    const isCanViewCobrus = props.user.mother_account ? props.user.permission_cobru : 3;
    const isCanViewWithdraw = props.user.mother_account ? props.user.permission_withdraw : 3;
    const isCanViewQuery = props.user.mother_account ? props.user.permission_query : true;
    const isCanViewReport = props.user.mother_account ? props.user.permission_reports : true;
    const isCanViewUser = props.user.mother_account ? props.user.permission_users : true;
    const pendingTasks = props.pendingTasks;



    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full md:w-1/15 bg-trixel-sidebar">
                    <MutualSidebar user={user} pathname={pathname} superUser={props.isSuperUser} />
                </div>
                <div className="w-full md:w-1/84 bg-trixel-content p-2">
                    <Nav user={user} pathname={pathname} />
                    <Switch>
                        <Route exact path="/"  >
                            <Redirect to={"/documentos"} />
                        </Route>
                        <Route exact path="/documentos"  >
                            <Documentos user={user} brand={'MUTUAL'} />
                        </Route>
                        <Route exact path="/cambios">
                            <Cambios user={user} />
                        </Route>
                        <Route path="/asociados"  >
                            <ClientesMutu cobrus={cobrus} totalBalance={totalBalance} pendingTasks={pendingTasks} canViewCobrus={isCanViewCobrus} />
                        </Route>
                        <Route exact path="/envios">
                            <EnviosMutu user={user} />
                        </Route>
                        <Route exact path="/retiros">
                            <RetirosMutu user={user} />
                        </Route>
                        <Route exact path="/recargas">
                            <RecargasMutu user={user} />
                        </Route>
                        <Route exact path="/reportes">
                            <ReporteMutu user={user} />
                        </Route>
                        <Route path="*" component={() => <NoMatch />} />
                    </Switch>
                </div>
            </div>
        </>
    )
}

export default Mutual;
