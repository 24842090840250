import React, { useState, useEffect, useContext, useRef } from 'react'
import cobruNew from "../../assets/images/iconSolo.png"
const Loading = (props) => {
    return (
        <>
            <div className="flex justify-center items-center w-full h-screen">
                <img src={cobruNew} />
            </div>
        </>
    )
}

export default Loading
