import React, { useState, useEffect, useContext, useRef } from 'react'
import Numeral from "numeral";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { FunctionContext } from "../../context/FuntionsContext";

import { TitleCase, FormatAmount } from "../common/functions"
import S3 from "react-aws-s3"
import { FormatPhone } from "../common/functions"
import moment from 'moment';
import Banner from '../../components/Banner';
import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BsCheckCircle } from "react-icons/bs";
import AxioInstance from '../../axios/AxiosConfig';

import ModalEvidencia from "../layouts/modalEvidencia"

import { RiCloseCircleFill } from "react-icons/ri";
const Recargar = (props) => {
    const [currentCobrus, setCurrentCobrus] = useState([]);
    const [State, setState] = useState(null);
    const { isSuperUser } = useContext(AuthContext)
    const [imagen, setImagen] = useState(null)
    const [pagination, setPagination] = useState(true);
    const { getAuthenticatedHeaders, permissions } = useContext(AuthContext);
    const { setShowModalRecarga, showModalRecarga } = useContext(FunctionContext);
    const [monedas,] = useState([
        {
            name: "COP",
            id: "COP",
        },
        {
            name: "USD",
            id: "USD",
        },
    ]);
    const [moneda, setMoneda] = useState("COP")


    const [number, setNumber] = useState(0)
    const [user, setUser] = useState("");
    const [data, setData] = useState();
    const fileInput = useRef(null)
    const [isActiveFilter, setIsActiveFilter] = useState(true);
    const [statusCobru, setStatusCobru] = useState(null);
    const [Modal, setModal] = useState(false)

    const [pending, setPending] = useState(false);
    const [msg, setMsg] = useState(false);


    const [bankAccount, setBankAccount] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const [showBannerAccion, setShowBannerAccion] = useState(false)
    const [description, setDescription] = useState("")
    const [cantidadCaracter, setCantidadCaracter] = useState(0)
    const [loadingAction, setLoadingAction] = useState(false)
    const [error, setError] = useState("");
    const [urlimg, setUrlimg] = useState("");
    const [bankAccounts, setBankAccounts] = useState([]);
    const [valor, setValor] = useState(0)
    const [img, setImg] = useState(null)
    const [showBanner, setShowBanner] = useState(false)
    const [errorBanner, setErrorBanner] = useState(null)
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState(props.user.username);
    const [agregar, setAgregar] = useState(null)
    const [id, setId] = useState(null)
    const [errorBannerAccion, setErrorBannerAccion] = useState(null)
    const caracteres = (e) => {

        setDescription(e)
        setCantidadCaracter(e.length)
    }
    const Confirmar = (tipo, pk) => {

        setAgregar(tipo)
        setShowModal(true)
        setId(pk)
    }

    useEffect(() => {
        renderDataTable()
        getUserListBanks()
    }, [])
    const checkUserPassword = async () => {

        if (!password) {
            return;
        }
        const data = {
            username: props.user.username,
            password: password
        }
        AxioInstance.post(`/api/login`, { data }, {
            headers: await getAuthenticatedHeaders(),
        })
            .then(response => {
                if (response.data.status === 200) {
                    aceptarOeliminar()
                    setShowModal(false);
                    setLoadingAction(true)
                } else {
                    setShowModal(true);
                    setError("Contraseña incorrecta");
                }
            })
    }
    const handleFilter = (event) => {
        let filter = event.currentTarget.value;
        let items = 0;
        let activeFilter = true;
        setStatusCobru(filter)
        setState(filter)
        setCurrentCobrus(items);
        setStatusCobru(filter);
        setIsActiveFilter(activeFilter);
        renderDataTable(filter)
    };

    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };
    const handleChangeWithDrawAmount = (amountValue) => {

        let amount = parseInt(
            amountValue.target.value.toString().replace(/\D/g, "")
        );
        setValor(amount)
        let withdrawAmount = Numeral(amount).format("0,0[.]00").replace(/,/g, ".");
        setNumber(withdrawAmount);
    };
    const getUserListBanks = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_banks/`, {
                headers: await getAuthenticatedHeaders(),
            });

            if (response.data.status === 200) {
                let data = response.data.data;
                data.push({ id: 999, name: "Crypto" })
                setBankAccounts(data);
                setBankAccount(data[0].name);
            }
        } catch (error) {

        }
    };
    const consultar = async (e) => {
        e.preventDefault();
        setLoading(true)

        const bank = bankAccount.toString().replace(/\D/g, "")
        let file = fileInput.current.files[0];
        let newFileName = `${user.toString().replace(/\D/g, "")}/${number}/${bank}/${moment().format("YYYY-MM-DD")}`

        const config = {
            bucketName: 'cobru-evidences',
            region: 'ca-central-1',
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_S3,
            secretAccessKey: process.env.REACT_APP_ACCESS_SECRET_KEY_S3,

        }


        const ReactS3Client = new S3(config)
        ReactS3Client
            .uploadFile(file, newFileName)
            .then(data => {
                setUrlimg(data.location)

                consultarapi(data.location)
            }

            )
            .catch(err => {

            }
            )
    };
    const optionValueSelectMoneda = () => {
        let items = []

        for (let i = 0; i < monedas.length; i++) {

            items.push(
                <option key={monedas[i].id}
                    value={(monedas[i].name)}>
                    {(monedas[i].name)}
                </option>
            );
        }
        return items;
    };
    const consultarapi = async (img) => {


        const data = {
            superuser: false,
            amount: valor,
            bank_withdraw: bankAccount,
            url_withdraw: urlimg != "" ? urlimg : img,
            description_withdraw: "Recarga Billetera Cobru",
            currency_code: moneda
        }
        try {
            const response = await AxioInstance.post(`/api/withdraw_manual/`, {
                headers: await getAuthenticatedHeaders(),
                data
            });



            if (response.data.status === 200) {
                let data = response.data.data;

                setShowBanner(true)
                setErrorBanner(false)

                setMsg(`Recarga solicitada, en 24h un operador validará y aprobará`)
                renderDataTable()


            } else if (response.data && response.data.data.error == "['Bad cobru amount']") {
                setShowBanner(true)
                setErrorBanner(true)
                setMsg('Monto invalido')
            } else {
                setShowBanner(true)
                setErrorBanner(true)
                setMsg('El usuario no se encontró')
            }



        } catch (error) {
            setShowBanner(true)
            setErrorBanner(true)
        }
        setLoading(false)
        setImg(null)
        setValor(0)
        setUrlimg("")
        setBankAccount("")
        setUser("")
        setMoneda("COP")
        fileInput.current.value = ''
        setNumber(0)
        setDescription("")
        setShowModalRecarga(false)
    };

    const renderDataTable = async (x) => {
        setIsLoading(true)
        setPending(true)
        try {
            const response = await AxioInstance.post(`/api/lista_recarga/`, {
                superuser: isSuperUser,
                state: x,
                headers: await getAuthenticatedHeaders(),

            });



            if (response.data.status === 200) {
                let data = response.data.data.data;

                setData(data)
            }
        } catch (error) {

        }
        setPending(false)
        setIsLoading(false)
    };
    const aceptarOeliminar = async () => {

        const data = {
            cobru_id: id,
            approved: agregar
        }
        try {
            const response = await AxioInstance.post(`/api/status_withdraw_manual/`, {
                headers: await getAuthenticatedHeaders(),
                data

            });



            if (response.data.status === 200) {
                renderDataTable()
                setErrorBannerAccion(false)
                setShowBannerAccion(true)

            } else {
                setErrorBannerAccion(true)
                setShowBannerAccion(true)
            }
        } catch (error) {

        }

        setLoadingAction(false)
    };

    const handleChangeBanks = (event) => {
        event.preventDefault();


        setBankAccount(event.target.value);

    };
    const changeBgColor = (state) => {
        let color = ''
        if (state === 0) {
            color = 'bg-yellow-500'
        } else if (state === 1) {
            color = 'bg-yellow-500'
        } else if (state === 2) {
            color = 'Color-red'
        } else if (state === 3) {
            color = 'bg-green-500'
        } else if (state === 4) {
            color = 'Color-red'
        } else if (state === 5) {
            color = 'Color-red'
        } else if (state === 6) {
            color = 'Color-red'
        }
        return color
    }
    const optionValueSelectBank = () => {
        let items = []

        for (let i = 0; i < bankAccounts.length; i++) {

            items.push(
                <option key={bankAccounts[i].id}
                    value={TitleCase(bankAccounts[i].name)}>
                    {TitleCase(bankAccounts[i].name)}
                </option>
            );
        }
        return items;
    };
    const columns = [{
        name: 'REF',
        grow: 1,
        cell: row => (
            <div className={'flex-row flex items-center justify-start'}>
                <div
                    title={row.state === 1
                        ? "Pendiente"
                        : row.state === 2
                            ? "Rechazado"
                            : row.state === 3
                                ? "Aprobado"
                                : ""
                    }
                    className={changeBgColor(row.state) + ' rounded-full h-4 w-4 bg-green mr-3'}
                >

                </div>
                <div
                    style={{ fontWeight: 700 }}>
                    <p className={!!row.payer_redirect_url ? "links cursor-pointer" : "notlinks"} onClick={() => AbrirModal(row.payer_redirect_url)}>
                        {row.pk}
                    </p>
                </div>
            </div>),
        sortable: true,
    },
    /* {
        name: 'User',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (
            <div>{row.owner}</div>
        )
    }, */
    {
        name: 'Banco',
        grow: 1,
        //selector: 'bank_account',
        sortable: true,
        cell: row => (


            <button>{row.bank_withdraw}</button>
        )
    },
    {
        name: 'Moneda',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (
            <p >{row.currency_code}</p>
        )
    },
    {
        grow: 1,
        name: 'Monto',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (
            <div>{FormatAmount(row.amount)}</div>

        )
    },

    /* {
        name: 'MONTO',
        cell: row => <div>{FormatAmount(row.amount)}</div>,
        sortable: true,
    }, */

    {
        grow: 1,
        name: 'Fecha',
        cell: row => (
            <div>{moment(row.date_created).format('YYYY-MM-DD HH:mm:ss A')}</div>

        ),
        //cell: row => <div>{moment(row.date).format('YYYY-MM-DD HH:mm:ss A')}</div>,
        sortable: true,
    },





    ];
    const Circular = () => (
        <div className="flex items-center justify-center">
            {
                <CircularProgress size={75} />
            }
        </div>
    )
    const AbrirModal = (e) => {

        if (e === null) {
            setShowBanner(true)
            setErrorBanner(true)
            setMsg('No hay ninguna evidencia')
        }
        else {
            setImagen(e)
            setModal(true)


        }
    }

    const handleChangeMoneda = (event) => {
        event.preventDefault();


        setMoneda(event.target.value);

    };

    return (
        <>
            {showModalRecarga &&
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                        <div className="relative w-pop-up my-6 mx-auto max-w-md">

                            <div className=" flex bg-blue-400 h-1/3x md:w-full  rounded border-solid shadow-lg  " style={{ background: "#ffffff" }}>
                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none right-0 top-0 absolute"
                                    onClick={() => setShowModalRecarga(!showModalRecarga)}
                                >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        ×
                                    </span>
                                </button>
                                <div className="w-full p-4 ">
                                    <p className="text-center text-xl bold mb-2" style={{ color: "#777777" }} >Datos para transferir</p>
                                    <div className="w-full ">
                                        <div className="flex flex-row justify-between mb-2">
                                            <p style={{ color: "#777777" }}>Banco</p>
                                            <p>Bancolombia</p>

                                        </div>
                                        <div className="flex flex-row  justify-between  mb-2">
                                            <p style={{ color: "#777777" }}>Tipo</p>
                                            <p>Corriente</p>

                                        </div>

                                        <div className="flex flex-row  justify-between  mb-2">
                                            <p style={{ color: "#777777" }}>Cuenta</p>
                                            <p>773-000012-95</p>

                                        </div>
                                        <hr className="" />
                                        <div className="flex flex-row  justify-between  mb-2 mt-2">
                                            <p style={{ color: "#777777" }}>Nombre</p>
                                            <p>Cobru SAS</p>

                                        </div>
                                        <div className="flex flex-row  justify-between mb-2">
                                            <p style={{ color: "#777777" }}>NIT</p>
                                            <p>901.278.890-0</p>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-span-2   mt-2 ">
                                <div className=" flex bg-blue-400 h-1/3x md:w-full  rounded border-solid shadow-lg  " style={{ background: "#ffffff" }}>

                                    <form className="w-full " >
                                        {/* {isSuperUser == true &&
                                <div className=" mx-3 my-6">
                                    <label className="mx-3">Usuario</label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        placeholder="(301) 548-3222"
                                        pattern="[0-9]*"
                                        value={FormatPhone(user)}
                                        onChange={(e) => setUser(e.target.value)}
                                    />



                                </div>
                            } */}

                                        <div className=" mx-3 my-6">
                                            <label className="mx-3">Banco desde donde transferiste</label>
                                            <select
                                                className="flex  w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 pl-3 "
                                                name={bankAccount}
                                                defaultValue=""
                                                value={bankAccount}
                                                custom={bankAccount.toString()}
                                                onChange={handleChangeBanks}
                                            >
                                                <option hidden value="">Seleccionar</option>
                                                {bankAccounts.length <= 0 ? (
                                                    <option value={bankAccount}>
                                                        No hay cuentas registradas
                                                    </option>
                                                ) : (
                                                    optionValueSelectBank()
                                                )}
                                                ;
                                            </select>
                                        </div>
                                        <div className=" mx-3 my-6">
                                            <label className="mx-3">Moneda</label>
                                            <select
                                                className="flex  w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 pl-3 "
                                                name={moneda}
                                                defaultValue=""
                                                autoFocus={false}
                                                value={moneda}
                                                custom={moneda}
                                                onChange={handleChangeMoneda}
                                            >
                                                <option hidden value="">Seleccionar</option>
                                                {monedas.length <= 0 ? (
                                                    <option value={moneda}>
                                                        No hay cuentas registradas
                                                    </option>
                                                ) : (
                                                    optionValueSelectMoneda()
                                                )}
                                                ;
                                            </select>


                                        </div>
                                        <div className="w-full ">
                                            <div className="mx-3 my-6" >
                                                <label className="mx-3">Monto transferido</label>
                                                <input
                                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                    id="withdraw_amount"
                                                    value={"$" + number}
                                                    onChange={handleChangeWithDrawAmount}
                                                    type="tel"
                                                    keyboard="numeral"
                                                    pattern="[0-9]*"
                                                ></input>

                                            </div>
                                        </div>
                                        <div className="w-full ">
                                            <div className="mx-3 my-6 ">
                                                <label className="mx-3">Evidencia</label>
                                                <input
                                                    className="appearance-none  block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500  "
                                                    type="file"
                                                    accept="image/*,application/pdf"
                                                    ref={fileInput}

                                                    onChange={(e) => setImg(e.target.value)}


                                                />


                                            </div>
                                        </div>
                                        {/* <div className="w-full ">
                                <div className="mx-3 my-6 m">
                                    <label className="mx-3 flex justify-between items-center"><span>Descripción<span className="text-gray-700">{` (opcional)`}</span>  </span>{description.length !== 0 && <span className="text-gray-700 text-xs">{`${cantidadCaracter}/40`}</span>}</label>

                                    <textarea
                                        className="appearance-none block h-20  w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500  "
                                        type="text"
                                        maxLength="40"
                                        placeholder="Recarga Billetera Cobru"
                                        onChange={(e) => caracteres(e.target.value)}
                                    />


                                </div>
                            </div> */}
                                        <div className="w-full ">
                                            <div className="mx-3 my-6">
                                                {((moneda === "COP" && valor >= 10000) || (moneda === "USD" && valor >= 1)) && img != null && loading == false && bankAccount != "" ?
                                                    <button
                                                        type="reset"
                                                        className="w-full  bg-green-600 hover:bg-green-600 border-green-600 hover:green-600 text-sm border-4 text-white py-2 rounded-full button-outline-off "
                                                        onClick={(e) => consultar(e)}
                                                    > Recarga {number != 0 ? `$${number}` : ""}
                                                    </button> : (
                                                        <button
                                                            className="w-full flex-shrink-0  text-sm border-4 text-white py-2 rounded-full btn-retiros button-outline-off"
                                                            type="button"

                                                        >{!loading ? `Recarga ${number != 0 ? `$${number}` : ""}` : "recargando..."}

                                                        </button>)}
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className=" fixed inset-0 z-40 " style={{
                        background: 'rgba(47, 46, 44, 0.5)',
                        backdropFilter: 'blur(10px)',
                    }}></div>

                </>

            }

            {showBanner &&
                <div className="col-span-4 ml-2 " style={{ marginBottom: 15 }}>
                    <Banner
                        title={'Proceso realizado'}
                        description={msg}
                        setShowBanner={setShowBanner}
                        error={errorBanner}
                    />
                </div>
            }
            {showBannerAccion &&
                <div className="col-span-4 ml-2" style={{ marginBottom: 15 }}>
                    <Banner
                        title={'Proceso realizado'}
                        description={errorBannerAccion ? `no se pudo realizar la accion` : `se ${agregar ? "acepto" : "Rechazo"} la recarga`}
                        setShowBanner={setShowBannerAccion}
                        error={errorBannerAccion}
                    />
                </div>
            }

            <div className="">


                {Modal && <ModalEvidencia img={imagen} setModal={setModal} />}
                <div className="flex md:flex-row flex-wrap p-1 overflow-x-auto text-xs mb-2">
                    <button
                        className={
                            "button-outline-off hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ml-2 " +
                            (isActiveFilter && statusCobru == null
                                ? "bg-green-600"
                                : "bg-gray-500")
                        }
                        onClick={handleFilter}
                        key="999"
                        value={null}
                    >
                        <span>Todos</span>
                    </button>
                    <button
                        className={
                            "button-outline-off hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " +
                            (isActiveFilter && statusCobru === '1'
                                ? "bg-green-600"
                                : "bg-gray-500")
                        }
                        onClick={handleFilter}
                        key="1"
                        value="1"
                    >
                        <span>Pendientes</span>
                    </button>
                    <button
                        className={
                            " button-outline-off hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " +
                            (isActiveFilter && statusCobru === '3'
                                ? "bg-green-600"
                                : "bg-gray-500")
                        }
                        onClick={handleFilter}
                        key="3"
                        value="3"
                    >
                        <span>Aprobados</span>
                    </button>
                    <button
                        className={
                            " button-outline-off hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " +
                            (isActiveFilter && statusCobru === '2'
                                ? "bg-green-600"
                                : "bg-gray-500")
                        }
                        onClick={handleFilter}
                        key="2"
                        value="2"
                    >
                        <span>Rechazados</span>
                    </button>

                </div>





                <div className="col-span-8 shadow-lg rounded w-full h-auto px-2 py-2 bg-white">

                    <DataTable
                        data={data}
                        columns={columns}

                        selectableRowsHighlight={true}
                        fixedHeader={true}
                        noHeader={true}
                        pagination={pagination}
                        paginationComponentOptions={paginationOptions}
                        progressPending={pending}
                        progressComponent={<Circular />}
                        noDataComponent={"No hay Recargas"}

                    />



                </div>


            </div>

        </>
    )
}

export default Recargar