import React, { useEffect, useState, useContext, useRef } from 'react';
import avatar from '../assets/images/avatar.png';
import { Link, Redirect } from "react-router-dom";
import { FunctionContext } from '../context/FuntionsContext';
import { AuthContext } from '../context/AuthContext';
import Banner from '../components/Banner/index';
import axios from 'axios';
import { FormatAmount, } from '../containers/common/functions';


import CircularProgress from "@material-ui/core/CircularProgress";
import s3 from '../helpers/aws';
import { BiBell, } from "react-icons/bi";
import { FaBell } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { RiDownloadCloudLine } from "react-icons/ri";
import { RiUploadCloudLine } from "react-icons/ri";
import moment from 'moment';
import { CgFileDocument } from "react-icons/cg"
import AxioInstance from '../axios/AxiosConfig';

import ModalUpload from "../containers/layouts/ModalUpload"
import ModalDownload from "../containers/layouts/modalDownload"
import { ImCancelCircle } from "react-icons/im"
import { FaSearch } from "react-icons/fa"
import Numeral from "numeral";
import { async } from 'q';


const NavigationBar = (props) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loadingPicture, setLoadingPicture] = useState(true);

    const DEV = localStorage.getItem('DEV');
    const [ShowBannerConfirm, setShowBannerConfirm] = useState(false)
    const [bankAccountInfo, setBankAccountInfo] = useState({})
    const [reloadPendings, setReloadPendings] = useState(false);
    const nameView = props.pathname;
    const [showConfirm, setShowConfirm] = useState({
        bank: false,
        third: false
    })
    const userDetails = JSON.parse(localStorage.getItem('userDetails'))

    const [errorBannerConfirm, setErrorBannerConfirm] = useState(false);
    const [showInscripcion, setShowInscripcion] = useState(false)
    const [loadingBankAccountInfo, setLoadingBankAccountInfo] = useState(false)
    //const { pathname } =props
    const userDetailName = props.user.first_name ? props.user.first_name : " ";


    const [show, setShow] = useState(false)
    const [data, setData] = useState(null)
    const [showNotifications, setShowNotifications] = useState(false)
    const [dataDownload, setDataDownload] = useState([
    ])
    const [dataUpload, setDataUpload] = useState([])

    const [showBannerUpload, setShowBannerUpload] = useState(false)
    const [showUpload, setShowUpload] = useState(false)
    const [errorBannerUpload, setErrorBannerUpload] = useState(false)

    const [profilePicture, setProfilePicture] = useState(props.user.profile_picture)


    const [showDonwnload, setShowDownload] = useState(false)
    const {
        reloadOperation,
        reloadRetiros,
        setReloadOperation,
        setReloadRetiros,
        setShowModalRecarga,
        renderNotifications,
        buscador,
        setBuscador,
        buscar,
        setBuscar,
        buscarDocument,
        setBuscarDocument,
        showModalRetirar,
        setShowModalRetirar,
        setBuscarRetiro,
        buscarRetiro,
        setBuscarCobrar,
        buscarCobrar,
        buscarEnvios,
        setBuscarEnvios,
        EnviarLayout,
        setEnviarLayout,
        CobrarLayout,
        setCobrarLayout,
        setRenderDownload,
        renderDownload,
        setRenderUpdate,
        RenderUpdate,
        setOpcionRetiros,
        opcionRetiros,
        setOpcionCobrus,
        opcionCobrus,
        setModalBlackList,
        modalBlackList,
        setModalManuel,
        ModalManual,
        setUserTarifas,
        userTarifas,
        GuardarTarifas,
        setGuardarTarifasClick,
        GuardarTarifasClick,
        opcionCumplimiento,
        setOpcionCumplimiento,
        optionSearchClient,
        setOptionSearchClient
    } = useContext(FunctionContext)

    const { logOut, isSuperUser, montoBolsaEfecty, getAuthenticatedHeaders, montoBolsaCobru, pendingRecargas, setPendingRecargas, getAccessToken, mutual } = useContext(AuthContext)

    const [showBanner, setShowBanner] = useState(false)
    const fileInputRef = useRef(null);

    const handleTextClick = () => {
        fileInputRef.current.click();
    };
    const AbrirModal = (e) => {

        if (e === 1) {
            setShowDownload(!showDonwnload)
            setShowUpload(false)
            setShowNotifications(false)
            setShowInscripcion(false)
        } else if (e === 2) {
            setShowUpload(!showUpload)
            setShowDownload(false)
            setShowNotifications(false)
            setShowInscripcion(false)
        } else if (e === 3) {
            setShowNotifications(!showNotifications)
            setShowDownload(false)
            setShowUpload(false)
            setShowInscripcion(false)
        } else if (e === 4) {
            setShowInscripcion(!showInscripcion)
            setShowDownload(false)
            setShowUpload(false)
            setShowNotifications(false)

        }

    }

    const LoginOut = () => {
        logOut()
        setShow(!show)

    }
    useEffect(() => {

        AbrirModal()
    }, [])
    const handleDownload = async (url) => {
        axios.get(url.file, {
            responseType: 'blob',
        })
            .then(async (res) => {
                const text = await new Response(res.data).text()
                downloadTxt(text, url, url.name_file)

            })
    }
    const handleUpload = async (url) => {
        axios.get(url.file, {
            responseType: 'blob',
        })
            .then(async (res) => {
                const text = await new Response(res.data).text()
                downloadDispersion(text, url)

            })
    }
    const getUserDetail = async () => {
        await AxioInstance.post(`/api/user`, {
            headers: await getAuthenticatedHeaders(),
        })
            .then(response => {
                if (response.data.status === 200) {
                    setProfilePicture(response.data.data.profile_picture)
                    localStorage.setItem('userDetails', JSON.stringify(response.data.data))
                }
            }, error => {

            });
        setLoadingPicture(false)
    };
    const renderDataTable = async () => {

        var total = 0
        try {
            const response = await AxioInstance.post(`/api/lista_recarga/`, {
                headers: await getAuthenticatedHeaders(),
                superuser: isSuperUser,

            });
            if (response.data.status === 200) {
                setData(response.data.data)
            }
        } catch (error) {

        }
    };
    useEffect(() => {

        setShowModalRecarga(false)
    }, [nameView])

    useEffect(() => {
        if (showDonwnload == true) {
            renderDataTableDispersion()
        }
        if (showUpload == true) {
            renderDataTableInscripcion()
        }

    },
        [showDonwnload, showUpload])
    const renderDataTableDispersion = async () => {

        try {
            const response = await AxioInstance.post(`/api/list/dispersion/`, {
                headers: await getAuthenticatedHeaders(),

            });
            if (response.data.status === 200) {

                setDataDownload(response.data.data)
            }
        } catch (error) {

        }
    };
    const Circular = () => (
        <div className='flex items-center justify-center w-full'  >
            {

                <CircularProgress color="red" size={20} />
            }
        </div>
    );
    const renderDataTableInscripcion = async () => {

        try {
            const response = await AxioInstance.post(`/api/list/inscripcionBank/`, {
                headers: await getAuthenticatedHeaders(),

            });
            if (response.data.status === 200) {

                setDataUpload(response.data.data)
            }
        } catch (error) {

        }
    };


    const AgregarArchivo = async (e) => {
        e.preventDefault();


        let archivos = e.target.files;


        const files = new FormData();


        for (let i = 0; i < archivos.length; i++) {
            files.append("files", archivos[i])
        }
        /*      const data = { "files": formData } */

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + await getAccessToken(),
            'x-api-key': DEV === "https://dev.cobru.co/" ? process.env.REACT_APP_API_KEY_DEV : process.env.REACT_APP_API_KEY
        };
        axios.post((DEV) + 'panel/load_payments_thirdpartywithdraws/', files, { headers }, {
        }).then(res => { // then print response status
            if (res.data.data === "OK") {
                renderDataTableInscripcion()
                setRenderUpdate(!RenderUpdate)
                setShowBannerUpload(true)
                setErrorBannerUpload(false)
            } else {
                setRenderUpdate(!RenderUpdate)
                setShowBannerUpload(true)
                setErrorBannerUpload(true)
            }



        }).catch((e) => {
            setRenderUpdate(!RenderUpdate)

            setShowBannerUpload(true)
            setErrorBannerUpload(true)
        })
    }

    function Name(name) {
        if (name.search("bancolombia") == 10) {

            return "D-Bancolombia"
        } else if (name.search("avvillas") == 10) {
            return "D-Avvillas"
        }
        else if (name.search("nequi") == 10) {
            return "D-Nequi"
        } else {
            return "Dispersión"
        }


    }

    const downloadTxt = async (data, name, bank) => {



        const link = document.createElement('a');
        if (data == null) return;
        const filename = `Dispersion-${Name(bank)}-${moment(name.date_created).format('DD/MM/YYYY-HH:mm A')}`;
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
        link.setAttribute('download', filename);
        link.click();
        //window.location.reload();

    }
    const downloadDispersion = async (data, name) => {

        const link = document.createElement('a');
        if (data == null) return;
        const filename = `Respuesta-${moment(name.date_created).format('DD/MM/YYYY-HH:mm A')}`;
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
        link.setAttribute('download', filename);
        link.click();
        //window.location.reload();

    }
    useEffect(() => {
        getUserDetail()
        getBankAccountInscriptionInfo()
        renderDataTable()
        renderDataTableDispersion()
        renderDataTableInscripcion()
    }, [])



    /*  useEffect(() => {
         if (data !== null) {
             contarRecargas()
 
         }
 
 
     }, [data]) */


    useEffect(() => {


        if (montoBolsaCobru.alerta === true) {
            setShowBanner(true)
        }
        else if (montoBolsaCobru.alerta === true) {
            setShowBanner(true)
        }

        else {
            setShowBanner(false)
        }

    }, [montoBolsaEfecty.alerta !== undefined && montoBolsaCobru.alerta !== undefined])

    const downloadPendingTirdPart = async () => {
        setLoadingBankAccountInfo(true)
        setShowConfirm({
            bank: false,
            third: false
        })
        try {
            const response = await AxioInstance.post('/api/third_party_bank_account_inscription_file', {

                headers: await getAuthenticatedHeaders()
            })

            setShowConfirm({
                bank: false,
                third: true
            });
            setReloadPendings(!reloadPendings)
            getBankAccountInscriptionInfo()

            downloadTxtInscripcion(response.data.data, true)
            setLoadingBankAccountInfo(false)
        } catch (error) {
            setLoadingBankAccountInfo(false)
            setShowConfirm({
                bank: false,
                third: false
            })
        }
    }
    useEffect(() => {
        if (showInscripcion) {
            getBankAccountInscriptionInfo()
        }
    }, [showInscripcion])
    const getBankAccountInscriptionInfo = async () => {
        setLoadingBankAccountInfo(true)
        try {
            const response = await AxioInstance.post('/api/bank_account_inscription_info', {
                username: nameView === 'fenixen' ? '901562504' :
                    nameView === 'construir' ? '901237347' : null,
                headers: await getAuthenticatedHeaders()
            })
            if (response.data.data) {
                setLoadingBankAccountInfo(false)

                setBankAccountInfo(response.data.data)
            } else {
                setBankAccountInfo({
                    pendingBankAccounts: 0, thirdPartyPendingBankAccounts: 0
                })
                setLoadingBankAccountInfo(false)
            }

        } catch (error) {

            setLoadingBankAccountInfo(false)

        }
    }


    const inscriptionThirdPartConfirm = async () => {
        setLoadingBankAccountInfo(true)
        setShowConfirm({
            bank: false,
            third: false
        })
        try {
            const response = await AxioInstance.post('/api/third_party_bank_account_inscription_confirm', {
                headers: await getAuthenticatedHeaders()
            })

            setReloadPendings(!reloadPendings)
        } catch (error) {

            setLoadingBankAccountInfo(false)
        }
    }
    const downloadTxtInscripcion = async (data, isTirdPart) => {


        if (isTirdPart) {
            const link = document.createElement('a');
            if (data == null) return;
            const filename = `inscripcion-${moment().format('YYYY-MM-DD-HH:mm A')}.txt`;
            link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
            link.setAttribute('download', filename);
            link.click();
            //window.location.reload();
        } else {
            const link = document.createElement('a');
            if (data == null) return;
            const filename = `inscripcion-${moment().format('YYYY-MM-DD-HH:mm:ss A')}.txt`;
            link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
            link.setAttribute('download', filename);
            link.click();
            //window.location.reload();
        }
    }
    const InscripcionesConfirm = async () => {
        try {
            const response = await AxioInstance.post(`/api/third_party_bank_account_inscription_confirm/`, {

                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {
                setRenderUpdate(!RenderUpdate)
                setErrorBannerConfirm(false)
                setShowBannerConfirm(true)
                getBankAccountInscriptionInfo()
            } else {
                setRenderUpdate(!RenderUpdate)
                setErrorBannerConfirm(true)
                setShowBannerConfirm(true)

            }
        } catch (error) {
            setRenderUpdate(!RenderUpdate)
            setErrorBannerConfirm(true)
            setShowBannerConfirm(true)

            //setIsLoading(false);
        }
    }

    /*     const esTextoONumero = (valor) => {
            typeof valor === 'string' || (typeof valor === 'number' && !isNaN(valor));
        } */




    const uploadImage = async (file) => {


        setLoadingPicture(true)
        const selectedFile = file.target.files[0];
        if (selectedFile.size > 1024 * 1024) {
            alert('El archivo seleccionado es demasiado grande. Debe ser menor de 1MB.');
            // Puedes borrar la selección del archivo si lo deseas
            fileInputRef.current.value = null;
            setLoadingPicture(false)
            return;
        }
        let utc = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
        utc = utc.replace(" ", "-");
        utc = utc.replace("/", "-").replace("/", "-");
        let dt = new Date();
        let secs = dt.getSeconds() + (60 * dt.getMinutes()) + (60 * 60 * dt.getHours());
        const objectKey = `uploads/${userDetails.document_number}-${utc}-${secs}.jpg`;
        const params = {
            Bucket: "cobru-profile-pictures",
            Key: objectKey, // Cambia el nombre de acuerdo a tu preferencia
            Body: selectedFile,
            ContentType: selectedFile.type,
            ACL: 'public-read', // Permisos de lectura público
        };
        let key = ""
        const res = s3.upload(params, async (err, data) => {
            if (err) {
                console.error('Error al subir la imagen a S3:', err);
            } else {

                const dataProfile = {
                    username: userDetails.username.toString(),
                    email: userDetails.email,
                    phone: userDetails.phone.toString(),
                    profile_picture: data.key
                };
                const res = await AxioInstance.post(`/api/update/profile/`, {
                    dataProfile: dataProfile,
                    headers: await getAuthenticatedHeaders()
                });
                setShow(!show)
                getUserDetail()
                // Aquí puedes realizar acciones adicionales después de la carga exitosa
            }
        });

    };

    return (
        <>
            <div>
                <div className="flex p-3 pt-2 pb-0 ">
                    <div className="w-80 h-12 capitalize text-1xl font-base flex flex-row items-center ">
                        {nameView === 'resume' ? 'Resumen' :
                            nameView === 'cobrar' ? 'Cobros' :
                                nameView === 'enviar' ? 'Envios' :
                                    nameView === 'recargar' ? 'Recargas' :
                                        nameView === 'cargar' ? 'Cargar' :
                                            nameView === 'consultar' && isSuperUser === true ? 'Callbacks' :
                                                nameView === 'mistarifas' ? 'Mis Tarifas' :
                                                    nameView === 'validacion' ? 'Validación' :
                                                        nameView === 'reportesdate' ? 'Reporte' :
                                                            nameView === 'retirar' ? 'Retiros' : nameView}{ /* isSuperUser === true ? ' super user ' : ' no es super ' */}
                        {
                            nameView === 'dashboard' && isSuperUser && (
                                <div className="hover:bg-green-600 bg-gray-500 rounded-full h-6 w-6 flex mx-1 cursor-pointer items-center " onClick={() => setReloadOperation(!reloadOperation)}>
                                    <img className="object-contain w-full h-4 " src={require('../assets/images/refresh-icon.png')}></img>
                                </div>
                            )
                        }

                        {
                            nameView === 'recargas' && isSuperUser && !mutual && (
                                <div onClick={() => setShowModalRecarga(true)}>
                                    <AiOutlinePlusCircle size="1em" className="text-green-600 ml-3 cursor-pointer" on />
                                </div>

                            )
                        }
                        {
                            nameView === 'cobrus' && isSuperUser && (
                                <div onClick={() => setModalManuel(true)}>
                                    <AiOutlinePlusCircle size="1em" className="text-green-600 ml-3 cursor-pointer" on />
                                </div>

                            )
                        }
                        {
                            nameView === 'blacklist' && isSuperUser && (
                                <div onClick={() => setModalBlackList(true)}>
                                    <AiOutlinePlusCircle size="1em" className="text-green-600 ml-3 cursor-pointer" on />
                                </div>

                            )
                        }
                        {
                            nameView === 'recargar' && (
                                <div onClick={() => setShowModalRecarga(true)}>
                                    <AiOutlinePlusCircle size="1em" className="text-green-600 ml-3 cursor-pointer" on />
                                </div>

                            )
                        }

                        {
                            nameView === 'retirar' && (
                                <div onClick={() => setShowModalRetirar(true)}>
                                    {(userDetails.permission_withdraw != 1) && <AiOutlinePlusCircle size="1em" className="text-green-600 ml-3 cursor-pointer" on />}
                                </div>

                            )
                        }

                        {
                            nameView === 'retiros' && (
                                <div className="hover:bg-green-600 bg-gray-500 rounded-full h-6 w-6 flex items-center mx-1 cursor-pointer outline-none focus:outline-none" onClick={() => setReloadRetiros(!reloadRetiros)}>
                                    <img className="object-contain w-full h-4 pr-0.5" src={require('../assets/images/refresh-icon.png')}></img>
                                </div>
                            )
                        }
                        {(nameView === 'cobrar') &&
                            <div className="relative">
                                <select name="permission_cobru" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 select-nav" id="grid-cobrus" onChange={(e) => setOpcionCobrus(e.target.value)} style={{ width: opcionCobrus == 1 ? 80 : opcionCobrus == 3 ? 80 : opcionCobrus == 4 ? 80 : 150 }}>
                                    <option value={0} disabled>Buscar</option>
                                    <option value={1} selected="true">REF</option>
                                    <option value={2}>Documento</option>
                                    <option value={3}>PIN</option>
                                    <option value={4}>URL</option>
                                    <option value={5}>CUS</option>
                                    <option value={6}>Descripción</option>
                                    <option value={7}>Autorización</option>


                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        }
                        {(nameView === 'cobrus') &&
                            <div className="relative">
                                <select name="permission_cobru" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 select-nav" id="grid-cobrus" onChange={(e) => setOpcionCobrus(e.target.value)} style={{ width: opcionCobrus == 1 ? 80 : opcionCobrus == 3 ? 80 : opcionCobrus == 4 ? 80 : opcionCobrus == 5 ? 100 : opcionCobrus == 6 ? 80 : 150 }}>
                                    <option value={0} disabled>Buscar</option>
                                    <option value={1} selected="true">REF</option>
                                    <option value={2}>Documento</option>
                                    <option value={3}>PIN</option>
                                    <option value={4}>URL</option>
                                    <option value={5}>Usuario</option>
                                    <option value={6}>CUS</option>
                                    <option value={7}>Autorización</option>


                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>}
                        {(nameView === 'retiros') &&
                            <div className="relative">
                                <select name="permission_cobru" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 select-nav" id="grid-cobrus" onChange={(e) => setOpcionRetiros(e.target.value)} style={{ width: opcionRetiros == 1 ? 80 : 150 }}>
                                    <option value={0} disabled>Buscar</option>
                                    <option value={1} selected="true">REF</option>
                                    <option value={2}>Documento</option>
                                    <option value={3}>Usuario</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        }
                        {(nameView === 'retirar') &&
                            <div className="relative">
                                <select name="permission_cobru" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 select-nav" id="grid-cobrus" onChange={(e) => setOpcionRetiros(e.target.value)} style={{ width: opcionRetiros == 1 ? 80 : 150 }}>
                                    <option value={0} disabled>Buscar</option>
                                    <option value={1} selected="true">REF</option>
                                    <option value={2}>Documento</option>
                                    <option value={3}>Descripción</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        }
                        {
                            nameView === 'retiros' && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={(buscador)} onChange={(e) => setBuscador(e.target.value)} placeholder={''}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -22, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>
                                </div>
                            )
                        }

                        {
                            nameView === 'documentos' && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={(buscarDocument)} onChange={(e) => setBuscarDocument(e.target.value)} placeholder={''}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -22, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>
                                </div>
                            )
                        }
                        {(nameView === 'envios') &&
                            <div className="relative">
                                <select name="permission_cobru" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 select-nav" id="grid-cobrus" value={optionSearchClient} onChange={(e) => setOptionSearchClient(e.target.value)} style={{ width: '135px' }}>
                                    <option value={0} disabled>Buscar</option>
                                    <option value={2}>REF</option>
                                    <option value={3}>Usuario</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        }
                        {
                            nameView === 'envios' && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={(buscarDocument)} onChange={(e) => setBuscarDocument(e.target.value)} placeholder={''}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -22, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>
                                </div>
                            )
                        }
                        {(nameView === 'recargas' && mutual) &&
                            <div className="relative">
                                <select name="permission_cobru" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 select-nav" id="grid-cobrus" value={optionSearchClient} onChange={(e) => setOptionSearchClient(e.target.value)} style={{ width: '135px' }}>
                                    <option value={0} disabled>Buscar</option>
                                    <option value={2}>REF</option>
                                    <option value={3}>Documento</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        }
                        {
                            nameView === 'recargas' && mutual && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={(buscarDocument)} onChange={(e) => setBuscarDocument(e.target.value)} placeholder={''}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -22, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>
                                </div>
                            )
                        }
                        {(nameView === 'cambios') &&
                            <div className="relative">
                                <select name="permission_cobru" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 select-nav" id="grid-cobrus" value={optionSearchClient} onChange={(e) => setOptionSearchClient(e.target.value)} style={{ width: '135px' }}>
                                    <option value={0} disabled>Buscar</option>
                                    <option value={2}>REF</option>
                                    {/*                                 <option value={3}>Usuario</option>
 */}                            </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        }
                        {
                            nameView === 'cambios' && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={(buscarDocument)} onChange={(e) => setBuscarDocument(e.target.value)} placeholder={''}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -22, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>
                                </div>
                            )
                        }

                        {(nameView === 'asociados') &&
                            <div className="relative">
                                <select name="permission_cobru" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 select-nav" id="grid-cobrus" value={optionSearchClient} onChange={(e) => setOptionSearchClient(e.target.value)} style={{ width: '135px' }}>
                                    <option value={0} disabled>Buscar</option>
                                    <option value={2}>Documento</option>
                                    <option value={3}>Usuario</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        }
                        {
                            nameView === 'asociados' && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={(buscarDocument)} onChange={(e) => setBuscarDocument(e.target.value)} placeholder={''}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -22, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>
                                </div>
                            )
                        }

                        {(nameView === 'clientes') &&
                            <div className="relative">
                                <select name="permission_cobru" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 select-nav" id="grid-cobrus" value={optionSearchClient} onChange={(e) => setOptionSearchClient(e.target.value)} style={{ width: '135px' }}>
                                    <option value={0} disabled>Buscar</option>
                                    <option value={2}>Documento</option>
                                    <option value={3}>Usuario</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        }
                        {
                            nameView === 'clientes' && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={(buscarDocument)} onChange={(e) => setBuscarDocument(e.target.value)} placeholder={''}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -22, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>
                                </div>
                            )
                        }
                        {
                            nameView === 'retirar' && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={buscarRetiro} onChange={(e) => setBuscarRetiro(e.target.value)} placeholder={''}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -22, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>
                                </div>
                            )
                        }
                        {
                            nameView === 'cobrar' && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={buscarCobrar} onChange={(e) => setBuscarCobrar(e.target.value)} placeholder={''}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -22, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>
                                </div>
                            )
                        }
                        {
                            nameView === 'cobrus' && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={buscarCobrar} onChange={(e) => setBuscarCobrar(e.target.value)} placeholder={''}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -30, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>
                                </div>
                            )
                        }
                        {
                            nameView === 'enviar' && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={buscarEnvios} onChange={(e) => setBuscarEnvios(e.target.value)} placeholder={''}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -22, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>

                                </div>
                            )
                        }
                        {
                            nameView === 'tarifas' && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={userTarifas} onChange={(e) => setUserTarifas(e.target.value)} placeholder={'Usuario'}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -22, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>

                                </div>
                            )
                        }
                        {(nameView === 'cumplimiento') &&
                            <div className="relative">
                                <select name="permission_cobru" className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 select-nav" id="grid-cobrus" onChange={(e) => setOpcionCumplimiento(e.target.value)} defaultValue={"0"} style={{ width: opcionCumplimiento !== "10" ? 80 : 100 }}  >
                                    <option value={"10"} disabled>Buscar</option>
                                    <option value="0">CC</option>
                                    <option value="1">CE</option>
                                    <option value="3">NIT</option>
                                    <option value="2">TI</option>
                                    <option value="4">PP</option>
                                    <option value="5">PPT</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                </div>
                            </div>
                        }
                        {
                            nameView === 'cumplimiento' && (
                                <div className="flex flex-row md:ml-4" style={{ height: 30, }} >
                                    <input className='bg-white  border rounded pl-2 pr-10   text-base focus:outline-none' value={(buscarEnvios)} onChange={(e) => setBuscarEnvios(e.target.value)} placeholder={''}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                setBuscar(!buscar)
                                            }
                                        }
                                        }
                                    />
                                    <button className='focus:outline-none' style={{ marginLeft: -22, }} onClick={() => setBuscar(!buscar)}> <FaSearch color={'#a0aec0'} size={16} /></button>
                                </div>
                            )
                        }

                        {

                            nameView === 'tarifas' && GuardarTarifas == true ? (

                                <button className={("focus:outline-none w-36 bg-green-600 text-xs  text-white font-bold py-1 px-4 rounded-full ml-4")} onClick={() => setGuardarTarifasClick(true)} >
                                    <span>Guardar </span>
                                </button>

                            ) : (
                                null
                            )
                        }
                        {
                            nameView === 'enviar' && EnviarLayout === false && (
                                <div className="ml-3" onClick={() => setEnviarLayout(true)}>
                                    {(userDetails.permission_send
                                        != 1) && <AiOutlinePlusCircle size="1em" className="text-green-600 ml-3 cursor-pointer" on />}
                                </div>

                            )
                        }

                        {
                            nameView === 'enviar' && EnviarLayout === true && (
                                <div className="ml-3" onClick={() => setEnviarLayout(false)}>
                                    <ImCancelCircle size="0.88em" className="text-red-600 ml-3 cursor-pointer" />
                                </div>
                            )
                        }
                        {
                            (nameView === 'cobrar' && CobrarLayout === false) && (
                                <div className="ml-3" onClick={() => setCobrarLayout(true)}>
                                    {(userDetails.permission_cobru != 1) && <AiOutlinePlusCircle size="1em" className="text-green-600 ml-3 cursor-pointer" on />}
                                </div>

                            )
                        }

                        {
                            nameView === 'cobrar' && CobrarLayout === true && (
                                <div className="ml-3" onClick={() => setCobrarLayout(false)}>
                                    <ImCancelCircle size="0.88em" className="text-red-600 ml-3 cursor-pointer" />
                                </div>
                            )
                        }


                    </div>


                    <div className="w-1/3 ml-auto hidden md:block" >
                        <div className="leading-tight bg-grey-lighter" >

                            <div className=" flex items-center md:ml-6 float-right" >

                                {isSuperUser && !mutual &&
                                    <label className="cursor-pointer">

                                        <CgFileDocument title="Descargar inscripción" size="30px" className=" mr-4" onClick={() => AbrirModal(4)} />
                                    </label>
                                }
                                {isSuperUser && !mutual && <label className="cursor-pointer" style={{ fontSize: '12px' }} onClick={() => AbrirModal(2)}>
                                    {/* <input type="file" className="hidden" multiple onChange={(e) => AgregarArchivo(e)}
                                    accept="text/plain"

                                    autoFocus={false}
                                /> */}
                                    <RiUploadCloudLine title="Subir archivo del banco" size="30px" className="mr-4" />

                                </label>

                                }
                                {isSuperUser && !mutual &&
                                    <label className="cursor-pointer" style={{}}>
                                        <RiDownloadCloudLine title="Descargar dispersiones" className="mr-4" size="30px" onClick={() => AbrirModal(1)} />

                                    </label>
                                }
                                {isSuperUser && pendingRecargas > 0 &&
                                    <FaBell size="27px" className="text-green-600 " onClick={() => AbrirModal(3)} />
                                }
                                {isSuperUser && pendingRecargas == 0 && <label className="cursor-pointer">
                                    <BiBell title="Sin notificaciones" size="30px" className="text-black-600 " onClick={() => AbrirModal(3)} />

                                </label>}

                                <div className="origin-top-right right-0 mt-2 cursor-pointer" onClick={() => setShow(!show)}>


                                    <div className="py-1 text-right ">
                                        <p className="block px-2 text-sm font-hairline text-gray-700" >¡Hola!</p>
                                        <p className="block px-2 text-sm" >{userDetailName}</p>
                                    </div>
                                </div>

                                <div className=" relative  ">
                                    <div onClick={() => setShow(!show)} className="max-w-xs flex items-center rounded-full focus:outline-none focus:shadow-solid cursor-pointer bg-white" style={{ width: 40, height: 40 }}>
                                        {profilePicture && loadingPicture === false ?
                                            <>
                                                {
                                                    (profilePicture === '' || profilePicture === 'none') ?
                                                        <img className="h-auto  rounded-full" src={avatar} alt=""></img>

                                                        :
                                                        <img className="h-auto img-icon-w rounded-full" src={"https://s3.amazonaws.com/cobru-profile-pictures/" + profilePicture} alt=""></img>
                                                }


                                            </>
                                            :
                                            <>
                                                {loadingPicture && <Circular />}
                                            </>
                                        }

                                    </div>
                                    {show && <div className="object-right-bottom absolute right-0 flex  flex-col items-center mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                                        {<div className="py-1 w-full text-center" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                            <div className=" px-4 py-2">
                                                <p onClick={(e) => handleTextClick(e)} className="cursor-pointer">Cambiar foto</p>
                                            </div>
                                            <input
                                                type="file"
                                                accept=".svg, .jpg, .jpeg"
                                                style={{ display: 'none' }}
                                                ref={fileInputRef}
                                                onChange={(e) => uploadImage(e)}
                                            />
                                        </div>}
                                        <hr />

                                        <div className="py-1 w-full text-center" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                            <Link onClick={LoginOut} className='block px-4 py-2 text-sm text-red-500'>Cerrar Sesíon</Link>
                                        </div>
                                    </div>}
                                    {showNotifications && <div className="object-right-bottom absolute right-0 flex items-center mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                            <Link to="recargas" className='block px-4 py-2 text-sm '>{'Recargas pendientes:  '}<b className="text-green-600 text-right text-base	" >{pendingRecargas}</b></Link>
                                        </div>
                                    </div>}
                                    {showInscripcion && <div className="object-right-bottom absolute right-0 flex items-center mt-2 w-86 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                                        <div className="bg-white  rounded overflow-hidden shadow-lg flex justify-center" style={{ height: 120, }}>
                                            <div className="px-2 py-4 flex flex-col  items-center justify-around">
                                                <button className="py-1 rounded-full  border-2 buttonAzul" style={{ width: "340px", borderColor: "#2E86C1", color: "#2E86C1" }}
                                                    onClick={() => {
                                                        if (bankAccountInfo.thirdPartyPendingBankAccounts !== 0) {

                                                            downloadPendingTirdPart()

                                                        } else {

                                                        }
                                                    }}>{loadingBankAccountInfo ? <p>Espere...</p> : <p>{`Descargar ${!!bankAccountInfo.thirdPartyPendingBankAccounts ? bankAccountInfo.thirdPartyPendingBankAccounts : 0} cuentas para inscribir`}</p>}</button>
                                                <button onClick={() => {
                                                    if (bankAccountInfo.thirdPartyOnProcessBankAccounts !== 0) {
                                                        InscripcionesConfirm()
                                                    }
                                                }
                                                } className="py-1 rounded-full  border-trixel-green text-trixel-green	border-2 hover:bg-trixel-green hover:text-white" style={{ width: "340px" }}>{loadingBankAccountInfo ? <p>Espere...</p> : `Marcar ${!!bankAccountInfo.thirdPartyOnProcessBankAccounts ? bankAccountInfo.thirdPartyOnProcessBankAccounts : 0} cuentas como inscritas`}</button>
                                            </div>
                                        </div>
                                    </div>}
                                    {showDonwnload && <ModalDownload data={dataDownload} downloadTxt={(e) => handleDownload(e)} />}
                                    {showUpload && <ModalUpload data={dataUpload} AgregarArchivo={(e) => AgregarArchivo(e)} downloadDispersion={(e) => handleUpload(e)} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isSuperUser &&
                    <div className="w-full ml-3 h-full ">
                        {showBanner &&
                            <Banner
                                title={'Proceso realizado'}
                                description={`La bolsa de ${montoBolsaCobru.alerta == true ? `Cobru (${FormatAmount(montoBolsaCobru.bolsa)})` : ""} se esta agotando  `}
                                setShowBanner={setShowBanner}
                                error={true}
                            />}
                        {showBannerUpload && <Banner
                            title={'Proceso realizado'}
                            description={errorBannerUpload ? `no se pudo subir el archivo` : "Subida de archivo exitosa"}
                            setShowBanner={setShowBannerUpload}
                            error={errorBannerUpload}
                        />}
                    </div>}

            </div>

        </>
    )
}

export default NavigationBar;