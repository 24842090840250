import React, { useState, createContext } from 'react'
import _ from 'lodash';
import Axios from 'axios';
export const FunctionContext = createContext();

const Provider = ({ children }) => {
    const [userTarifas, setUserTarifas] = useState('')

    const [reloadOperation, setReloadOperation] = useState(false)
    const [GuardarTarifas, setGuardarTarifas] = useState(false)
    const [GuardarTarifasClick, setGuardarTarifasClick] = useState(false)

    const [reloadRetiros, setReloadRetiros] = useState(false)
    const [reloadSwaps, setReloadSwaps] = useState(false)

    const [showModalRecarga, setShowModalRecarga] = useState(false)
    const [showModalRetirar, setShowModalRetirar] = useState(false)
    const [renderNotifications, setRenderNotifications] = useState(false)
    const [buscador, setBuscador] = useState('')
    const [buscar, setBuscar] = useState(false)
    const [buscarDocument, setBuscarDocument,] = useState('')
    const [buscarRetiro, setBuscarRetiro] = useState('')
    const [buscarCobrar, setBuscarCobrar] = useState('')
    const [buscarEnvios, setBuscarEnvios] = useState('')
    const [EnviarLayout, setEnviarLayout] = useState(false)
    const [CobrarLayout, setCobrarLayout] = useState(false)
    const [renderDownload, setRenderDownload] = useState(false)
    const [RenderUpdate, setRenderUpdate] = useState(false)
    const [opcionRetiros, setOpcionRetiros] = useState(1)
    const [opcionCumplimiento, setOpcionCumplimiento] = useState("0")

    const [opcionCobrus, setOpcionCobrus] = useState(1)
    const [modalBlackList, setModalBlackList] = useState(false)
    const [renderDataDocuments, setRenderDataDocuments] = useState(false)
    const [ModalManual, setModalManuel] = useState(false)


    const [optionSearchClient, setOptionSearchClient] = useState(2)



    const value = {
        reloadOperation,
        reloadRetiros,
        setReloadOperation,
        setReloadRetiros,
        setShowModalRecarga,
        showModalRecarga,
        renderNotifications,
        setRenderNotifications,
        buscador,
        setBuscador,
        buscar,
        setShowModalRetirar,
        showModalRetirar,
        setBuscar,
        buscarDocument,
        setBuscarDocument,
        buscarRetiro,
        setBuscarRetiro,
        setBuscarCobrar,
        buscarCobrar,
        buscarEnvios,
        setBuscarEnvios,
        EnviarLayout,
        setEnviarLayout,
        CobrarLayout,
        setCobrarLayout,
        setRenderDownload,
        renderDownload,
        RenderUpdate,
        setRenderUpdate,
        setOpcionRetiros,
        opcionRetiros,
        setOpcionCobrus,
        opcionCobrus,
        setModalBlackList,
        modalBlackList,
        setRenderDataDocuments,
        renderDataDocuments,
        setModalManuel,
        ModalManual,
        setUserTarifas,
        userTarifas,
        GuardarTarifas,
        setGuardarTarifas,
        setGuardarTarifasClick,
        GuardarTarifasClick,
        opcionCumplimiento,
        setOpcionCumplimiento,
        optionSearchClient,
        setOptionSearchClient,
        reloadSwaps,
        setReloadSwaps
    }
    return (
        <FunctionContext.Provider value={value}>
            {children}
        </FunctionContext.Provider>
    )
}
export default {
    Provider,
    Consumer: FunctionContext.Consumer
};