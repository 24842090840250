import axios from 'axios';

const AxioInstance = axios.create({

});

AxioInstance.interceptors.request.use(config => {
    const DEV = "https://prod.colurs.io/";
    config.data = {
        ...config.data,
        dev: DEV, // Cambia esto según tus necesidades
    };
    return config;
});

export default AxioInstance;