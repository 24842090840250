import React, { useEffect, useState, useContext, Fragment, useRef } from 'react';
import axios from 'axios';
import { /* getAuthenticatedHeaders,  */FormatAmount, formatCurrency } from '../common/functions';
import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '../../components/Button';
import _, { result } from 'lodash';
import moment from 'moment';
import AxioInstance from '../../axios/AxiosConfig';

import Banner from '../../components/Banner';
import { FunctionContext } from '../../context/FuntionsContext';
import { AuthContext } from '../../context/AuthContext';
import { GrDocumentUpload } from "react-icons/gr"
const Operacion = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingBalance, setIsLoadingBalance] = useState(true);

    const [ShowBanner, setShowBanner] = useState(false);
    const [errorBanner, setErrorBanner] = useState(false);
    const [fileTxt, setFileTxt] = useState("");
    const [info, setInfo] = useState([]);
    const [BTC, setBTC] = useState(0);
    const [TRX, setTRX] = useState(0);
    const [COPU, setCOPU] = useState(0);
    const [USDT, setUSDT] = useState(0);
    const [COBRU, setCOBRU] = useState(0);


    const { reloadOperation } = useContext(FunctionContext)
    const { getAuthenticatedHeaders, setMontoBolsaEfecty, setMontoBolsaBaloto, montoBolsaCobru, setMontoBolsaCobru, getAccessToken } = useContext(AuthContext)
    const [balanceMulti, setBalanceMulti] = useState([]);
    const [errorBannerConfirm, setErrorBannerConfirm] = useState(false);
    const [loadingBolsa, setLoadingBolsa] = useState(false)
    const [loadingBankAccountInfo, setLoadingBankAccountInfo] = useState(false)
    const [bolsaBemovil, setBolsaBemovil] = useState({})
    const [ShowBannerConfirm, setShowBannerConfirm] = useState(false)
    const [bankAccountInfo, setBankAccountInfo] = useState({})
    const [showConfirm, setShowConfirm] = useState({
        bank: false,
        third: false
    })
    const [reloadPendings, setReloadPendings] = useState(false);
    const [efectyBolsa, setEfectyBolsa] = useState(0);
    const [balotoBolsa, setBalotoBolsa] = useState(0);
    const [playTechBolsa, setPlayTechBolsa] = useState(0);
    const fileInput = useRef(null)



    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };

    const getData = async () => {

        setIsLoading(true);
        try {
            const response = await AxioInstance.post(`/api/operations`, {
                headers: await getAuthenticatedHeaders(),
            });

            if (response.data.status === 200) {

                setInfo(response.data.data);

                setIsLoading(false);
            }
            setIsLoading(false);

        } catch (error) {

            setIsLoading(false);
        }
        // setIsLoading(false);
    }

    const getBankAccountInscriptionInfo = async () => {
        setLoadingBankAccountInfo(true)
        try {
            const response = await AxioInstance.post('/api/bank_account_inscription_info', {
                headers: await getAuthenticatedHeaders()
            })
            if (response.data.data) {
                setLoadingBankAccountInfo(false)

                setBankAccountInfo(response.data.data)
            } else {
                setBankAccountInfo({
                    pendingBankAccounts: 0, thirdPartyPendingBankAccounts: 0
                })
                setLoadingBankAccountInfo(false)
            }

        } catch (error) {

            setLoadingBankAccountInfo(false)

        }
    }

    const getBusiness = async () => {
        setLoadingBolsa(true)
        Promise.all([getBusinessReload(), getBusinessProducts(), getEfecty(), getBaloto(), getPlayTech()])
            .then(function (results) {
                const getBusinessReload = results[0];
                const getBusinessProducts = results[1];
                const efecty = results[2]
                const baloto = results[3]
                const play = results[4]
                setBolsaBemovil({
                    reload: getBusinessReload,
                    products: getBusinessProducts
                })


                setEfectyBolsa(efecty)
                setBalotoBolsa(baloto)
                setPlayTechBolsa(play)
                setLoadingBolsa(false)
                setMontoBolsaEfecty({
                    bolsa: efecty,
                    alerta: efecty < 2000000 ? true : false
                })
                setMontoBolsaBaloto({
                    bolsa: baloto,
                    alerta: baloto < 2000000 ? true : false
                })
            }).catch(reason => {
                setLoadingBolsa(false)

            })
    }

    const getBusinessReload = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_business_constant/`, {
                id: 130,
                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {

                return response.data.data.int_value

            } else {
                return ''

            }
        } catch (error) {

            return ''

            //setIsLoading(false);
        }
    }
    const InscripcionesConfirm = async () => {
        try {
            const response = await AxioInstance.post(`/api/third_party_bank_account_inscription_confirm/`, {
                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {
                setErrorBannerConfirm(false)
                setShowBannerConfirm(true)

            } else {
                setErrorBannerConfirm(true)
                setShowBannerConfirm(true)

            }
        } catch (error) {
            setErrorBannerConfirm(true)
            setShowBannerConfirm(true)

            //setIsLoading(false);
        }
    }
    const getBusinessProducts = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_business_constant/`, {
                id: 131,
                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {

                return response.data.data.int_value

            } else {
                return ''
            }

        } catch (error) {

            return ''

            //setIsLoading(false);
        }
    }
    const getBusinessMultiproduct = async () => {
        setIsLoadingBalance(true);
        try {
            const response = await AxioInstance.post(`/api/balance_general_crypto/`, {
                headers: await getAuthenticatedHeaders(),
            });
            if (response.data.status === 200) {
                response.data.data.balance.map((item) => {
                    if (item.currency_code === "BTC") {
                        setBTC(item.balance)
                    }
                    if (item.currency_code === "COPU") {
                        setCOPU(item.balance)
                    }
                    if (item.currency_code === "TRX") {
                        setTRX(item.balance)
                    }
                    if (item.currency_code === "USDT") {
                        setUSDT(item.balance)
                    }
                    if (item.currency_code === "COBRU") {
                        setCOBRU(item.balance)
                        setMontoBolsaCobru({
                            bolsa: item.balance,
                            alerta: item.balance < 20000000 ? true : false
                        })
                    }

                })


            } else {
                return ''
            }

        } catch (error) {

            return ''

            //setIsLoading(false);
        }
        setIsLoadingBalance(false);
    }
    const getEfecty = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_business_constant/`, {
                id: 929,
                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {
                return response.data.data.int_value
            } else {
                return ''
            }

        } catch (error) {

            return ''

            //setIsLoading(false);
        }
    }
    const getBaloto = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_business_constant/`, {
                id: 128,
                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {
                return response.data.data.int_value
            } else {
                return ''
            }

        } catch (error) {

            return ''

            //setIsLoading(false);
        }
    }
    const getPlayTech = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_business_constant/`, {
                id: 125,
                headers: await getAuthenticatedHeaders(),
            });


            if (response.data.status === 200) {
                return response.data.data.int_value
            } else {
                return ''
            }

        } catch (error) {

            return ''

            //setIsLoading(false);
        }
    }



    const downloadTxt = async (data, isTirdPart) => {

        if (isTirdPart) {
            const link = document.createElement('a');
            if (data == null) return;
            const filename = `inscripcion-${moment().format('YYYY-MM-DD-HH:mm A')}.txt`;
            link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
            link.setAttribute('download', filename);
            link.click();
            //window.location.reload();
        } else {
            const link = document.createElement('a');
            if (data == null) return;
            const filename = `inscripcion-${moment().format('YYYY-MM-DD-HH:mm:ss A')}.txt`;
            link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
            link.setAttribute('download', filename);
            link.click();
            //window.location.reload();
        }
    }

    const downloadPending = async () => {
        setLoadingBankAccountInfo(true)
        setShowConfirm({
            bank: false,
            third: false
        })
        try {
            const response = await AxioInstance.post('/api/bank_account_inscription_file', {
                headers: await getAuthenticatedHeaders()
            })
            setShowConfirm({
                bank: true,
                third: false
            })
            downloadTxt(response.data.data)
            setLoadingBankAccountInfo(false)
            setReloadPendings(!reloadPendings)
        } catch (error) {
            setLoadingBankAccountInfo(false)
            setShowConfirm({
                bank: false,
                third: false
            })

        }
    }
    const inscriptionConfirm = async () => {
        setLoadingBankAccountInfo(true)
        setShowConfirm({
            bank: false,
            third: false
        })
        try {
            const response = await AxioInstance.post('/api/bank_account_inscription_confirm', {
                headers: await getAuthenticatedHeaders()
            })

            setReloadPendings(!reloadPendings)
        } catch (error) {

            setLoadingBankAccountInfo(false)
        }
    }
    const inscriptionThirdPartConfirm = async () => {
        setLoadingBankAccountInfo(true)
        setShowConfirm({
            bank: false,
            third: false
        })
        try {
            const response = await AxioInstance.post('/api/third_party_bank_account_inscription_confirm', {
                headers: await getAuthenticatedHeaders()
            })

            setReloadPendings(!reloadPendings)
        } catch (error) {

            setLoadingBankAccountInfo(false)
        }
    }
    const downloadPendingTirdPart = async () => {
        setLoadingBankAccountInfo(true)
        setShowConfirm({
            bank: false,
            third: false
        })
        try {
            const response = await AxioInstance.post('/api/third_party_bank_account_inscription_file', {
                headers: await getAuthenticatedHeaders()
            })

            setShowConfirm({
                bank: false,
                third: true
            });
            setReloadPendings(!reloadPendings)
            downloadTxt(response.data.data, true)
            //setLoadingBankAccountInfo(false)
        } catch (error) {
            setLoadingBankAccountInfo(false)
            setShowConfirm({
                bank: false,
                third: false
            })
        }
    }
    useEffect(() => {
        const abortController = new AbortController();
        return () => abortController.abort();
    }, [])



    useEffect(() => {
        getData();
        getBusinessMultiproduct()
    }, [reloadOperation])

    useEffect(() => {
        getBankAccountInscriptionInfo()
    }, [])

    useEffect(() => {
        getBusiness()
    }, [reloadOperation])

    useEffect(() => {
        getBankAccountInscriptionInfo()
    }, [reloadPendings, reloadOperation])


    const AgregarArchivo = async (e) => {
        e.preventDefault();
        /*  let formData = new FormData();
         let archivo = (e.target.files[0]);
         formData.append('files', e.target.files[0])

 
         const blob = new Blob([archivo], { type: "text/plain" });
         var url = URL.createObjectURL(blob)

         var reader = new FileReader();
         reader.addEventListener("loadend", function () {
             // reader.result contains the contents of blob as a typed array
         });
         reader.readAsArrayBuffer(blob);

 
         const response = await axios.post('https://dev.cobru.co/api/update/file', formData, {
             headers: await getAuthenticatedHeaders(true),
 
 
         }) */
        setFileTxt(fileInput.current.files[0].name)
        let archivos = e.target.files;
        const formData = new FormData();
        formData.append('file', archivos[0])
        const DEV = localStorage.getItem('DEV');

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + await getAccessToken(),
            'x-api-key': DEV === "https://dev.cobru.co/" ? process.env.REACT_APP_API_KEY_DEV : process.env.REACT_APP_API_KEY

        };

        axios.post((DEV) + 'panel/load_payments_thirdpartywithdraws/', formData, { headers }, {
        }).then(res => { // then print response status
            if (res.data.data === "OK") {
                setShowBanner(true)
                setErrorBanner(false)
            } else {
                setShowBanner(true)
                setErrorBanner(true)
            }



        }).catch((e) => {

            setShowBanner(true)
            setErrorBanner(true)
        })
    }

    return (
        <>
            {ShowBanner && <Banner
                title={'Proceso realizado'}
                description={errorBanner ? `no se pudo subir el archivo` : "Subida de archivo exitosa"}
                setShowBanner={setShowBanner}
                error={errorBanner}
            />}
            {ShowBannerConfirm && <Banner
                title={'Proceso realizado'}
                description={errorBannerConfirm ? `hubo un problema` : "Se marcaron las cuentas como inscritas"}
                setShowBanner={setShowBannerConfirm}
                error={errorBannerConfirm}
            />}
            <div className="grid gap-x-2 gap-y-2 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 ml-3">


                <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${isLoadingBalance ? "mt-6" : "mt-8"}`}>
                                {isLoadingBalance ? <CircularProgress size={31} />
                                    : (parseFloat(USDT).toFixed(2))
                                }

                            </div>
                            <p className="text-gray-700 text-xs">
                                Disponible USDT
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">

                            <div className={`font-bold text-xl mb-2 text-black ${isLoadingBalance ? "mt-6" : "mt-8"}`}>
                                {isLoadingBalance ? <CircularProgress size={31} />
                                    : bolsaBemovil ?
                                        (BTC) : '0'}
                            </div>
                            <p className="text-gray-700 text-xs">
                                Disponible BTC
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full  p-4 text-center pl-0">
                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${isLoadingBalance ? "mt-6" : "mt-8"}`}>
                                {isLoadingBalance ? <CircularProgress size={31} />
                                    : (parseFloat(TRX).toFixed(2))

                                }
                            </div>
                            <p className="text-gray-700 text-xs">
                                Disponible TRX
                            </p>
                        </div>
                    </div>
                </div>


                <div className="w-full p-4 text-center pl-0">

                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${isLoadingBalance ? "mt-6" : "mt-8"}`}>

                                {isLoadingBalance ? <CircularProgress size={31} />
                                    : FormatAmount(COPU)
                                }

                            </div>
                            <p className="text-gray-700 text-xs">
                                Disponible COPU
                            </p>

                        </div>
                    </div>
                </div>
                <div className="w-full p-4 text-center pl-0">

                    <div className="bg-white  rounded overflow-hidden shadow-lg">
                        <div className="px-2 py-4">
                            <div className={`font-bold text-xl mb-2 text-black ${isLoadingBalance ? "mt-6" : "mt-8"}`}>

                                {isLoadingBalance ? <CircularProgress size={31} />
                                    : FormatAmount(COBRU)
                                }

                            </div>
                            <p className="text-gray-700 text-xs">
                                Disponible Cobru
                            </p>

                        </div>
                    </div>
                </div>


            </div>


        </>
    )
}

export default Operacion;