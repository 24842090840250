import React, { useState, useContext, useEffect } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { BsFillCalendarFill } from "react-icons/bs";
import moment from 'moment';
import { FormatAmount, formatoDinero, localeDataPicker } from '../../common/functions';
import axios from 'axios';
import Banner from '../../../components/Banner';
import { CSVLink } from "react-csv";
import NoView from '../../layouts/NoView';
import { AuthContext } from '../../../context/AuthContext';
import Dropzone from 'react-dropzone';
import { /* token, */ isEmpty } from '../../common/functions';

import AxioInstance from '../../../axios/AxiosConfig';
import { CircularProgress } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { FunctionContext } from '../../../context/FuntionsContext';

//import { getAuthenticatedHeaders} from '../common/functions';
const filterCurrency = { cop: 'COP', usd: 'USD' }
const EnviosMutu = (props) => {


    const getPermissions = localStorage.getItem('userDetails')
    const [msg, setMsg] = useState("")
    const [showBanner, setShowBanner] = useState(false);
    const [errorBanner, setErrorBanner] = useState(false);
    const [data, setData] = useState([]);

    const [pending, setPending] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [value, setValue] = useState("transactions");
    const [start_date, setStart_date] = useState(moment().startOf('month'));
    const [end_date, setEnd_date] = useState(moment().endOf('month'));
    const ranges = {
        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Últimos 7 Dias': [moment().subtract(6, 'days'), moment()],
        'Últimos 30 Días': [moment().subtract(29, 'days'), moment()],
        'Último Mes': [moment().startOf('month'), moment().endOf('month')],
        'Mes Anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    };
    const locale = localeDataPicker;
    const maxDate = moment();
    const [label, setLabel] = useState("");
    const csvLink = React.useRef();

    const { getAuthenticatedHeaders, permissions, getAccessToken } = useContext(AuthContext)
    const { reloadRetiros, ReloadSwaps, buscar, buscarDocument, optionSearchClient } = useContext(FunctionContext)
    //const canViewReport = props.canViewReport;
    const canViewReport = permissions.permission_reports;

    const [page, setPage] = useState(1)
    const [newPerPage, setPerPage] = useState(10)
    const [totalRows, setTotalRows] = useState(0)
    const [listWithdraw, setListWithdraw] = useState([]);
    const [pagination, setPagination] = useState(true);
    const [listWithdrawFilter, setListWithdrawFilter] = useState([]);
    const [toggledClearRows, setToggledClearRows] = useState(false);
    const [numFilas, setNumFilas] = useState([]);

    const [activeFilter, setActiveFilter] = useState('COP')

    React.useEffect(() => {
        let start = start_date.format('MMM D YYYY');
        let end = end_date.format('MMM D YYYY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        setLabel(label);

    }, [start_date, end_date]);





    const getListSwaps = async (numPage, paramsReq) => {
        setPending(true);

        try {
            const params = {
                headers: await getAuthenticatedHeaders(),
                pageNum: numPage,
                perPage: newPerPage,
                mutu: 'True',
                currency: activeFilter,
                ...paramsReq
            }
            const response = await AxioInstance.post(`/api/sendsMutu`, params);


            if (response.data.status === 200) {
                setTotalRows(response.data.max_rows)
                const pendings = response.data.data

                let final = !pendings ? [] : pendings
                setListWithdraw(final);
                setListWithdrawFilter(final);
                setPending(false);
            }
            setPending(false);

        } catch (error) {
            setPending(false);
        }
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPending(true);
        try {
            const response = await AxioInstance.post(`/api/sendsMutu`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: page,
                perPage: newPerPage,
                mutu: 'True',
                currency: activeFilter
            });
            if (response.data.status === 200) {
                const pendings = response.data.data
                let final = !pendings ? [] : pendings
                setPerPage(newPerPage)
                setListWithdraw(final);
                setListWithdrawFilter(final);
                setPending(false);
            }
            setPending(false);

        } catch (error) {
            setPending(false);
        }
    }
    const Circular = () => (
        <div style={{ padding: '24px' }}>
            {
                pending &&
                <CircularProgress color="red" size={75} />
            }
        </div>
    );



    const columns = [
        {
            name: 'REF',
            sortable: true,
            selector: 'pk',
            grow: 1,
            cell: row => (

                <div className='flex-row flex items-center justify-start  ' title={row.state === 0 || row.state === 1 ? 'Pendiente' : row.state === 5 ? 'Rechazado' : row.state === 6 ? 'Vencido' : row.state === 3 ? 'Consignado' : row.state === 2 ? 'Depositado' : ''}>
                    {/*    <div className={"rounded-full h-4 w-4  " +
                        (row.state == 0 || row.state == 1
                            ? "bg-yellow-500"
                            : row.state == 2
                                ? "bg-green-500"
                                : row.state == 3
                                    ? "orange"
                                    : row.state == 4 || row.state == 5 || row.state == 6
                                        ? "Color-red"
                                        : "")}></div> */}
                    <p className='ml-3'>{row.pk}</p>
                </div>
            )
        },
        {
            name: 'DESDE',
            grow: 1,
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">

                    <span> {row.from_user_phone}</span>
                </div>
            )
        },
        {
            name: 'PARA',
            grow: 1,
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">

                    <span> {row.to_user_phone}</span>
                </div>
            )
        },
        {
            name: 'MONEDA',

            grow: 1,
            sortable: true,
            selector: 'date',
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span className="text-gray-700 py-3  flex items-center"> {row.currency_code} </span>
                </div>
            )
        },
        {
            name: 'ABONO',
            grow: 2,
            sortable: true,
            selector: 'amount',

            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span> {formatoDinero(row.payed_amount !== undefined ? row.payed_amount : row.amount)}</span>
                </div>
            )
        },
        {
            name: 'FECHA DE ENVIO',

            grow: 2,
            sortable: true,
            selector: 'date',
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span className="text-gray-700 py-3  flex items-center"> {moment(row.date).format('YYYY-MM-DD hh:mm:ss A')} </span>
                </div>
            )
        },
        {
            name: 'METODO',
            grow: 1,
            wrap: true,
            cell: row => (
                <div className="text-gray-700 py-3 flex items-center">
                    <span className='ml-3'>{row.method == 2 ? 'Baloto' : row.method == 3 ? 'Efecty' : row.method == 1 ? 'GDE' : 'Billetera'}</span>
                </div>
            )
        },


    ];
    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };
    const lasfilas = () => {

        if (totalRows <= 10) {
            setNumFilas([10])

        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 50) {
            setNumFilas([10, 25, 50,])
        } else if (totalRows <= 100) {
            setNumFilas([10, 25, 50, 100,])
        } else if (totalRows <= 200) {
            setNumFilas([10, 25, 50, 100, 200,])
        } else if (totalRows <= 500) {
            setNumFilas([10, 25, 50, 100, 200, 500,])
        } else if (totalRows <= 1000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000,])
        } else if (totalRows <= 1500) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500])
        } else if (totalRows <= 2000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        } else {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        }

    }
    const getSearch = async () => {
        if (buscarDocument?.length !== 0) {

            setPending(true);


            let paramsReq = {}
            //<option value={2}>Documento</option>
            //<option value={3}>Usuario</option>

            if (parseInt(optionSearchClient) === 2) {
                paramsReq.send_id = buscarDocument
            }
            if (parseInt(optionSearchClient) === 3) {
                paramsReq.document_number = buscarDocument
            }
            setToggledClearRows(!toggledClearRows)
            getListSwaps(1, paramsReq)

        } else {
            setToggledClearRows(!toggledClearRows)
            getListSwaps(1)
        }
    }

    useEffect(() => {
        getSearch()
    }, [buscar]);

    useEffect(() => {
        setToggledClearRows(!toggledClearRows)
        getListSwaps(1);
    }, [ReloadSwaps, activeFilter])

    useEffect(() => {
        lasfilas()
    }, [totalRows]);
    return canViewReport ? (
        <>
            {showBanner && <Banner
                title={'Proceso realizado'}
                description={msg}
                setShowBanner={setShowBanner}
                error={errorBanner}
            />}

            <div className='flex flex-row text-xs p-1 mb-5 overflow-auto'>
                <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter === filterCurrency.cop ? 'bg-green-600' : 'bg-gray-500')} onClick={() => { setActiveFilter(filterCurrency.cop) }} key="COP" >
                    <span>COP</span>
                </button>
                <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter === filterCurrency.usd ? 'bg-green-600' : 'bg-gray-500')} onClick={() => { setActiveFilter(filterCurrency.usd) }} key="USD" >
                    <span>USD</span>
                </button>

            </div>
            <DataTable
                title={``}
                noHeader={true}
                clearSelectedRows={toggledClearRows}
                paginationResetDefaultPage={toggledClearRows}
                data={listWithdrawFilter}

                columns={columns}


                selectableRowsHighlight={true}
                fixedHeader={true}
                noDataComponent={"No hay movimientos disponibles"}

                pagination={true}
                paginationComponentOptions={paginationOptions}

                progressPending={pending}
                progressComponent={<Circular />}

                paginationTotalRows={totalRows}
                paginationPerPage={newPerPage}
                paginationServer
                paginationRowsPerPageOptions={numFilas}
                onChangePage={getListSwaps}
                onChangeRowsPerPage={handlePerRowsChange}
            /*
            
             */
            />
        </>
    ) : (
        <NoView />
    )

}

export default EnviosMutu;

