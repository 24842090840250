import React, { useState, useEffect, useContext } from 'react'
import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import { /* getAuthenticatedHeaders, */ FormatAmount, FormatPhone } from '../common/functions';
import moment from 'moment';
import Banner from '../../components/Banner';
import approve from "../../assets/images/approve.svg"
import cancel from "../../assets/images/cancel.svg"
import Button from '../../components/Button';
import _, { filter } from 'lodash';
import axios from 'axios';
import AxioInstance from '../../axios/AxiosConfig';

import { FunctionContext } from '../../context/FuntionsContext';
import { AuthContext } from '../../context/AuthContext';
import ModalClientes from "../layouts/ModalClientes"
import { RiContactsBookUploadLine } from 'react-icons/ri';
import { useHistory, } from "react-router-dom";
const Clientes = (props) => {
    const history = useHistory();
    const [state, setState] = useState(null)
    const [amountCheck, setAmountCheck] = useState(0)
    const [loadingCheck, setLoadingCheck] = useState(false)
    const [filtros, setFiltros] = useState(null)
    const [header, setHeader] = useState(true)
    const [page, setPage] = useState(1)
    const [newPerPage, setPerPage] = useState(10)
    const [totalRows, setTotalRows] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const [ResultsNequi, setResultsNequi] = useState({});
    const [showNequi, setShowNequi] = useState(false);

    const { reloadRetiros, setReloadRetiros, buscador, setBuscador, buscar, setRenderDownload, renderDownload,
        setBuscar, RenderUpdate, buscarDocument, optionSearchClient } = useContext(FunctionContext)
    const [numFilas, setNumFilas] = useState([]);
    const [listWithdraw, setListWithdraw] = useState([]);
    const [listWithdrawFilter, setListWithdrawFilter] = useState([]);
    const [activo, setActivo] = useState(true);
    const [pagination, setPagination] = useState(true);
    const [pending, setPending] = useState(true);
    const [data, setData] = useState([]);
    const [dispersion, setDispersion] = useState([]);
    const [dispersionAll, setDispersionAll] = useState([]);
    const [label, setLabel] = useState("");
    const [msn, setMsn] = useState("Se deben seleccionar elementos para poder realizar la acción, No se han modificado elementos.");
    const [error, setError] = useState(false);
    const [errorWithdraws, setErrorWithdraws] = useState(false);
    const [isActiveFilter, setIsActiveFilter] = useState(true);
    const [statuWithdraw, setStatuWithdraw] = useState(window.location.hash === "#creados" ? 0 :
        window.location.hash === "#fraude" ? 6 :
            window.location.hash === "#enproceso" ? 1 :
                window.location.hash === "#consignado" ? 3 :
                    window.location.hash === "#depositado" ? 2 :
                        window.location.hash === "#rechazado" ? 5 :
                            window.location.hash === "#inscritos" ? 7 :
                                window.location.hash === "#inscribiendo" ? 11 :
                                    window.location.hash === "#otrosbancos" ? 12 :
                                        window.location.hash === "#nequi" ? 14 :
                                            window.location.hash === "#bancolombia" ? 13 :
                                                window.location.hash === "#sininscribir" ? 8 : "0");
    const [browserName, setBrowserName] = useState("");
    const [reloadData, setReloadData] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [showBannerDownload, setShowBannerDownload] = useState(false);

    const [infoWithdraws, setInfoWithdraws] = useState(null);
    const [toggledClearRows, setToggledClearRows] = useState(false);
    const [loadingGDE, setLoadingGDE] = useState(false)
    const { getAuthenticatedHeaders, others, isSuperUser } = useContext(AuthContext)
    const [bancos, setBancos] = useState("")
    const [copiar, setCopiar] = useState(false)
    const [idCopiado, setIdCopiado] = useState()
    const [dataModal, setDataModal] = useState([]);
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        if (RenderUpdate) {
            refreshList()
        }

    }, [RenderUpdate])
    useEffect(() => {

        lasfilas()


    }, [totalRows]);


    useEffect(() => {
        if (window.location.hash != "") {
            newRequestState(
                window.location.hash === "#creados" ? 0 :
                    window.location.hash === "#fraude" ? 6 :
                        window.location.hash === "#enproceso" ? 1 :
                            window.location.hash === "#consignado" ? 3 :
                                window.location.hash === "#depositado" ? 2 :
                                    window.location.hash === "#rechazado" ? 5 :
                                        window.location.hash === "#todos" ? "all" : null,


                null

            )
            setStatuWithdraw(window.location.hash === "#creados" ? 0 :
                window.location.hash === "#fraude" ? 6 :
                    window.location.hash === "#enproceso" ? 1 :
                        window.location.hash === "#consignado" ? 3 :
                            window.location.hash === "#depositado" ? 2 :
                                window.location.hash === "#rechazado" ? 5 :
                                    window.location.hash === "#inscritos" ? 7 :
                                        window.location.hash === "#inscribiendo" ? 11 :
                                            window.location.hash === "#otrosbancos" ? 12 :
                                                window.location.hash === "#bancolombia" ? 13 :
                                                    window.location.hash === "#nequi" ? 14 :
                                                        window.location.hash === "#sininscribir" ? 8 :
                                                            window.location.hash === "#todos" ? "all" : null

            );

        }
    }, [])
    const typeDocument = (id) => {
        let type = ''
        if (id == 0) {
            type = 'CC'
        } else if (id == 1) {
            type = 'CE'
        } else if (id == 2) {
            type = 'TI'
        } else if (id == 3) {
            type = 'NIT'
        } else if (id == 4) {
            type = 'PSP'
        }
        return type
    }
    const hashState = (x) => {
        if (x === 0) {
            history.push("/retiros#creados")
        } else if (x === 6) {
            history.push("/retiros#fraude")
        } else if (x === 1) {
            history.push("/retiros#enproceso")
        } else if (x === 3) {
            history.push("/retiros#consignado")
        } else if (x === 2) {
            history.push("/retiros#depositado")
        } else if (x === 5) {
            history.push("/retiros#rechazado")
        }
        else if (x === 7) {
            history.push("/retiros#inscritos")
        }
        else if (x === 8) {
            history.push("/retiros#sininscribir")
        }
        else if (x === 11) {
            history.push("/retiros#inscribiendo")
        } else if (x === 12) {
            history.push("/retiros#otrosbancos")
        } else if (x === 13) {
            history.push("/retiros#bancolombia")
        }
        else if (x === 14) {
            history.push("/retiros#nequi")
        }

        else if (x === "all") {
            history.push("/retiros#todos")
        }



        newRequestState(x, null)
        setStatuWithdraw(x);
        setIsActiveFilter(true);
    }

    const lasfilas = () => {

        if (totalRows <= 10) {
            setNumFilas([10])

        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 50) {
            setNumFilas([10, 25, 50,])
        } else if (totalRows <= 100) {
            setNumFilas([10, 25, 50, 100,])
        } else if (totalRows <= 200) {
            setNumFilas([10, 25, 50, 100, 200,])
        } else if (totalRows <= 500) {
            setNumFilas([10, 25, 50, 100, 200, 500,])
        } else if (totalRows <= 1000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000,])
        } else if (totalRows <= 1500) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500])
        } else if (totalRows <= 2000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        } else {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        }

    }
    useEffect(() => {

        getSearch()


    }, [buscar]);

    useEffect(() => {
        if (copiar == true) {


            const timer = setTimeout(() => {
                setCopiar(false)
            }, 2000);
        }
    }, [copiar]);


    useEffect(() => {
        if (toggledClearRows == true) {
            setToggledClearRows(false);
        }
    }, [toggledClearRows]);


    useEffect(() => {
        if (label === "bulk_deposit" || label === "bulk_consigned") {
            updateStatusWithdraw(data);

        }
    }, [bancos])

    useEffect(() => {
        getListWithdrawPending();
    }, [reloadRetiros])

    useEffect(() => {
        if (data) {
            SumaCheck()

        }
    }, [data])

    useEffect(() => {
        getBrowserInfo()
    }, [])

    const handleClearRows = () => {
        setToggledClearRows(!toggledClearRows)
    }

    const getBrowserInfo = async () => {

        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
            setBrowserName('Opera');
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            setBrowserName('Chrome');
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            setBrowserName('Safari');
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            setBrowserName('Firefox');
        } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
            setBrowserName('IE');
        } else {
            setBrowserName('Unknown');
        }
    }
    const SumaCheck = () => {
        setLoadingCheck(true)

        var total = 0
        data.map((item) => {
            try {
                total += item.payed_amount
            } catch (error) {

            }
        });
        setLoadingCheck(false)

        setAmountCheck(total);
    }
    const getListWithdrawPending = async () => {
        setIsLoading(true);
        setPending(true);


        try {

            const response = await AxioInstance.post(`/api/clientes`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: page,
                perPage: newPerPage,
            });



            if (response.data.status === 200) {
                setTotalRows(response.data.max_rows)


                //const pendings = await formatArray(response.data.data)
                const pendings = response.data.data


                let final = pendings
                setListWithdraw(final);
                setListWithdrawFilter(final);
                /* setListWithdraw(response.data.data);
                setListWithdrawFilter(response.data.data); */
                setPending(false);

            }

            setIsLoading(false)
            setPending(false);

        } catch (error) {

            setIsLoading(false)
            setPending(false);

        }
    }

    const getSearch = async () => {
        if (buscarDocument.length !== 0) {

            setIsLoading(true);
            setPending(true);
            var withdraw_id = ''
            var document = ''
            var username = ''
            if (buscarDocument[0] === "#") {
                username = buscarDocument
            } else {
                if (buscarDocument.length < 7) {
                    withdraw_id = buscarDocument

                } else {
                    document = buscarDocument
                }
            }

            try {
                let paramsReq = {
                    headers: await getAuthenticatedHeaders(),
                    pageNum: page,
                    perPage: newPerPage,

                }
                //<option value={2}>Documento</option>
                //<option value={3}>Usuario</option>

                if (parseInt(optionSearchClient) === 2) {
                    paramsReq.document_number = buscarDocument
                }
                if (parseInt(optionSearchClient) === 3) {
                    paramsReq.username = buscarDocument
                }
                const response = await AxioInstance.post(`/api/clientes`, paramsReq);



                if (response.data.status === 200) {
                    setTotalRows(response.data.max_rows)

                    //const pendings = await formatArray(response.data.data)
                    const pendings = response.data.data
                    /*    const thirdPart = await getUserTrirdPartWithDraws() */

                    /* let finalArray = pendings.concat(thirdPart) */
                    let final = _.sortBy(pendings, ["date_created"]).reverse();
                    setListWithdraw(final);
                    setListWithdrawFilter(final);
                    /* setListWithdraw(response.data.data);
                    setListWithdrawFilter(response.data.data); */
                    setPending(false);
                }

                setIsLoading(false)
                setPending(false);

            } catch (error) {

                setIsLoading(false)
                setPending(false);

            }
        } else {

            setIsLoading(true);
            setPending(true);
            refreshList()
        }
    }
    const refreshList = async (filtro) => {
        /* setIsLoading(true);
        setPending(true); */


        try {


            const response = await AxioInstance.post(`/api/clientes`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: page,
                perPage: newPerPage,
            });



            if (response.data.status === 200) {
                setTotalRows(response.data.max_rows)

                const pendings = response.data.data


                let final = pendings


                setListWithdraw(final);
                setListWithdrawFilter(final);
                /* setListWithdraw(response.data.data);
                setListWithdrawFilter(response.data.data); */
                setPending(false);

            }

            setIsLoading(false)
            setPending(false);

        } catch (error) {

            setIsLoading(false)
            setPending(false);

        }
    }

    const formatArray = async (data) => {
        let array = []

        data.map((res) => {
            return (
                array.push({
                    amount: res.amount,
                    bank_account: res.bank_account,
                    date: moment(res.date).format('YYYY-MM-DD HH:mm:ss A'),
                    name: res.account_holder_name,
                    payed_amount: res.payed_amount,
                    pk: res.pk,
                    state: res.state,
                    state_bank: res.state_bank,

                })
            )
        })

        return array;
    }

    const changeBankState = (state) => {
        let text = ''
        if (state === 0) {
            text = 'Creada'
        } else if (state === 1) {
            text = 'En proceso de inscripcion'
        } else if (state === 2) {
            text = 'inscrita'
        } else if (state === 3) {
            text = 'tiene un problema'
        }
        return text
    }
    const bankName = (id) => {

        let banco = ''
        if (id == 0) {
            banco = 'Bancolombia'
        } else if (id == 1) {
            banco = 'Banco Bogota'
        } else if (id == 2) {
            banco = ' Davivienda'
        } else if (id == 3) {
            banco = 'BBVA'
        } else if (id == 4) {
            banco = 'Banco Occidente'
        } else if (id == 5) {
            banco = 'Colpatria'
        } else if (id == 6) {
            banco = 'Banco Popular'
        } else if (id == 7) {
            banco = 'Helm Bank'
        } else if (id == 8) {
            banco = 'Banco Agrario'
        } else if (id == 9) {
            banco = 'Banco BCSC'
        } else if (id == 10) {
            banco = 'Banco CorpBanca'
        } else if (id == 11) {
            banco = 'Banco Citibank'
        } else if (id == 12) {
            banco = 'Banco AV Villas'
        } else if (id == 13) {
            banco = 'Banco ProCredit'
        } else if (id == 14) {
            banco = 'Banco Pichincha'
        } else if (id == 15) {
            banco = 'Banco Bancoomeva'
        } else if (id == 16) {
            banco = 'Banco Santander'
        } else if (id == 17) {
            banco = 'Banco Falabella'
        } else if (id == 18) {
            banco = 'Nequi'
        } else if (id == 19) {
            banco = 'DaviPlata'
        } else if (id == 20) {
            banco = 'Banco Finandina'
        } else if (id == 21) {
            banco = 'Banco Itaú'
        } else if (id == 22) {
            banco = 'Banco Itaú CorpBanca'
        } else if (id == 23) {
            banco = 'Lulo Bank'
        } else if (id == 24) {
            banco = 'Coltefinanciera'
        } else if (id == 25) {
            banco = "Ualá"
        } else if (id == 26) {
            banco = "Banco Cooperativo Coopcentral"
        } else if (id == 27) {
            banco = "Rappi pay"
        }
        else {
            banco = ''
        }

        return banco
    }
    const cHangeName = (item) => {
        let name = ''
        if (item.type === "Retiro a tercero") {
            if (item.account_holder_name && item.owner_first_name) {
                name = ` ${item.owner_first_name}`
            } else {
                name = item.owner_first_name
            }
            //name = item.account_holder_name ? item.account_holder_name + ' - ' + item.owner_first_name ? item.owner_first_name : '' :''
        } else if (item.type === "Retiro") {
            /* if (item.account_holder_name && item.payer_id ) {
                name = `${item.account_holder_name} - ${item.owner_first_name}`
            } else {
                name = item.account_holder_name ? item.account_holder_name :''
            } */
            name = item.account_holder_name ? item.account_holder_name : ''
        } else {
            name = item.account_holder_name ? item.account_holder_name : ''

        }
        return name
    }


    const copy = (text) => {

        var id = "el-id-del-textarea";
        var existsTextarea = document.getElementById(id);

        if (!existsTextarea) {

            var textarea = document.createElement("textarea");
            textarea.id = id;
            // Coloca el textarea en el borde superior izquierdo
            textarea.style.position = 'fixed';
            textarea.style.top = 0;
            textarea.style.left = 0;


            // Asegurate que las dimensiones del textarea son minimas, normalmente 1px 
            // 1em no funciona porque esto generate valores negativos en algunos exploradores
            textarea.style.width = '1px';
            textarea.style.height = '1px';

            // No se necesita el padding
            textarea.style.padding = 0;

            // Limpiar bordes
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';

            // Evitar el flasheo de la caja blanca al renderizar
            textarea.style.background = 'transparent';
            document.querySelector("body").appendChild(textarea);

            existsTextarea = document.getElementById(id);
        } else {

        }

        existsTextarea.value = text;
        existsTextarea.select();

        try {
            var status = document.execCommand('copy');
            if (!status) {
                console.error("No se pudo copiar el texto");
            } else {
                setCopiar(true)
                setIdCopiado(text)
            }
        } catch (err) {

        }

    }
    function formatDate(x) {
        let currentTimestamp = x - 18000000
        // get current timestamp
        /* let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(currentTimestamp) */
        let date = moment(x).format('DD/MM/YYYY HH:mm A')

        return date;
    }

    const changeTextState = (state) => {
        let text = ''


        if (state.state === 0) {
            text = 'Pendiente'
        } else if (state.state === 1) {
            text = 'En proceso'
        } else if (state.state === 2) {
            text = `Depositado  ${state.bank_dispersion !== null && state.bank_dispersion !== undefined && state.bank_dispersion !== "" ? `por ${state.bank_dispersion}` : ""}`
        } else if (state.state === 3) {
            text = `Consignado  ${state.bank_dispersion !== null && state.bank_dispersion !== undefined && state.bank_dispersion !== "" ? `por ${state.bank_dispersion}` : ""}`
        } else if (state.state === 4) {
            text = 'Hubo un problema'
        } else if (state.state === 5) {
            text = 'Rechazado por el banco'
        } else if (state.state === 6) {
            text = 'Fraude'

        }
        return text
    }
    const changeBgColor = (state) => {
        let color = ''
        if (state === 0) {
            color = 'bg-yellow-500'
        } else if (state === 1) {
            color = 'bg-yellow-500'
        } else if (state === 2) {
            color = 'bg-green-500'
        } else if (state === 3) {
            color = 'orange'
        } else if (state === 4) {
            color = 'Color-red'
        } else if (state === 5) {
            color = 'Color-red'
        } else if (state === 6) {
            color = 'Color-red'
        }
        return color
    }
    const getDate = (data) => {
        let date = data.date_created
        if (data.state === 0) {
            date = data.date_created
        } else if (data.state === 1) {
            date = data.date_created
        } else if (data.state === 2) {
            date = data.date_deposited
        } else if (data.state === 3) {
            date = data.date_consigned
        } else if (data.state === 4) {
            date = data.date_rejected
        } else if (data.state === 5) {
            date = data.date_rejected
        } else if (data.state === 6) {
            date = data.date_rejected
        }
        return (date)
    }
    const ActivarModal = async (data) => {

        setDataModal(data)
        setOpenModal(true)

    }
    const getUserTrirdPartWithDraws = async () => {
        try {
            const response = await AxioInstance.post(`/api/thirdpartywithdraw/getall`, {

                headers: await getAuthenticatedHeaders(),
            });

            if (response.data.status === 200) {



                /* let array = []

                response.data.data.map((res) => {
                    return (
                        array.push({
                            pk: res.pk,
                            amount:res.amount,
                            bank_account: res.account_bank,
                            name: res.account_holder_name,
                            state: changeTextState(res.state),
                            state_bank: changeBankState(res.account_state),
                            payed_amount: res.payed_amount,
                            date: res.date_created,
                            date_consigned:res.date_consigned,
                            date_created: res.date_created,
                            date_deposited: res.date_deposited,
                            date_rejected: res.date_rejected
                        })
                    )
                })

                 */
                return response.data.data;
            } else {
                return []
            }
        } catch (error) {

            return []

        }
    }

    const columns = [{
        name: 'REF',
        cell: row => (
            <div className={'flex-row flex items-center justify-start'}>
                <div className='links cursor-pointer'
                    style={{ fontWeight: 700 }}>
                    <p onClick={() => ActivarModal(row.id)}>

                        {row.id}
                    </p>
                </div>
            </div>)
        ,
        sortable: true,
    },


    {


        name: 'NOMBRE',
        selector: 'name',
        cell: row => (<div >{row.user.first_name} {row.user.last_name}</div>

        )
    },

    {
        name: 'USUARIO',
        cell: row => <div >{row.user.username}</div>,
        sortable: true,
    },
    {
        name: 'DOCUMENTO',
        cell: row => (
            <div>{typeDocument(row.document_type)} {row.document_number}</div>

        ),
        //cell: row => <div>{moment(row.date).format('YYYY-MM-DD HH:mm:ss A')}</div>,
        sortable: true,
    },

    {
        name: 'CELULAR',
        //selector: 'state',
        cell: row => (
            <div> {
                FormatPhone(row.phone)
            }</div>
        ),
        sortable: true,
    },
    {
        name: 'CREACIÓN',
        //selector: 'state',
        cell: row => (
            <div> {
                moment(row.date_created).format('YYYY-MM-DD HH:mm:ss A')

            }</div>
        ),
        sortable: true,
    },


    ];
    const handleFilter = (e) => {
        let filter = e.currentTarget.value;
        if (filter !== "all") {
            if (filter < 7) {

                newRequestState(filter, null)
                setStatuWithdraw(filter);
                setIsActiveFilter(true);
            } else {
                incritosList(filter)
                setStatuWithdraw(filter);
                setIsActiveFilter(true);
            }

        } else {
            setStatuWithdraw("all");
            newRequestState(null, null)
            setIsActiveFilter(false);
        }
    }

    const handleChange = async ({ selectedRows }) => {

        if (selectedRows.length === 0) {
            setHeader(true);
        } else {
            setHeader(false)

        }

        setData(selectedRows);
    };
    const handleChangeSelect = (value) => {

        setLabel(value);
    }
    const sendProcessBackend = async () => {
        if (label === 'download_bank_payment_file_of_selected') {
            if (data.length > 0) {
                //downloadCSV(data);
                downloadFileBankPaymentSelected(data);
            } else {
                setError(true);
            }

        } else if (label === 'bulk_deposit_nequi') {
            setBancos("Nequi")
            setLabel("bulk_deposit_nequi")
            DispersionNequi(data);
        }
        else if (label === 'download_bank_payment_file_of_all') {

            downloadFileBankPaymentAll(data);
        } else if (label === 'bulk_deposit' || label === 'bulk_consigned' || label === 'bulk_reject' || label === 'fraud_reject') {

            if (data.length > 0) {
                updateStatusWithdraw(data);
            } else {
                setError(true);
            }
        } else if (label === 'bulk_deposit_Bancolombia') {

            setBancos("Bancolombia")
            setLabel("bulk_deposit")


        } else if (label === 'bulk_deposit_BBVA') {
            setBancos("BBVA")
            setLabel("bulk_deposit")


        }
        else if (label === 'bulk_deposit_Avvilla') {
            setBancos("avvilla")
            setLabel("bulk_deposit")


        } else if (label === 'bulk_deposit_Depósito') {
            setBancos("Depósito")
            setLabel("bulk_deposit")


        } else if (label === 'bulk_consigned_Bancolombia') {
            setBancos("Bancolombia")
            setLabel("bulk_consigned")



        } else if (label === 'bulk_consigned_Avvilla') {
            setBancos("avvilla")
            setLabel("bulk_consigned")



        } else if (label === 'bulk_consigned_BBVA') {
            setBancos("BBVA")
            setLabel("bulk_consigned")


        } else if (label === 'bulk_consigned_Depósito') {
            setBancos("Depósito")
            setLabel("bulk_consigned")


        } else if (label === 'retired_bank_pagosgde') {

            sendPagosGDE(data);
        } /* else if (label === 'inscription') {
                    inscriptonsBank(data)
                } */ else {

            setMsn("Ninguna opción seleccionada")
        }
    }

    const updateStatusWithdraw = async (data) => {
        setActivo(false)
        setLoadingGDE(true)

        /*  let pks = [];
         _.filter(data, function(o){
             pks.push(parseInt(o.pk))           
             return (parseInt(o.pk))
         });
     

         data = {
             pks: pks,
             selected: true,
             status: label,
             headers : await getAuthenticatedHeaders(),
     
         } */
        let pks = [];
        _.filter(data, (array) => {
            pks.push({
                pk: parseInt(array.pk),
                type: array.type === "Retiro a tercero" ? 'third_party_withdraw' : 'withdraw'
            })
        })

        data = {
            data: pks,
            status: label,
            /* selected: true */
            bank_dispersion: bancos,
            headers: await getAuthenticatedHeaders(),
        }

        try {
            const response = await AxioInstance.post(`/api/withdraw/update_status_withdraw`, data);

            if (response.data.status === 200) {

                refreshList();
                /* setReloadRetiros(!reloadRetiros)
                setLabel('') */
            } else {
                setErrorWithdraws(true)
                setShowBanner(true)
            }
        } catch (error) {

            setIsLoading(true);
            setErrorWithdraws(true)
            setShowBanner(true)


        }
        setBancos("")
        setLabel("")
        setToggledClearRows(true)
        setActivo(true)
        setLoadingGDE(false)

    }
    const inscriptonsBank = async (data) => {
        try {

        } catch (error) {

        }

    }

    const DispersionNequi = async (data) => {
        setLoadingGDE(true)
        setActivo(false)
        let pks = [];
        _.filter(data, (array) => {
            pks.push(
                parseInt(array.pk),
            )
        })
        /*  _.filter(data, function(o){
                    pks.push(parseInt(o.pk))           
             return (parseInt(o.pk))
         }); */
        data = {
            pks: pks,
            headers: await getAuthenticatedHeaders(),
        }

        try {
            const response = await AxioInstance.post(`/api/withdraw/dispersar_to_nequi`, data);
            if (response.data.status === 200) {

                refreshList();
                setShowNequi(true)
                setResultsNequi(response.data.data)
                /* setReloadRetiros(!reloadRetiros)
                setLabel('') */
            } else {
                setErrorWithdraws(true)
                setShowBanner(true)
            }
        } catch (error) {

            setIsLoading(true);
            setErrorWithdraws(true)
            setShowBanner(true)


        }
        setBancos("")
        setLabel("")
        setToggledClearRows(true)
        setActivo(true)
        setLoadingGDE(false)
    }

    const downloadFileBankPaymentSelected = async (data) => {
        setLoadingGDE(true)
        setActivo(false)
        let pks = [];
        _.filter(data, (array) => {
            pks.push(
                parseInt(array.pk),
            )
        })
        /*  _.filter(data, function(o){
                    pks.push(parseInt(o.pk))           
             return (parseInt(o.pk))
         }); */
        data = {
            data: pks,
            /* selected: true,
            status: label, */
            headers: await getAuthenticatedHeaders(),
        }

        try {
            const response = await AxioInstance.post(`/api/withdraw/download_file_bankpayment_selected`, data);
            if (response.data.status === 200) {
                setDispersion(response.data.data);
                setShowBannerDownload(true)
                setRenderDownload(!renderDownload)
                //setReloadRetiros(!reloadRetiros)
                //setLabel('')
                //handleClearRows()
                /* setTimeout(() => {
                    window.location.reload()
                }, 800); */
            }
        } catch (error) {

            setIsLoading(true);
        }
        setBancos("")
        setLabel("")
        setToggledClearRows(true)
        setActivo(true)
        setLoadingGDE(false)

    }
    const downloadTxt = async (data) => {
        setActivo(false)

        const link = document.createElement('a');
        if (data == null) return;

        const filename = 'pago_dispersion.txt';
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
        link.setAttribute('download', filename);
        link.click();
        setReloadRetiros(!reloadRetiros)
        //window.location.reload();
        setBancos("")
        setLabel("")
        setToggledClearRows(true)
        setActivo(true)
    }
    const downloadFileBankPaymentAll = async (data) => {
        setLoadingGDE(true)

        setActivo(false)
        let pks = [];
        _.filter(data, function (o) {
            pks.push(parseInt(o.pk))
            return (parseInt(o.pk))
        });

        let dataTo = {
            pks: pks,
            /* selected: true,
            status: label, */
            headers: await getAuthenticatedHeaders(),

        }
        try {
            const response = await AxioInstance.post(`/api/withdraw/download_file_bankpayment_all`, dataTo);
            if (response.data.status === 200) {

                setDispersion(response.data.data);
                downloadTxt(response.data.data);
                //setReloadRetiros(!reloadRetiros)
                //handleClearRows()
                setTimeout(() => {
                    window.location.reload()
                }, 800);
            }
        } catch (error) {

            setIsLoading(true);
        }

        /* try {
            const response = await axios.get(`/api/withdraw/download_file_bankpayment_all`, {
                    headers : await getAuthenticatedHeaders(),
            });
     
                if (response.data.status === 200) {

                setDispersionAll(response.data.data);
                downloadTxt(response.data.data);
            }
        } catch (error) {

                setIsLoading(true);
        } */
        setBancos("")
        setLabel("")
        setToggledClearRows(true);
        setActivo(true)
        setLoadingGDE(false)

    }

    const sendPagosGDE = async (row) => {
        setActivo(false)
        setLoadingGDE(true);
        const data = {
            withdraws: row,
            name_device: browserName,
            system_operative: 'WEB'
        }


        try {
            const response = await AxioInstance.post(`/api/withdraw/bank_pagosgde`, {
                withdraws: row,
                name_device: browserName,
                system_operative: 'WEB',
                headers: await getAuthenticatedHeaders(),

            });

            if (response.status === 200) {

                setInfoWithdraws(response.data.data)
                setShowBanner(true)
                setLoadingGDE(false);
                setReloadData(true)
            } else {

                setErrorWithdraws(true)
                setShowBanner(true)
                setLoadingGDE(false);

            }
            setLoadingGDE(false);

        } catch (error) {

            setLoadingGDE(false);
            setInfoWithdraws(null)
            setShowBanner(false)
            setErrorWithdraws(true)

        }
        setBancos("")
        setLabel("")
        setToggledClearRows(true)
        setActivo(true)
    }


    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };

    const Circular = () => (
        <div style={{ padding: '24px' }}>
            {
                isLoading &&
                <CircularProgress color="red" size={75} />
            }
        </div>
    );
    const incritosList = (filter) => {

        var filtro = []
        if (filter == 8) {
            setFiltros(0)
            newRequestState(null, 0)

        } else if (filter == 7) {
            setFiltros(2)
            newRequestState(0, 2)

        }
        else if (filter == 9) {
            listWithdraw.map((item) => {
                try {
                    (item.owner_first_name) !== "Construir Comundo SAS" && (item.owner_first_name) !== "Tecnovelas sas" ?
                        filtro.push(item) :
                        console.log(null);
                } catch (error) {

                }
            });
        }
        else if (filter == 10) {
            listWithdraw.map((item) => {
                try {
                    bankName(item.account_bank !== undefined ? item.account_bank : item.bank_name) === "BBVA" ?
                        filtro.push(item) :
                        console.log(null);
                } catch (error) {

                }
            });
        }
        else if (filter == 11) {
            setFiltros(1)
            newRequestState(null, 1)
        }
        else if (filter == 12) {
            listWithdraw.map((item) => {
                try {
                    bankName(item.account_bank !== undefined ? item.account_bank : item.bank_name) !== "Nequi" && bankName(item.account_bank !== undefined ? item.account_bank : item.bank_name) !== "Bancolombia" ?
                        filtro.push(item) :
                        console.log(null);
                } catch (error) {

                }
            });
        }

        else {

        }
        setListWithdrawFilter(filtro)

    };
    const filterName = () => {

    }

    const contextActions = () => (

        <>
            <div className="flex md:flex-row text-left">

                <p className='mr-5 mt-1'>${new Intl.NumberFormat(["ban", "id"]).format(amountCheck)}</p>
                <div className="w-full md:w-3/4 mr-2 input-borders ">
                    <NativeSelect
                        className="input-border-green background-white "
                        id="demo-customized-select-native"
                        defaultValue={label}
                        value={label}
                        onChange={e => handleChangeSelect(e.target.value)}
                    >
                        <option aria-label="None" disabled value="">Escoge una acción</option>
                        <option disabled>--------------------------------------</option>
                        <option value={"download_bank_payment_file_of_selected"}>Generar archivo para dispersión</option>
                        <option disabled>--------------------------------------</option>
                        {/* <option value={"download_bank_payment_file_of_all"}>Dispersion de pago de todos</option> */}
                        {/*                 <option value={"bulk_deposit"}>Depositar</option>
                        <option value={"bulk_consigned"}>Consignar</option> */}
                        <option value={"bulk_consigned_Avvilla"}>Consignado por AV Villas</option>
                        <option value={"bulk_consigned_Bancolombia"}>Consignado por Bancolombia</option>
                        <option value={"bulk_consigned_BBVA"}>Consignado por BBVA</option>
                        <option value={"bulk_consigned_Depósito"}>Consignado por Depósito</option>
                        {/* <option value={"retired_bank_pagosgde"}>Consignar por Depósito</option> */}
                        <option disabled>--------------------------------------</option>
                        <option value={"bulk_deposit_nequi"}>Dispersar por Nequi</option>
                        <option disabled>--------------------------------------</option>
                        <option value={"bulk_deposit_Avvilla"}>Depositado por AV Villas</option>
                        <option value={"bulk_deposit_Bancolombia"}>Depositado por Bancolombia</option>
                        <option value={"bulk_deposit_BBVA"}>Depositado por BBVA</option>
                        <option value={"bulk_deposit_Depósito"}>Depositado por Depósito</option>
                        <option disabled>--------------------------------------</option>

                        <option value={"bulk_reject"}>Rechazado por el banco</option>
                        <option value={"fraud_reject"}>Fraude</option>
                        {/* <option value={"inscription"}>Inscripcion</option> */}
                    </NativeSelect>
                </div>
                <div className="w-full md:w-1/3 pl-2 ">
                    {label !== "" && activo ? (<button
                        className="py-1 px-4 flex bg-green-600 hover:bg-green-600 border-green-600 hover:green-600 text-sm border-4 text-white  rounded-full button-outline-off"
                        type="button"
                        onClick={sendProcessBackend}
                    >
                        {loadingGDE ? 'Procesando...' : 'Aplicar'}
                    </button>
                    ) : (<button
                        className="py-1 px-4 flex  text-sm border-4 text-white rounded-full btn-retiros button-outline-off bloqued-cursor"
                        type="button"


                    >
                        {loadingGDE ? 'Procesando...' : 'Aplicar'}
                    </button>)}

                </div>
            </div>
        </>
    )
    const newRequest = async (x, y) => {
        setIsLoading(true);
        setPending(true);

        setPerPage(x)
        try {

            const response = await AxioInstance.post(`/api/list/withdraw`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: page,
                perPage: x,

            });


            setPage(page)
            setPerPage(x)
            if (response.data.status === 200) {
                setTotalRows(response.data.max_rows)

                //const pendings = await formatArray(response.data.data)
                const pendings = response.data.data


                let final = pendings

                setListWithdraw(final);
                setListWithdrawFilter(final);
                /* setListWithdraw(response.data.data);
                setListWithdrawFilter(response.data.data); */
                setPending(false);

            }

            setIsLoading(false)
            setPending(false);

        } catch (error) {

            setIsLoading(false)
            setPending(false);

        }
    }

    const newRequestPage = async x => {
        setIsLoading(true);
        setPending(true);


        try {

            const response = await AxioInstance.post(`/api/clientes`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: x,
                perPage: newPerPage,

            });




            setPage(x)
            setPerPage(newPerPage)
            if (response.data.status === 200) {
                setTotalRows(response.data.max_rows)

                //const pendings = await formatArray(response.data.data)

                const pendings = response.data.data


                let final = pendings
                setListWithdraw(final);
                setListWithdrawFilter(final);
                /* setListWithdraw(response.data.data);
                setListWithdrawFilter(response.data.data); */
                setPending(false);

            }

            setIsLoading(false)
            setPending(false);

        } catch (error) {

            setIsLoading(false)
            setPending(false);

        }
    }
    const newRequestState = async (state, filtross) => {

        setIsLoading(true);
        setPending(true);


        try {

            const response = await AxioInstance.post(`/api/clientes`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: 1,
                perPage: newPerPage,

            });



            setState(state)
            setPage(1)
            setPerPage(newPerPage)
            if (response.data.status === 200) {
                setTotalRows(response.data.max_rows)

                //const pendings = await formatArray(response.data.data)
                const pendings = response.data.data


                let final = pendings
                setListWithdraw(final);
                setListWithdrawFilter(final);
                /* setListWithdraw(response.data.data);
                setListWithdrawFilter(response.data.data); */
                setPending(false);

            }

            setIsLoading(false)
            setPending(false);

        } catch (error) {

            setIsLoading(false)
            setPending(false);

        }
    }

    const handlePageChange = page => {
        newRequestPage(page);
    };

    return (
        <>
            {/* <div className="flex md:flex-row flex-wrap p-1 overflow-x-auto text-xs md:flex-wrap">  */}
            {/* <div className="flex overflow-scroll text-xs flex-row items-center">
            <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + ((!isActiveFilter && statuWithdraw === 'all' ) ? 'bg-green-600': 'bg-gray-500' )}  onClick={handleFilter} key="all" value="all">
                    <span>Todos</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Creado"  ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="0" value="Creado">
                    <span>Creados</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "En proceso"  ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="1" value="En proceso">
                    <span>En proceso</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Depositado"  ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="2" value="Depositado">
                    <span>Depositado</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Consignado"  ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="3" value="Consignado">
                    <span>Consignado</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3  " + (isActiveFilter && statuWithdraw === "Rechazado por el banco" ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="5" value="Rechazado por el banco">
                    <span>Rechazado por el banco</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Fraude" ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="6" value="Fraude">
                    <span>Fraude</span>
                </button>
            </div> */}

            {openModal === true &&
                <ModalClientes modal={() => setOpenModal()} open={openModal} id={dataModal} />
            }
            {/*datatble*/}

            <div className="flex md:flex-row flex-wrap p-2">


                <div className="w-full mt-5 ">

                    {
                        showBanner && infoWithdraws && (
                            <Banner
                                title={'Proceso realizado'}
                                description={`Problemas con el banco ${infoWithdraws.total_prob_bank} se depositaron ${infoWithdraws.total_approved} retiros seleccionado se rechazaron ${infoWithdraws.total_rejected} retiros seleccionados`}
                                setShowBanner={setShowBanner}
                                error={false}
                            />
                        )
                    }
                    {
                        showNequi && (
                            <Banner
                                title={'Proceso realizado'}
                                description={`aprobados: ${ResultsNequi.total_approved} - rechazados: ${ResultsNequi.total_rejected}`}
                                setShowBanner={setShowNequi}
                                error={false}
                            />
                        )
                    }
                    {
                        showBannerDownload && (
                            <Banner
                                title={'Proceso realizado'}
                                description={`El archivo de dispersión se está procesando`}
                                setShowBanner={setShowBannerDownload}
                                error={false}
                            />
                        )
                    }

                    {
                        showBanner && errorWithdraws && (
                            <Banner
                                title={'Proceso realizado'}
                                description={`Ah ocurrido un error, por favor intentelo mas tarde`}
                                setShowBanner={setShowBanner}
                                error={errorWithdraws}
                            />
                        )
                    }
                    {/* {
                        isLoading && (
                            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Circular />
                            </div>
                        )
                    } */}

                    <DataTable
                        title={``}
                        noHeader={header}
                        clearSelectedRows={toggledClearRows}
                        data={listWithdrawFilter}
                        columns={columns}

                        selectableRowsHighlight={true}
                        fixedHeader={true}
                        noDataComponent={"No hay movimientos disponibles"}

                        pagination={pagination}
                        paginationComponentOptions={paginationOptions}
                        progressPending={pending}
                        progressComponent={<Circular />}
                        onSelectedRowsChange={handleChange}
                        contextActions={contextActions()}
                        /* actions={contextActions(label)} */
                        paginationTotalRows={totalRows}
                        paginationPerPage={newPerPage}
                        paginationServer
                        paginationRowsPerPageOptions={numFilas}
                        onChangeRowsPerPage={newRequest}
                        onChangePage={handlePageChange}
                    />

                </div>
            </div>

        </>
    )
}

export default Clientes
