import React, { useState, useEffect, useContext } from 'react'
import DataTable from 'react-data-table-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import { /* getAuthenticatedHeaders, */ FormatAmount, formatCurrency } from '../../common/functions';
import moment from 'moment';
import Banner from '../../../components/Banner';
import approve from "../../../assets/images/approve.svg"
import cancel from "../../../assets/images/cancel.svg"
import _, { filter } from 'lodash';
import { FunctionContext } from '../../../context/FuntionsContext';
import { AuthContext } from '../../../context/AuthContext';
import ModalDescripcion from "../../layouts/ModalDescripcion"
import { useHistory, } from "react-router-dom";
import useGeolocation from "react-hook-geolocation";
import AxioInstance from '../../../axios/AxiosConfig';


const RetirosMutu = (props) => {
    const DEV = localStorage.getItem('DEV');

    const { latitude, longitude } = useGeolocation();
    const history = useHistory();
    const [state, setState] = useState(null)
    const [amountCheck, setAmountCheck] = useState(0)
    const [loadingCheck, setLoadingCheck] = useState(false)
    const [filtros, setFiltros] = useState(null)
    const [header, setHeader] = useState(true)
    const [page, setPage] = useState(1)
    const [newPerPage, setPerPage] = useState(10)
    const [totalRows, setTotalRows] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const [ResultsNequi, setResultsNequi] = useState({});
    const [showNequi, setShowNequi] = useState(false);

    const { reloadRetiros, setReloadRetiros, buscador, setBuscador, buscar, setRenderDownload, renderDownload,
        setBuscar, RenderUpdate, opcionRetiros } = useContext(FunctionContext)
    const [numFilas, setNumFilas] = useState([]);
    const [listWithdraw, setListWithdraw] = useState([]);
    const [listWithdrawFilter, setListWithdrawFilter] = useState([]);
    const [activo, setActivo] = useState(true);
    const [pagination, setPagination] = useState(true);
    const [pending, setPending] = useState(true);
    const [data, setData] = useState([]);
    const [dispersion, setDispersion] = useState([]);
    const [dispersionAll, setDispersionAll] = useState([]);
    const [label, setLabel] = useState("");
    const [msn, setMsn] = useState("Se deben seleccionar elementos para poder realizar la acción, No se han modificado elementos.");
    const [error, setError] = useState(false);
    const [errorWithdraws, setErrorWithdraws] = useState(false);
    const [isActiveFilter, setIsActiveFilter] = useState(true);
    const [statuWithdraw, setStatuWithdraw] = useState(window.location.hash === "#creados" ? 0 :
        window.location.hash === "#fraude" ? 6 :
            window.location.hash === "#enproceso" ? 1 :
                window.location.hash === "#consignado" ? 3 :
                    window.location.hash === "#depositado" ? 2 :
                        window.location.hash === "#rechazado" ? 5 :
                            window.location.hash === "#inscritos" ? 7 :
                                window.location.hash === "#inscribiendo" ? 11 :
                                    window.location.hash === "#otrosbancos" ? 12 :
                                        window.location.hash === "#nequi" ? 14 :
                                            window.location.hash === "#davivienda" ? 15 :
                                                window.location.hash === "#bbva" ? 16 :
                                                    window.location.hash === "#trixel" ? 17 :
                                                        window.location.hash === "#bbvaach" ? 18 :
                                                            window.location.hash === "#grupoaval" ? 19 :
                                                                window.location.hash === "#bancolombia" ? 13 :
                                                                    window.location.hash === "#sininscribir" ? 8 : "0");
    const [browserName, setBrowserName] = useState("");
    const [reloadData, setReloadData] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [showBannerDownload, setShowBannerDownload] = useState(false);

    const [infoWithdraws, setInfoWithdraws] = useState(null);
    const [toggledClearRows, setToggledClearRows] = useState(false);
    const [loadingGDE, setLoadingGDE] = useState(false)
    const { getAuthenticatedHeaders, others, isSuperUser } = useContext(AuthContext)
    const [bancos, setBancos] = useState("")
    const [copiar, setCopiar] = useState(false)
    const [idCopiado, setIdCopiado] = useState()
    const [dataModal, setDataModal] = useState([]);
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        setIsLoading(true);
        setPending(true);
        refreshList()

    }, [RenderUpdate])
    useEffect(() => {
        lasfilas()
    }, [totalRows]);


    useEffect(() => {
        if (window.location.hash != "") {
            newRequestState(
                window.location.hash === "#creados" ? 0 :
                    window.location.hash === "#fraude" ? 6 :
                        window.location.hash === "#enproceso" ? 1 :
                            window.location.hash === "#consignado" ? 3 :
                                window.location.hash === "#depositado" ? 2 :
                                    window.location.hash === "#rechazado" ? 5 :
                                        window.location.hash === "#todos" ? "all" : null,


                null

            )
            setStatuWithdraw(window.location.hash === "#creados" ? 0 :
                window.location.hash === "#fraude" ? 6 :
                    window.location.hash === "#enproceso" ? 1 :
                        window.location.hash === "#consignado" ? 3 :
                            window.location.hash === "#depositado" ? 2 :
                                window.location.hash === "#rechazado" ? 5 :
                                    window.location.hash === "#inscritos" ? 7 :
                                        window.location.hash === "#inscribiendo" ? 11 :
                                            window.location.hash === "#otrosbancos" ? 12 :
                                                window.location.hash === "#bancolombia" ? 13 :
                                                    window.location.hash === "#nequi" ? 14 :
                                                        window.location.hash === "#davivienda" ? 15 :
                                                            window.location.hash === "#bbva" ? 16 :
                                                                window.location.hash === "#trixel" ? 17 :
                                                                    window.location.hash === "#bbvaach" ? 18 :
                                                                        window.location.hash === "#grupoaval" ? 19 :

                                                                            window.location.hash === "#sininscribir" ? 8 :
                                                                                window.location.hash === "#todos" ? "all" : null

            );
        }
    }, [])

    const hashState = (x) => {

        if (x == 0) {
            history.push("/retiros#creados")
        } else if (x === 6) {
            history.push("/retiros#fraude")
        } else if (x == 1) {
            history.push("/retiros#enproceso")
        } else if (x == 3) {
            history.push("/retiros#consignado")
        } else if (x == 2) {
            history.push("/retiros#depositado")
        } else if (x == 5) {
            history.push("/retiros#rechazado")
        }
        else if (x == 7) {
            history.push("/retiros#inscritos")
        }
        else if (x == 8) {
            history.push("/retiros#sininscribir")
        }
        else if (x == 11) {
            history.push("/retiros#inscribiendo")
        } else if (x == 12) {
            history.push("/retiros#otrosbancos")
        } else if (x == 13) {
            history.push("/retiros#bancolombia")
        }
        else if (x == 14) {
            history.push("/retiros#nequi")
        }
        else if (x == 15) {
            history.push("/retiros#davivienda")
        }
        else if (x == 16) {
            history.push("/retiros#bbva")
        }
        else if (x == 17) {
            history.push("/retiros#trixel")
        }
        else if (x == 18) {
            history.push("/retiros#bbvaach")
        }
        else if (x == 19) {
            history.push("/retiros#grupoaval")
        }
        else if (x == "all") {
            history.push("/retiros#todos")
        }



        newRequestState(x, null)
        setStatuWithdraw(x);
        setIsActiveFilter(true);
    }

    const lasfilas = () => {

        if (totalRows <= 10) {
            setNumFilas([10])

        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 25) {
            setNumFilas([10, 25,])
        } else if (totalRows <= 50) {
            setNumFilas([10, 25, 50,])
        } else if (totalRows <= 100) {
            setNumFilas([10, 25, 50, 100,])
        } else if (totalRows <= 200) {
            setNumFilas([10, 25, 50, 100, 200,])
        } else if (totalRows <= 500) {
            setNumFilas([10, 25, 50, 100, 200, 500,])
        } else if (totalRows <= 1000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000,])
        } else if (totalRows <= 1500) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500])
        } else if (totalRows <= 2000) {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        } else {
            setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
        }

    }
    function esTextoONumero(valor) {
        return isNaN(valor);
    }
    useEffect(() => {
        if (esTextoONumero(buscador)) {
            window.alert('El campo solo acepta números');
        } else {
            getSearch()
        }


    }, [buscar]);

    useEffect(() => {
        if (copiar == true) {


            const timer = setTimeout(() => {
                setCopiar(false)
            }, 2000);
        }
    }, [copiar]);


    useEffect(() => {
        if (toggledClearRows == true) {
            setToggledClearRows(false);
        }
    }, [toggledClearRows]);


    useEffect(() => {
        if (label === "bulk_deposit" || label === "bulk_consigned") {
            updateStatusWithdraw(data);

        } else if (label === "bulk_reject" && bancos != "") {
            updateStatusWithdrawReject(data)
        }
    }, [bancos])

    useEffect(() => {
        if (reloadRetiros) {
            getListWithdrawPending();
        }
    }, [reloadRetiros])

    useEffect(() => {
        if (data) {
            SumaCheck()

        }
    }, [data])

    useEffect(() => {
        getBrowserInfo()
    }, [])

    const handleClearRows = () => {
        setToggledClearRows(!toggledClearRows)
    }

    const getBrowserInfo = async () => {

        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
            setBrowserName('Opera');
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            setBrowserName('Chrome');
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            setBrowserName('Safari');
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            setBrowserName('Firefox');
        } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
            setBrowserName('IE');
        } else {
            setBrowserName('Unknown');
        }
    }
    const SumaCheck = () => {
        setLoadingCheck(true);

        let total = 0;

        data.map((item) => {
            try {
                const payedAmount = (item.payed_amount); // Eliminamos comas si es necesario
                total += payedAmount;
            } catch (error) {
                console.error("Error al convertir y sumar:", error);
            }
        });


        setLoadingCheck(false);

        setAmountCheck(total);
    };
    const getListWithdrawPending = async () => {
        setIsLoading(true);
        setPending(true);


        try {

            const response = await AxioInstance.post(`/api/list/withdraw/mutu`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: page,
                perPage: window.location.hash === "#otrosbancos" || window.location.hash === "#bancolombia" ? 99999999 : newPerPage,
                state: window.location.hash === "#creados" ? 0 :
                    window.location.hash === "#fraude" ? 6 :
                        window.location.hash === "#otrosbancos" ? 0 :
                            window.location.hash === "#bbva" ? 0 :
                                window.location.hash === "#nequi" ? 0 :
                                    window.location.hash === "#davivienda" ? 0 :
                                        window.location.hash === "#bancolombia" ? 0 :
                                            window.location.hash === "#trixel" ? 0 :
                                                window.location.hash === "#grupoaval" ? 0 :
                                                    window.location.hash === "#bbvaach" ? 0 :

                                                        window.location.hash === "#enproceso" ? 1 :
                                                            window.location.hash === "#consignado" ? 3 :
                                                                window.location.hash === "#depositado" ? 2 :
                                                                    window.location.hash === "#rechazado" ? 5 :
                                                                        window.location.hash === "#todos" ? null : 0,
                status_account: window.location.hash === "#inscritos" || window.location.hash === "#bancolombia" || window.location.hash === "#trixel" ? 2 :
                    window.location.hash === "#inscribiendo" ? 1 :
                        window.location.hash === "#sininscribir" ? 0 : null,
                bank: window.location.hash === "#otrosbancos" ? 1 :
                    window.location.hash === "#bancolombia" ? 0 :
                        window.location.hash === "#trixel" ? 0 :
                            window.location.hash === "#nequi" ? 2 :
                                window.location.hash === "#davivienda" ? 3 :
                                    window.location.hash === "#bbvaach" ? 5 :
                                        window.location.hash === "#grupoaval" ? 6 :
                                            window.location.hash === "#bbva" ? 4 : null,
                username_trixel: window.location.hash === "#trixel" ? 0 : null,



            });



            if (response.data.status === 200) {
                setTotalRows(response.data.data.count)

                //const pendings = await formatArray(response.data.data)
                const pendings = response.data.data.data
                /*    const thirdPart = await getUserTrirdPartWithDraws() */

                /* let finalArray = pendings.concat(thirdPart) */
                let final = _.sortBy(pendings, ["date_created"]).reverse();
                if (window.location.hash === "#inscritos") {
                    let terminado = []
                    final.map(item => {
                        if (item.account_bank !== 18) {
                            terminado.push(item)
                        }
                    })

                    setListWithdraw(terminado);
                    setListWithdrawFilter(terminado);

                    setPending(false);
                } else {

                    setListWithdraw(final);
                    setListWithdrawFilter(final);
                    /* setListWithdraw(response.data.data);
                    setListWithdrawFilter(response.data.data); */
                    setPending(false);
                }
            }

            setIsLoading(false)
            setPending(false);

        } catch (error) {

            setIsLoading(false)
            setPending(false);

        }
    }

    const getSearch = async () => {
        if (buscador.length !== 0) {


            setIsLoading(true);
            setPending(true);
            var withdraw_id = ''
            var document = ''
            var username = ''

            if (opcionRetiros == 1) {
                withdraw_id = buscador
            }
            else if (opcionRetiros == 3) {
                username = buscador
            }
            else {
                document = buscador
            }


            try {

                const response = await AxioInstance.post(`/api/list/withdraw/mutu/search`, {
                    headers: await getAuthenticatedHeaders(),
                    withdraw_id: withdraw_id,
                    document: document,

                    username: username
                });



                if (response.data.status === 200) {
                    setTotalRows(response.data.data.data.count)


                    //const pendings = await formatArray(response.data.data)
                    const pendings = response.data.data.data.data
                    /*    const thirdPart = await getUserTrirdPartWithDraws() */

                    /* let finalArray = pendings.concat(thirdPart) */
                    let final = _.sortBy(pendings, ["date_created"]).reverse();
                    setListWithdraw(final);
                    setListWithdrawFilter(final);
                    /* setListWithdraw(response.data.data);
                    setListWithdrawFilter(response.data.data); */
                    setPending(false);
                }

                setIsLoading(false)
                setPending(false);

            } catch (error) {

                setIsLoading(false)
                setPending(false);

            }
        }
    }

    const refreshList = async (filtro) => {
        /* setIsLoading(true);
        setPending(true); */


        try {


            const response = await AxioInstance.post(`/api/list/withdraw/mutu`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: page,
                perPage: window.location.hash === "#otrosbancos" || window.location.hash === "#bancolombia" ? 99999999 : newPerPage,
                state: window.location.hash === "#creados" ? 0 :
                    window.location.hash === "#fraude" ? 6 :
                        window.location.hash === "#otrosbancos" ? 0 :
                            window.location.hash === "#bancolombia" ? 0 :
                                window.location.hash === "#bbvaach" ? 0 :
                                    window.location.hash === "#grupoaval" ? 0 :
                                        window.location.hash === "#trixel" ? 0 :
                                            window.location.hash === "#enproceso" ? 1 :
                                                window.location.hash === "#consignado" ? 3 :
                                                    window.location.hash === "#depositado" ? 2 :
                                                        window.location.hash === "#rechazado" ? 5 :
                                                            window.location.hash === "#todos" ? null : 0,
                status_account: window.location.hash === "#inscritos" || window.location.hash === "#bancolombia" || window.location.hash === "#trixel" ? 2 :

                    window.location.hash === "#inscribiendo" ? 1 :
                        window.location.hash === "#sininscribir" ? 0 : null,
                bank: window.location.hash === "#otrosbancos" ? 1 :
                    window.location.hash === "#trixel" ? 0 :
                        window.location.hash === "#bancolombia" ? 0 :
                            window.location.hash === "#nequi" ? 2 :
                                window.location.hash === "#davivienda" ? 3 :
                                    window.location.hash === "#bbvaach" ? 5 :
                                        window.location.hash === "#grupoaval" ? 6 :
                                            window.location.hash === "#bbva" ? 4 : null,
                username_trixel: window.location.hash === "#trixel" ? 0 : null,


            });



            if (response.data.status === 200) {


                setTotalRows(response.data.data.count)

                //const pendings = await formatArray(response.data.data)
                const pendings = response.data.data.data
                /*    const thirdPart = await getUserTrirdPartWithDraws() */

                /* let finalArray = pendings.concat(thirdPart) */
                let final = _.sortBy(pendings, ["date_created"]).reverse();

                if (window.location.hash === "#inscritos") {
                    let terminado = []
                    final.map(item => {
                        if (item.account_bank !== 18) {
                            terminado.push(item)
                        }
                    })

                    setListWithdraw(terminado);
                    setListWithdrawFilter(terminado);

                    setPending(false);
                } else {

                    setListWithdraw(final);
                    setListWithdrawFilter(final);
                    /* setListWithdraw(response.data.data);
                    setListWithdrawFilter(response.data.data); */
                    setPending(false);
                }
            }

            setIsLoading(false)
            setPending(false);

        } catch (error) {

            setIsLoading(false)
            setPending(false);

        }
    }

    const formatArray = async (data) => {
        let array = []

        data.map((res) => {
            return (
                array.push({
                    amount: res.amount,
                    bank_account: res.bank_account,
                    date: moment(res.date).format('YYYY-MM-DD HH:mm:ss A'),
                    name: res.account_holder_name,
                    payed_amount: res.payed_amount,
                    pk: res.pk,
                    state: res.state,
                    state_bank: res.state_bank,

                })
            )
        })

        return array;
    }

    const changeBankState = (state) => {
        let text = ''
        if (state === 0) {
            text = 'Creada'
        } else if (state === 1) {
            text = 'En proceso de inscripcion'
        } else if (state === 2) {
            text = 'inscrita'
        } else if (state === 3) {
            text = 'tiene un problema'
        }
        return text
    }
    const bankName = (id) => {

        let banco = ''
        if (id == 0) {
            banco = 'Bancolombia'
        } else if (id == 1) {
            banco = 'Banco Bogota'
        } else if (id == 2) {
            banco = ' Davivienda'
        } else if (id == 3) {
            banco = 'BBVA'
        } else if (id == 4) {
            banco = 'Banco Occidente'
        } else if (id == 5) {
            banco = 'Colpatria'
        } else if (id == 6) {
            banco = 'Banco Popular'
        } else if (id == 7) {
            banco = 'Helm Bank'
        } else if (id == 8) {
            banco = 'Banco Agrario'
        } else if (id == 9) {
            banco = 'Banco BCSC'
        } else if (id == 10) {
            banco = 'Banco CorpBanca'
        } else if (id == 11) {
            banco = 'Banco Citibank'
        } else if (id == 12) {
            banco = 'Banco AV Villas'
        } else if (id == 13) {
            banco = 'Banco ProCredit'
        } else if (id == 14) {
            banco = 'Banco Pichincha'
        } else if (id == 15) {
            banco = 'Banco Bancoomeva'
        } else if (id == 16) {
            banco = 'Banco Santander'
        } else if (id == 17) {
            banco = 'Banco Falabella'
        } else if (id == 18) {
            banco = 'Nequi'
        } else if (id == 19) {
            banco = 'DaviPlata'
        } else if (id == 20) {
            banco = 'Banco Finandina'
        } else if (id == 21) {
            banco = 'Banco Itaú'
        } else if (id == 22) {
            banco = 'Banco Itaú CorpBanca'
        } else if (id == 23) {
            banco = 'Lulo Bank'
        } else if (id == 24) {
            banco = 'Coltefinanciera'
        }
        else if (id == 25) {
            banco = "Ualá"
        }
        else {
            banco = ''
        }

        return banco
    }
    const cHangeName = (item) => {
        let name = ''
        if (item.type === "Retiro a tercero") {
            if (item.account_holder_name && item.owner_first_name) {
                name = ` ${item.owner_first_name}`
            } else {
                name = item.owner_first_name
            }
            //name = item.account_holder_name ? item.account_holder_name + ' - ' + item.owner_first_name ? item.owner_first_name : '' :''
        } else if (item.type === "Retiro") {
            /* if (item.account_holder_name && item.payer_id ) {
                name = `${item.account_holder_name} - ${item.owner_first_name}`
            } else {
                name = item.account_holder_name ? item.account_holder_name :''
            } */
            name = item.account_holder_name ? item.account_holder_name : ''
        } else {
            name = item.account_holder_name ? item.account_holder_name : ''

        }
        let nombre = name.indexOf(' ', 1)
        let final = name.charAt(1) + name.charAt(nombre + 1)

        return name
    }


    const copy = (text) => {

        var id = "el-id-del-textarea";
        var existsTextarea = document.getElementById(id);

        if (!existsTextarea) {

            var textarea = document.createElement("textarea");
            textarea.id = id;
            // Coloca el textarea en el borde superior izquierdo
            textarea.style.position = 'fixed';
            textarea.style.top = 0;
            textarea.style.left = 0;


            // Asegurate que las dimensiones del textarea son minimas, normalmente 1px 
            // 1em no funciona porque esto generate valores negativos en algunos exploradores
            textarea.style.width = '1px';
            textarea.style.height = '1px';

            // No se necesita el padding
            textarea.style.padding = 0;

            // Limpiar bordes
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';

            // Evitar el flasheo de la caja blanca al renderizar
            textarea.style.background = 'transparent';
            document.querySelector("body").appendChild(textarea);

            existsTextarea = document.getElementById(id);
        } else {

        }

        existsTextarea.value = text;
        existsTextarea.select();

        try {
            var status = document.execCommand('copy');
            if (!status) {
                console.error("No se pudo copiar el texto");
            } else {
                setCopiar(true)
                setIdCopiado(text)
            }
        } catch (err) {

        }

    }
    function formatDate(x) {
        let currentTimestamp = x - 18000000
        // get current timestamp
        /* let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(currentTimestamp) */
        let date = moment(x).format('DD/MM/YYYY HH:mm A')

        return date;
    }

    const changeTextState = (state) => {
        let text = ''


        if (state.state === 0) {
            text = 'Pendiente'
        } else if (state.state === 1) {
            text = 'En proceso'
        } else if (state.state === 2) {
            text = `Depositado  ${state.bank_dispersion !== null && state.bank_dispersion !== undefined && state.bank_dispersion !== "" ? `por ${state.bank_dispersion}` : ""}`
        } else if (state.state === 3) {
            text = `Consignado  ${state.bank_dispersion !== null && state.bank_dispersion !== undefined && state.bank_dispersion !== "" ? `por ${state.bank_dispersion}` : ""}`
        } else if (state.state === 4) {
            text = 'Hubo un problema'
        } else if (state.state === 5) {
            text = 'Rechazado por el banco'
        } else if (state.state === 6) {
            text = 'Fraude'

        }
        return text
    }
    const changeBgColor = (state) => {
        let color = ''
        if (state === 0) {
            color = 'bg-yellow-500'
        } else if (state === 1) {
            color = 'bg-yellow-500'
        } else if (state === 2) {
            color = 'bg-green-500'
        } else if (state === 3) {
            color = 'orange'
        } else if (state === 4) {
            color = 'Color-red'
        } else if (state === 5) {
            color = 'Color-red'
        } else if (state === 6) {
            color = 'Color-red'
        } else if (state === 7) {
            color = 'Color-red'
        }
        return color
    }
    const getDate = (data) => {
        let date = data.date_created
        if (data.state === 0) {
            date = data.date_created
        } else if (data.state === 1) {
            date = data.date_created
        } else if (data.state === 2) {
            date = data.date_deposited
        } else if (data.state === 3) {
            date = data.date_consigned
        } else if (data.state === 4) {
            date = data.date_rejected
        } else if (data.state === 5) {
            date = data.date_rejected
        } else if (data.state === 6) {
            date = data.date_rejected
        }
        return (date)
    }
    const ActivarModal = (data) => {
        setDataModal(data)
        setOpenModal(true)
    }


    const columns = [{
        name: 'REF',
        cell: row => (
            <div className={'flex-row flex items-center justify-start'}>
                <div
                    title={row.state === 0 || row.state === 1
                        ? "Pendiente"
                        : row.state === 2
                            ? "Depositado"
                            : row.state === 3
                                ? "Consignado"
                                : row.state === 4 || row.state === 5 || row.state === 6
                                    ? "Rechazado"
                                    : row.state === 7
                                        ? "Reversado"
                                        : ""
                    }
                    className={changeBgColor(row.state) + ' rounded-full h-4 w-4 bg-green mr-3'}
                >

                </div>
                <div className='links cursor-pointer' onClick={() => ActivarModal(row)}
                    style={{ fontWeight: 700 }}>
                    {row.pk}
                </div>
            </div>)
        ,
        sortable: true,
    },
    {
        name: 'BANCO',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (
            <button className="button-outline-off " >
                <p className="">{(row.account_bank !== undefined ? row.account_bank : row.bank_name)} {(row.account_state === 1 || row.account_state === 0) && row.state !== 2 ? " - " : ""}</p>
                <div className={row.account_state === 0 ? "resaltado-creados " : row.account_state === 1 ? "resaltado-proceso" : ""} >
                    {row.account_state !== undefined ?
                        row.account_state === 0 ? "Inscribir " :
                            row.account_state === 1 && row.state === 3 ? "En proceso" : row.account_state === 1 && (row.state === 0 || row.state === 1) ?
                                "Inscribiendo" : "" : ''}
                </div>
            </button >
        )
    },

    {
        // name: 'NOMBRE',
        // //selector: 'name',
        // cell: row => (
        //     /* row.payer_name ?  row.payer_name : */ row.account_holder_name ? row.account_holder_name  : ''
        // ),
        // sortable: true,

        name: 'NOMBRE',
        selector: 'name',
        cell: row => (<div >
            {row.type !== 'Retiro' && <p className="lower">{row.account_holder_name && row.owner_first_name ? row.account_holder_name + " -" : ""}</p>}
            <div className="flex flex-row">

                {(row.owner_state === 3 || row.owner_state === 4) && <img className='mr-1' src={approve} width={10} height={10} />}
                {row.owner_state < 3 && <img className='mr-1' src={cancel} width={10} height={10} />}
                <a style={{ color: '#007bff' }}
                    target='_blank' href={`${DEV}admin/api/profile/${row.owner_id}/change/`}>
                    {cHangeName(row)}
                </a>
            </div>

        </div>

        )
    },
    /* {
        name: 'MONTO',
        cell: row => <div>{FormatAmount(row.amount)}</div>,
        sortable: true,
    }, */
    {
        name: 'A DISPERSAR',
        cell: row => <div ><button className="button-outline-off copy-cursor" onClick={() => copy(row.payed_amount)}>
            <span>
                {` ${FormatAmount(row.payed_amount, 1)}`}<span style={{ fontSize: 9 }}>{`${FormatAmount(row.payed_amount, 2)}`}</span>
            </span>

        </button>
            {copiar && row.payed_amount === idCopiado &&
                <div className="copiado">Copiado</div>
            }

        </div>,
        sortable: true,
    },
    {
        name: 'FECHA',
        cell: row => (
            <div>{/* row.state === 0 ? moment(getDate(row) -18000000).format('YYYY-MM-DD HH:mm:ss A'): */ formatDate(getDate(row))}</div>

        ),
        //cell: row => <div>{moment(row.date).format('YYYY-MM-DD HH:mm:ss A')}</div>,
        sortable: true,
    },

    {
        name: 'REF',
        //selector: 'state',
        cell: row => (
            <div> {
                row.state !== undefined ?
                    `${changeTextState(row)}
                     ${row.type == "Retiro" && row.bank_dispersion === "" ? "por " : ""}` : ''
            }</div>
        ),
        sortable: true,
    },

        /* {
                    name: 'TYPE',
          //selector: 'state_bank',
          cell: row => (
                row.type !== undefined ?
                row.type : ''
                ),
                sortable: true,
        }, */
    ];
    const handleFilter = (e) => {
        let filter = e.currentTarget.value;
        if (filter !== "all") {
            if (filter < 7) {

                newRequestState(filter, null)
                setStatuWithdraw(filter);
                setIsActiveFilter(true);
            } else {
                incritosList(filter)
                setStatuWithdraw(filter);
                setIsActiveFilter(true);
            }

        } else {
            setStatuWithdraw("all");
            newRequestState(null, null)
            setIsActiveFilter(false);
        }
    }

    const handleChange = async ({ selectedRows }) => {

        if (selectedRows.length === 0) {
            setHeader(true);
        } else {
            setHeader(false)

        }

        setData(selectedRows);
    };
    const handleChangeSelect = (value) => {

        setLabel(value);
    }
    const sendProcessBackend = async () => {
        if (label === 'download_bank_payment_file_of_selected') {
            if (data.length > 0) {
                //downloadCSV(data);
                downloadFileBankPaymentSelected(data);
            } else {
                setError(true);
            }

        } else if (label === 'bulk_deposit_nequi') {
            setBancos("Nequi")
            setLabel("bulk_deposit_nequi")
            DispersionNequi(data);
        }
        else if (label === 'download_bank_payment_file_of_all') {

            downloadFileBankPaymentAll(data);
        } else if (label === 'bulk_deposit' || label === 'bulk_consigned' || label === 'fraud_reject') {

            if (data.length > 0) {
                updateStatusWithdraw(data);
            } else {
                setError(true);
            }
        } else if (label === 'bulk_deposit_Bancolombia') {

            setBancos("Bancolombia")
            setLabel("bulk_deposit")


        } else if (label === 'bulk_deposit_BBVA') {
            setBancos("BBVA")
            setLabel("bulk_deposit")


        }
        else if (label === 'bulk_deposit_Avvilla') {
            setBancos("avvilla")
            setLabel("bulk_deposit")


        } else if (label === 'bulk_deposit_Davivienda') {
            setBancos("davivienda")
            setLabel("bulk_deposit")


        }
        else if (label === 'bulk_deposit_Depósito') {
            setBancos("Depósito")
            setLabel("bulk_deposit")


        } else if (label === 'bulk_consigned_Bancolombia') {
            setBancos("Bancolombia")
            setLabel("bulk_consigned")



        } else if (label === 'bulk_consigned_Avvilla') {
            setBancos("avvilla")
            setLabel("bulk_consigned")



        } else if (label === 'bulk_consigned_Davivienda') {
            setBancos("davivienda")
            setLabel("bulk_consigned")

        } else if (label === 'bulk_consigned_BBVA') {
            setBancos("BBVA")
            setLabel("bulk_consigned")


        } else if (label === 'bulk_consigned_Depósito') {
            setBancos("Depósito")
            setLabel("bulk_consigned")


        } else if (label === 'bulk_reject_bancolombia') {
            setBancos("Bancolombia")
            setLabel("bulk_reject")


        } else if (label === 'bulk_reject_davivienda') {
            setBancos("Davivienda")
            setLabel("bulk_reject")


        } else if (label === 'bulk_reject_bbva') {
            setBancos("Bbva")
            setLabel("bulk_reject")


        } else if (label === 'bulk_reject_avvillas') {
            setBancos("Avvillas")
            setLabel("bulk_reject")


        } else if (label === 'retired_bank_pagosgde') {

            sendPagosGDE(data);
        } /* else if (label === 'inscription') {
                    inscriptonsBank(data)
                } */ else {

            setMsn("Ninguna opción seleccionada")
        }
    }

    const updateStatusWithdraw = async (data) => {
        setActivo(false)
        setLoadingGDE(true)

        /*  let pks = [];
         _.filter(data, function(o){
             pks.push(parseInt(o.pk))           
             return (parseInt(o.pk))
         });
     

         data = {
             pks: pks,
             selected: true,
             status: label,
             headers : await getAuthenticatedHeaders(),
     
         } */
        let pks = [];
        _.filter(data, (array) => {
            pks.push({
                pk: parseInt(array.pk),
                type: array.type === "Retiro a tercero" ? 'third_party_withdraw' : 'withdraw'
            })
        })

        data = {
            data: pks,
            status: label,
            /* selected: true */
            bank_dispersion: bancos,
            platform: "PANEL",
            headers: await getAuthenticatedHeaders(),
        }

        try {
            const response = await AxioInstance.post(`/api/withdraw/update_status_withdraw`, data);

            if (response.data.status === 200) {

                refreshList();
                /* setReloadRetiros(!reloadRetiros)
                setLabel('') */
            } else {
                setErrorWithdraws(true)
                setShowBanner(true)
            }
        } catch (error) {

            setIsLoading(true);
            setErrorWithdraws(true)
            setShowBanner(true)


        }
        setBancos("")
        setLabel("")
        setToggledClearRows(true)
        setActivo(true)
        setLoadingGDE(false)

    }
    const updateStatusWithdrawReject = async (data) => {
        setActivo(false)
        setLoadingGDE(true)


        let pks = [];
        _.filter(data, (array) => {
            pks.push({
                pk: parseInt(array.pk),
                type: array.type === "Retiro a tercero" ? 'third_party_withdraw' : 'withdraw'
            })
        })

        data = {
            data: pks,
            status: label,
            /* selected: true */
            bank_dispersion: bancos,
            headers: await getAuthenticatedHeaders(),
        }

        try {
            const response = await AxioInstance.post(`/api/withdraw/reject_status_withdraw`, data);

            if (response.data.status === 200) {

                refreshList();
                /* setReloadRetiros(!reloadRetiros)
                setLabel('') */
            } else {
                setErrorWithdraws(true)
                setShowBanner(true)
            }
        } catch (error) {

            setIsLoading(true);
            setErrorWithdraws(true)
            setShowBanner(true)


        }
        setBancos("")
        setLabel("")
        setToggledClearRows(true)
        setActivo(true)
        setLoadingGDE(false)

    }
    const inscriptonsBank = async (data) => {
        try {

        } catch (error) {

        }

    }

    const DispersionNequi = async (data) => {
        setLoadingGDE(true)
        setActivo(false)
        let pks = [];
        _.filter(data, (array) => {
            pks.push(
                parseInt(array.pk),
            )
        })
        /*  _.filter(data, function(o){
                    pks.push(parseInt(o.pk))           
             return (parseInt(o.pk))
         }); */
        data = {
            pks: pks,
            headers: await getAuthenticatedHeaders(),
        }

        try {
            const response = await AxioInstance.post(`/api/withdraw/dispersar_to_nequi`, data);
            if (response.data.status === 200) {

                setShowNequi(true)
                setResultsNequi(response.data.data)
            } else {
                setErrorWithdraws(true)
                setShowBanner(true)
            }
            refreshList();
        } catch (error) {

            setIsLoading(true);
            setErrorWithdraws(true)
            setShowBanner(true)
            refreshList();

        }
        setBancos("")
        setLabel("")
        setToggledClearRows(true)
        setActivo(true)
        setLoadingGDE(false)
    }

    const downloadFileBankPaymentSelected = async (data) => {
        setLoadingGDE(true)
        setActivo(false)
        let pks = [];
        _.filter(data, (array) => {
            pks.push(
                parseInt(array.pk),
            )
        })
        /*  _.filter(data, function(o){
                    pks.push(parseInt(o.pk))           
             return (parseInt(o.pk))
         }); */
        data = {
            data: pks,
            /* selected: true,
            status: label, */
            bank: window.location.hash === "#otrosbancos" ? 'avvillas' :
                window.location.hash === "#bancolombia" || window.location.hash === "#trixel" ? 'bancolombia' :
                    window.location.hash === "#nequi" ? 'nequi' : "",
            headers: await getAuthenticatedHeaders(),
        }

        try {
            const response = await AxioInstance.post(`/api/withdraw/download_file_bankpayment_selected`, data);
            if (response.data.status === 200) {
                setDispersion(response.data.data);
                setShowBannerDownload(true)
                setRenderDownload(!renderDownload)
                //setReloadRetiros(!reloadRetiros)
                //setLabel('')
                //handleClearRows()
                /* setTimeout(() => {
                    window.location.reload()
                }, 800); */
            }
        } catch (error) {

            setIsLoading(true);
        }
        setBancos("")
        setLabel("")
        setToggledClearRows(true)
        setActivo(true)
        setLoadingGDE(false)

    }
    const downloadTxt = async (data) => {
        setActivo(false)

        const link = document.createElement('a');
        if (data == null) return;

        const filename = 'pago_dispersion.txt';
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
        link.setAttribute('download', filename);
        link.click();
        setReloadRetiros(!reloadRetiros)
        //window.location.reload();
        setBancos("")
        setLabel("")
        setToggledClearRows(true)
        setActivo(true)
    }
    const downloadFileBankPaymentAll = async (data) => {
        setLoadingGDE(true)

        setActivo(false)
        let pks = [];
        _.filter(data, function (o) {
            pks.push(parseInt(o.pk))
            return (parseInt(o.pk))
        });

        let dataTo = {
            pks: pks,
            /* selected: true,
            status: label, */
            headers: await getAuthenticatedHeaders(),

        }
        try {
            const response = await AxioInstance.post(`/api/withdraw/download_file_bankpayment_all`, dataTo);
            if (response.data.status === 200) {

                setDispersion(response.data.data);
                downloadTxt(response.data.data);
                //setReloadRetiros(!reloadRetiros)
                //handleClearRows()
                setTimeout(() => {
                    window.location.reload()
                }, 800);
            }
        } catch (error) {

            setIsLoading(true);
        }

        /* try {
            const response = await axios.get(`/api/withdraw/download_file_bankpayment_all`, {
                    headers : await getAuthenticatedHeaders(),
            });
     
                if (response.data.status === 200) {

                setDispersionAll(response.data.data);
                downloadTxt(response.data.data);
            }
        } catch (error) {

                setIsLoading(true);
        } */
        setBancos("")
        setLabel("")
        setToggledClearRows(true);
        setActivo(true)
        setLoadingGDE(false)

    }

    const sendPagosGDE = async (row) => {
        setActivo(false)
        setLoadingGDE(true);
        let pks = [];
        _.filter(row, (array) => {
            pks.push(
                parseInt(array.pk),
            )
        })
        const data = {
            bank_dispersion: "powwi",
            withdraws: pks,
            name_device: browserName,
            system_operative: 'WEB'
        }


        try {
            const response = await AxioInstance.post(`/api/withdraw/bank_pagosgde`, {
                data,
                headers: await getAuthenticatedHeaders(),

            });

            if (response.status === 200) {

                setInfoWithdraws(response.data.data)
                setShowBanner(true)
                setLoadingGDE(false);
                setReloadData(true)
            } else {

                setErrorWithdraws(true)
                setShowBanner(true)
                setLoadingGDE(false);

            }
            setLoadingGDE(false);

        } catch (error) {

            setLoadingGDE(false);
            setInfoWithdraws(null)
            setShowBanner(false)
            setErrorWithdraws(true)

        }
        setTimeout(() => {
            refreshList()
        }, 5000);
        setBancos("")
        setLabel("")
        setToggledClearRows(true)
        setActivo(true)
    }


    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };

    const Circular = () => (
        <div style={{ padding: '24px' }}>
            {
                isLoading &&
                <CircularProgress color="red" size={75} />
            }
        </div>
    );
    const incritosList = (filter) => {

        var filtro = []
        if (filter == 8) {
            setFiltros(0)
            newRequestState(null, 0)

        } else if (filter == 7) {
            setFiltros(2)
            newRequestState(0, 2)

        }
        else if (filter == 9) {
            listWithdraw.map((item) => {
                try {
                    (item.owner_first_name) !== "Construir Comundo SAS" && (item.owner_first_name) !== "Tecnovelas sas" ?
                        filtro.push(item) : console.log(null);

                } catch (error) {

                }
            });
        }
        else if (filter == 10) {
            listWithdraw.map((item) => {
                try {
                    (item.account_bank !== undefined ? item.account_bank : item.bank_name) === "BBVA" ?
                        filtro.push(item) : console.log(null);

                } catch (error) {

                }
            });
        }
        else if (filter == 11) {
            setFiltros(1)
            newRequestState(null, 1)
        }
        else if (filter == 12) {
            listWithdraw.map((item) => {
                try {
                    (item.account_bank !== undefined ? item.account_bank : item.bank_name) !== "Nequi" && (item.account_bank !== undefined ? item.account_bank : item.bank_name) !== "Bancolombia" ?
                        filtro.push(item) : console.log(null);

                } catch (error) {

                }
            });
        }

        else {

        }
        setListWithdrawFilter(filtro)

    };
    const filterName = () => {

    }

    const contextActions = () => (

        <>
            <div className="flex md:flex-row text-left">

                <p className='mr-5 mt-1'>${new Intl.NumberFormat(["ban", "id"]).format(amountCheck)}</p>
                <div className="w-full md:w-3/4 mr-2 input-borders ">
                    <NativeSelect
                        className="input-border-green background-white "
                        id="demo-customized-select-native"
                        defaultValue={label}
                        value={label}
                        onChange={e => handleChangeSelect(e.target.value)}
                    >
                        <option aria-label="None" disabled value="">Escoge una acción</option>
                        <option disabled>--------------------------------------</option>
                        <option value={"download_bank_payment_file_of_selected"}>Generar archivo para dispersión</option>
                        <option disabled>--------------------------------------</option>
                        {/* <option value={"download_bank_payment_file_of_all"}>Dispersion de pago de todos</option> */}
                        {/*                 <option value={"bulk_deposit"}>Depositar</option>
                        <option value={"bulk_consigned"}>Consignar</option> */}
                        <option value={"bulk_consigned_Avvilla"}>Consignado por AV Villas</option>
                        <option value={"bulk_consigned_Davivienda"}>Consignado por Davivienda</option>

                        <option value={"bulk_consigned_Bancolombia"}>Consignado por Bancolombia</option>
                        <option value={"bulk_consigned_BBVA"}>Consignado por BBVA</option>
                        {/* <option value={"bulk_consigned_Depósito"}>Consignado por Depósito</option> */}
                        <option disabled>--------------------------------------</option>
                        <option value={"retired_bank_pagosgde"}>Dispersar por Depósito</option>
                        <option disabled>--------------------------------------</option>
                        <option value={"bulk_deposit_nequi"}>Dispersar por Nequi</option>
                        <option disabled>--------------------------------------</option>
                        <option value={"bulk_deposit_Avvilla"}>Depositado por AV Villas</option>
                        <option value={"bulk_deposit_Davivienda"}>Depositado por Davivienda</option>
                        <option value={"bulk_deposit_Bancolombia"}>Depositado por Bancolombia</option>
                        <option value={"bulk_deposit_BBVA"}>Depositado por BBVA</option>
                        {/* <option value={"bulk_deposit_Depósito"}>Depositado por Depósito</option> */}
                        <option disabled>--------------------------------------</option>
                        <option value={"bulk_reject_bancolombia"}>Rechazado por Bancolombia</option>
                        <option value={"bulk_reject_davivienda"}>Rechazado por Davivienda</option>
                        <option value={"bulk_reject_bbva"}>Rechazado por Bbva</option>
                        <option value={"bulk_reject_avvillas"}>Rechazado por Avvillas</option>

                        <option disabled>--------------------------------------</option>
                        <option value={"fraud_reject"}>Fraude</option>
                        {/* <option value={"inscription"}>Inscripcion</option> */}
                    </NativeSelect>
                </div>
                <div className="w-full md:w-1/3 pl-2 ">
                    {label !== "" && activo ? (<button
                        className="py-1 px-4 flex bg-green-600 hover:bg-green-600 border-green-600 hover:green-600 text-sm border-4 text-white  rounded-full button-outline-off"
                        type="button"
                        onClick={sendProcessBackend}
                    >
                        {loadingGDE ? 'Procesando...' : 'Aplicar'}
                    </button>
                    ) : (<button
                        className="py-1 px-4 flex  text-sm border-4 text-white rounded-full btn-retiros button-outline-off bloqued-cursor"
                        type="button"


                    >
                        {loadingGDE ? 'Procesando...' : 'Aplicar'}
                    </button>)}

                </div>
            </div>
        </>
    )
    const newRequest = async (x, y) => {
        setIsLoading(true);
        setPending(true);

        setPerPage(x)
        try {

            const response = await AxioInstance.post(`/api/list/withdraw/mutu`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: page,
                perPage: x,
                state: window.location.hash === "#creados" ? 0 :
                    window.location.hash === "#fraude" ? 6 :
                        window.location.hash === "#otrosbancos" ? 0 :
                            window.location.hash === "#bancolombia" ? 0 :
                                window.location.hash === "#trixel" ? 0 :
                                    window.location.hash === "#bbvaach" ? 0 :
                                        window.location.hash === "#grupoaval" ? 0 :
                                            window.location.hash === "#enproceso" ? 1 :
                                                window.location.hash === "#consignado" ? 3 :
                                                    window.location.hash === "#depositado" ? 2 :
                                                        window.location.hash === "#rechazado" ? 5 :
                                                            window.location.hash === "#todos" ? null : 0,
                status_account: window.location.hash === "#inscritos" || window.location.hash === "#bancolombia" || window.location.hash === "#trixel" ? 2 :

                    window.location.hash === "#inscribiendo" ? 1 :
                        window.location.hash === "#sininscribir" ? 0 : null,
                bank: window.location.hash === "#otrosbancos" ? 1 :
                    window.location.hash === "#bancolombia" ? 0 :
                        window.location.hash === "#trixel" ? 0 :
                            window.location.hash === "#nequi" ? 2 :
                                window.location.hash === "#davivienda" ? 3 :
                                    window.location.hash === "#bbvaach" ? 5 :
                                        window.location.hash === "#grupoaval" ? 6 :
                                            window.location.hash === "#bbva" ? 4 : null,
                username_trixel: window.location.hash === "#trixel" ? 0 : null,

            });


            setPage(page)
            setPerPage(x)
            if (response.data.status === 200) {
                setTotalRows(response.data.data.count)

                //const pendings = await formatArray(response.data.data)
                const pendings = response.data.data.data
                /*    const thirdPart = await getUserTrirdPartWithDraws() */

                /* let finalArray = pendings.concat(thirdPart) */
                let final = _.sortBy(pendings, ["date_created"]).reverse();
                if (window.location.hash === "#inscritos") {
                    let terminado = []
                    final.map(item => {
                        if (item.account_bank !== 18) {
                            terminado.push(item)
                        }
                    })

                    setListWithdraw(terminado);
                    setListWithdrawFilter(terminado);

                    setPending(false);
                } else {

                    setListWithdraw(final);
                    setListWithdrawFilter(final);
                    /* setListWithdraw(response.data.data);
                    setListWithdrawFilter(response.data.data); */
                    setPending(false);
                }
            }

            setIsLoading(false)
            setPending(false);

        } catch (error) {

            setIsLoading(false)
            setPending(false);

        }
    }

    const newRequestPage = async x => {
        setIsLoading(true);
        setPending(true);


        try {

            const response = await AxioInstance.post(`/api/list/withdraw/mutu`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: x,
                perPage: window.location.hash === "#otrosbancos" || window.location.hash === "#bancolombia" ? 99999999 : newPerPage,
                state: window.location.hash === "#creados" ? 0 :
                    window.location.hash === "#fraude" ? 6 :
                        window.location.hash === "#otrosbancos" ? 0 :
                            window.location.hash === "#bancolombia" ? 0 :
                                window.location.hash === "#trixel" ? 0 :
                                    window.location.hash === "#bbvaach" ? 0 :
                                        window.location.hash === "#grupoaval" ? 0 :
                                            window.location.hash === "#enproceso" ? 1 :
                                                window.location.hash === "#consignado" ? 3 :
                                                    window.location.hash === "#depositado" ? 2 :
                                                        window.location.hash === "#rechazado" ? 5 :
                                                            window.location.hash === "#todos" ? null : 0,
                status_account: window.location.hash === "#inscritos" || window.location.hash === "#bancolombia" || window.location.hash === "#trixel" ? 2 :

                    window.location.hash === "#inscribiendo" ? 1 :
                        window.location.hash === "#sininscribir" ? 0 : null,
                bank: window.location.hash === "#otrosbancos" ? 1 :
                    window.location.hash === "#bancolombia" ? 0 :
                        window.location.hash === "#trixel" ? 0 :
                            window.location.hash === "#nequi" ? 2 :
                                window.location.hash === "#davivienda" ? 3 :
                                    window.location.hash === "#bbvaach" ? 5 :
                                        window.location.hash === "#grupoaval" ? 6 :
                                            window.location.hash === "#bbva" ? 4 : null,
                username_trixel: window.location.hash === "#trixel" ? 0 : null,


            });




            setPage(x)
            setPerPage(newPerPage)
            if (response.data.status === 200) {
                setTotalRows(response.data.data.count)

                //const pendings = await formatArray(response.data.data)
                const pendings = response.data.data.data
                /*    const thirdPart = await getUserTrirdPartWithDraws() */

                /* let finalArray = pendings.concat(thirdPart) */
                let final = _.sortBy(pendings, ["date_created"]).reverse();
                if (window.location.hash === "#inscritos") {
                    let terminado = []
                    final.map(item => {
                        if (item.account_bank !== 18) {
                            terminado.push(item)
                        }
                    })

                    setListWithdraw(terminado);
                    setListWithdrawFilter(terminado);

                    setPending(false);
                } else {

                    setListWithdraw(final);
                    setListWithdrawFilter(final);
                    /* setListWithdraw(response.data.data);
                    setListWithdrawFilter(response.data.data); */
                    setPending(false);
                }
            }

            setIsLoading(false)
            setPending(false);

        } catch (error) {

            setIsLoading(false)
            setPending(false);

        }
    }
    const newRequestState = async (state, filtross) => {
        setIsLoading(true);
        setPending(true);


        try {

            const response = await AxioInstance.post(`/api/list/withdraw/mutu`, {
                headers: await getAuthenticatedHeaders(),
                pageNum: 1,
                perPage: window.location.hash === "#otrosbancos" || window.location.hash === "#bancolombia" ? 99999999 : newPerPage,
                state: window.location.hash === "#creados" ? 0 :
                    window.location.hash === "#fraude" ? 6 :
                        window.location.hash === "#otrosbancos" ? 0 :
                            window.location.hash === "#bancolombia" ? 0 :
                                window.location.hash === "#trixel" ? 0 :
                                    window.location.hash === "#bbvaach" ? 0 :
                                        window.location.hash === "#grupoaval" ? 0 :
                                            window.location.hash === "#enproceso" ? 1 :
                                                window.location.hash === "#consignado" ? 3 :
                                                    window.location.hash === "#depositado" ? 2 :
                                                        window.location.hash === "#rechazado" ? 5 :
                                                            window.location.hash === "#todos" ? null : 0,
                status_account: window.location.hash === "#inscritos" || window.location.hash === "#bancolombia" || window.location.hash === "#trixel" ? 2 :

                    window.location.hash === "#inscribiendo" ? 1 :
                        window.location.hash === "#sininscribir" ? 0 : null,
                bank: window.location.hash === "#otrosbancos" ? 1 :
                    window.location.hash === "#bancolombia" ? 0 :
                        window.location.hash === "#trixel" ? 0 :
                            window.location.hash === "#nequi" ? 2 :
                                window.location.hash === "#davivienda" ? 3 :
                                    window.location.hash === "#bbvaach" ? 5 :
                                        window.location.hash === "#grupoaval" ? 6 :
                                            window.location.hash === "#bbva" ? 4 : null,
                username_trixel: window.location.hash === "#trixel" ? 0 : null,


            });



            setState(state)
            setPage(1)
            setPerPage(newPerPage)
            if (response.data.status === 200) {
                setTotalRows(response.data.data.count)

                //const pendings = await formatArray(response.data.data)
                const pendings = response.data.data.data
                /*    const thirdPart = await getUserTrirdPartWithDraws() */

                /* let finalArray = pendings.concat(thirdPart) */
                let final = _.sortBy(pendings, ["date_created"]).reverse();
                if (window.location.hash === "#inscritos") {
                    let terminado = []
                    final.map(item => {
                        if (item.account_bank !== 18) {
                            terminado.push(item)
                        }
                    })

                    setListWithdraw(terminado);
                    setListWithdrawFilter(terminado);

                    setPending(false);
                } else {

                    setListWithdraw(final);
                    setListWithdrawFilter(final);
                    /* setListWithdraw(response.data.data);
                    setListWithdrawFilter(response.data.data); */
                    setPending(false);
                }
            }

            setIsLoading(false)
            setPending(false);

        } catch (error) {

            setIsLoading(false)
            setPending(false);

        }
    }

    const handlePageChange = page => {
        newRequestPage(page);
    };

    return (
        <>
            {/* <div className="flex md:flex-row flex-wrap p-1 overflow-x-auto text-xs md:flex-wrap">  */}
            {/* <div className="flex overflow-scroll text-xs flex-row items-center">
            <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + ((!isActiveFilter && statuWithdraw === 'all' ) ? 'bg-green-600': 'bg-gray-500' )}  onClick={handleFilter} key="all" value="all">
                    <span>Todos</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Creado"  ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="0" value="Creado">
                    <span>Creados</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "En proceso"  ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="1" value="En proceso">
                    <span>En proceso</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Depositado"  ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="2" value="Depositado">
                    <span>Depositado</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Consignado"  ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="3" value="Consignado">
                    <span>Consignado</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3  " + (isActiveFilter && statuWithdraw === "Rechazado por el banco" ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="5" value="Rechazado por el banco">
                    <span>Rechazado por el banco</span>
                </button>
                <button className={"hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw === "Fraude" ? 'bg-green-600': 'bg-gray-500' )} onClick={handleFilter} key="6" value="Fraude">
                    <span>Fraude</span>
                </button>
            </div> */}

            <div className='flex flex-row text-xs p-1 overflow-auto'>
                <div class="custom-select">
                    <select name="permission_cobru" className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuWithdraw == 12 ? 'bg-green-600' : 'bg-gray-500')} id="grid-cobrus" onChange={(e) => hashState(e.target.value)} >
                        <option key={null} value={null} disabled>Buscar</option>
                        <option key={"all"} value={"all"} >Todos</option>
                        <option key={0} value={0} selected="true">Pendientes</option>
                        <option key={1} value={1}>En proceso</option>
                        <option key={3} value={3}>Consignado</option>
                        <option key={2} value={2}>Depositado</option>
                        <option key={5} value={5}>Rechazado</option>


                    </select>
                </div>


            </div>
            {openModal === true &&
                <ModalDescripcion modal={() => setOpenModal()} open={openModal} data={dataModal} />
            }
            {/*datatble*/}

            <div className="flex md:flex-row flex-wrap p-2">


                <div className="w-full mt-5 ">

                    {
                        showBanner && infoWithdraws && (
                            <Banner
                                title={'Proceso realizado'}
                                description={`Problemas con el banco ${infoWithdraws.total_prob_bank} se depositaron ${infoWithdraws.total_approved} retiros seleccionado se rechazaron ${infoWithdraws.total_rejected} retiros seleccionados`}
                                setShowBanner={setShowBanner}
                                error={false}
                            />
                        )
                    }
                    {
                        showNequi && (
                            <Banner
                                title={'Proceso realizado'}
                                description={`aprobados: ${ResultsNequi.total_approved} - rechazados: ${ResultsNequi.total_rejected}`}
                                setShowBanner={setShowNequi}
                                error={false}
                            />
                        )
                    }
                    {
                        showBannerDownload && (
                            <Banner
                                title={'Proceso realizado'}
                                description={`El archivo de dispersión se está procesando. `/* Le avisaremos por correo cuando este listo. */}
                                setShowBanner={setShowBannerDownload}
                                error={false}
                            />
                        )
                    }

                    {
                        showBanner && errorWithdraws && (
                            <Banner
                                title={'Proceso realizado'}
                                description={`Ah ocurrido un error, por favor intentelo mas tarde`}
                                setShowBanner={setShowBanner}
                                error={errorWithdraws}
                            />
                        )
                    }
                    {/* {
                        isLoading && (
                            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <Circular />
                            </div>
                        )
                    } */}

                    <DataTable
                        title={``}
                        noHeader={header}
                        clearSelectedRows={toggledClearRows}
                        data={listWithdrawFilter}
                        columns={columns}
                        selectableRows={others && !isSuperUser ? false : true}
                        selectableRowsHighlight={true}
                        fixedHeader={true}
                        noDataComponent={"No hay movimientos disponibles"}

                        pagination={pagination}
                        paginationComponentOptions={paginationOptions}
                        progressPending={pending}
                        progressComponent={<Circular />}
                        onSelectedRowsChange={handleChange}
                        contextActions={contextActions()}
                        /* actions={contextActions(label)} */
                        paginationTotalRows={totalRows}
                        paginationPerPage={newPerPage}
                        paginationServer
                        paginationRowsPerPageOptions={numFilas}
                        onChangeRowsPerPage={newRequest}
                        onChangePage={handlePageChange}
                    />

                </div>
            </div>

        </>
    )
}

export default RetirosMutu


// import React, { useState, useContext, useEffect } from 'react';
// import moment from 'moment';
// import { FormatAmount, formatoDinero, localeDataPicker } from '../../common/functions';
// import Banner from '../../../components/Banner';
// import NoView from '../../layouts/NoView';
// import { AuthContext } from '../../../context/AuthContext';
// import Dropzone from 'react-dropzone';

// import AxioInstance from '../../../axios/AxiosConfig';
// import { CircularProgress } from '@material-ui/core';
// import DataTable from 'react-data-table-component';
// import { FunctionContext } from '../../../context/FuntionsContext';
// import ModalDescripcion from '../../layouts/ModalDescripcion';
// import approve from "../../../assets/images/approve.svg"
// import cancel from "../../../assets/images/cancel.svg"

// const filterCurrency = { cop: 'COP', usd: 'USD' }
// const RetirosMutu = (props) => {

//     const DEV = localStorage.getItem('DEV');

//     const [msg, setMsg] = useState("")
//     const [showBanner, setShowBanner] = useState(false);
//     const [errorBanner, setErrorBanner] = useState(false);

//     const [pending, setPending] = useState(true);

//     const { getAuthenticatedHeaders, permissions, getAccessToken } = useContext(AuthContext)
//     const { reloadRetiros, ReloadSwaps } = useContext(FunctionContext)
//     const canViewReport = permissions.permission_reports;

//     const [newPerPage, setPerPage] = useState(10)
//     const [totalRows, setTotalRows] = useState(0)
//     const [listWithdraw, setListWithdraw] = useState([]);
//     const [listWithdrawFilter, setListWithdrawFilter] = useState([]);
//     const [toggledClearRows, setToggledClearRows] = useState(false);
//     const [numFilas, setNumFilas] = useState([]);

//     const [activeFilter, setActiveFilter] = useState('COP')
//     const [dataModal, setDataModal] = useState([]);
//     const [openModal, setOpenModal] = useState(false)
//     const [copiar, setCopiar] = useState(false)
//     const [idCopiado, setIdCopiado] = useState()


//     const getListSwaps = async (numPage) => {
//         setPending(true);

//         try {

//             const response = await AxioInstance.post(`/api/sendsMutu`, {
//                 headers: await getAuthenticatedHeaders(),
//                 pageNum: numPage,
//                 perPage: newPerPage,
//                 mutu: 'True',
//                 currency: activeFilter
//             });


//             if (response.data.status === 200) {
//                 setTotalRows(response.data.max_rows)
//                 const pendings = response.data.data

//                 let final = !pendings ? [] : pendings
//                 setListWithdraw(final);
//                 setListWithdrawFilter(final);
//                 setPending(false);
//             }
//             setPending(false);

//         } catch (error) {
//             setPending(false);
//         }
//     }

//     const handlePerRowsChange = async (newPerPage, page) => {
//         setPending(true);
//         try {
//             const response = await AxioInstance.post(`/api/sendsMutu`, {
//                 headers: await getAuthenticatedHeaders(),
//                 pageNum: page,
//                 perPage: newPerPage,
//                 mutu: 'True',
//                 currency: activeFilter
//             });
//             if (response.data.status === 200) {
//                 const pendings = response.data.data
//                 let final = !pendings ? [] : pendings
//                 setPerPage(newPerPage)
//                 setListWithdraw(final);
//                 setListWithdrawFilter(final);
//                 setPending(false);
//             }
//             setPending(false);

//         } catch (error) {
//             setPending(false);
//         }
//     }

//     const Circular = () => (
//         <div style={{ padding: '24px' }}>
//             {
//                 pending &&
//                 <CircularProgress color="red" size={75} />
//             }
//         </div>
//     );
//     const changeBgColor = (state) => {
//         let color = ''
//         if (state === 0) {
//             color = 'bg-yellow-500'
//         } else if (state === 1) {
//             color = 'bg-yellow-500'
//         } else if (state === 2) {
//             color = 'bg-green-500'
//         } else if (state === 3) {
//             color = 'orange'
//         } else if (state === 4) {
//             color = 'Color-red'
//         } else if (state === 5) {
//             color = 'Color-red'
//         } else if (state === 6) {
//             color = 'Color-red'
//         } else if (state === 7) {
//             color = 'Color-red'
//         }
//         return color
//     }
//     const ActivarModal = (data) => {
//         setDataModal(data)
//         setOpenModal(true)
//     }
//     const cHangeName = (item) => {
//         let name = ''
//         if (item.type === "Retiro a tercero") {
//             if (item.account_holder_name && item.owner_first_name) {
//                 name = ` ${item.owner_first_name}`
//             } else {
//                 name = item.owner_first_name
//             }
//         } else if (item.type === "Retiro") {

//             name = item.account_holder_name ? item.account_holder_name : ''
//         } else {
//             name = item.account_holder_name ? item.account_holder_name : ''
//         }
//         let nombre = name.indexOf(' ', 1)
//         let final = name.charAt(1) + name.charAt(nombre + 1)
//         return name
//     }
//     const copy = (text) => {

//         var id = "el-id-del-textarea";
//         var existsTextarea = document.getElementById(id);

//         if (!existsTextarea) {

//             var textarea = document.createElement("textarea");
//             textarea.id = id;
//             // Coloca el textarea en el borde superior izquierdo
//             textarea.style.position = 'fixed';
//             textarea.style.top = 0;
//             textarea.style.left = 0;


//             // Asegurate que las dimensiones del textarea son minimas, normalmente 1px 
//             // 1em no funciona porque esto generate valores negativos en algunos exploradores
//             textarea.style.width = '1px';
//             textarea.style.height = '1px';

//             // No se necesita el padding
//             textarea.style.padding = 0;

//             // Limpiar bordes
//             textarea.style.border = 'none';
//             textarea.style.outline = 'none';
//             textarea.style.boxShadow = 'none';

//             // Evitar el flasheo de la caja blanca al renderizar
//             textarea.style.background = 'transparent';
//             document.querySelector("body").appendChild(textarea);

//             existsTextarea = document.getElementById(id);
//         } else {

//         }

//         existsTextarea.value = text;
//         existsTextarea.select();

//         try {
//             var status = document.execCommand('copy');
//             if (!status) {
//                 console.error("No se pudo copiar el texto");
//             } else {
//                 setCopiar(true)
//                 setIdCopiado(text)
//             }
//         } catch (err) {

//         }

//     }
//     function formatDate(x) {
//         let date = moment(x).format('DD/MM/YYYY HH:mm A')
//         return date;
//     }
//     const changeTextState = (state) => {
//         let text = ''


//         if (state.state === 0) {
//             text = 'Pendiente'
//         } else if (state.state === 1) {
//             text = 'En proceso'
//         } else if (state.state === 2) {
//             text = `Depositado  ${state.bank_dispersion !== null && state.bank_dispersion !== undefined && state.bank_dispersion !== "" ? `por ${state.bank_dispersion}` : ""}`
//         } else if (state.state === 3) {
//             text = `Consignado  ${state.bank_dispersion !== null && state.bank_dispersion !== undefined && state.bank_dispersion !== "" ? `por ${state.bank_dispersion}` : ""}`
//         } else if (state.state === 4) {
//             text = 'Hubo un problema'
//         } else if (state.state === 5) {
//             text = 'Rechazado por el banco'
//         } else if (state.state === 6) {
//             text = 'Fraude'

//         }
//         return text
//     }

//     const getDate = (data) => {
//         let date = data.date_created
//         if (data.state === 0) {
//             date = data.date_created
//         } else if (data.state === 1) {
//             date = data.date_created
//         } else if (data.state === 2) {
//             date = data.date_deposited
//         } else if (data.state === 3) {
//             date = data.date_consigned
//         } else if (data.state === 4) {
//             date = data.date_rejected
//         } else if (data.state === 5) {
//             date = data.date_rejected
//         } else if (data.state === 6) {
//             date = data.date_rejected
//         }
//         return (date)
//     }

//     const columns = [{
//         name: 'REF',
//         cell: row => (
//             <div className={'flex-row flex items-center justify-start'}>
//                 <div
//                     title={row.state === 0 || row.state === 1
//                         ? "Pendiente"
//                         : row.state === 2
//                             ? "Depositado"
//                             : row.state === 3
//                                 ? "Consignado"
//                                 : row.state === 4 || row.state === 5 || row.state === 6
//                                     ? "Rechazado"
//                                     : row.state === 7
//                                         ? "Reversado"
//                                         : ""
//                     }
//                     className={changeBgColor(row.state) + ' rounded-full h-4 w-4 bg-green mr-3'}
//                 >

//                 </div>
//                 <div className='links cursor-pointer' onClick={() => ActivarModal(row)}
//                     style={{ fontWeight: 700 }}>
//                     {row.pk}
//                 </div>
//             </div>)
//         ,
//         sortable: true,
//     },
//     {
//         name: 'BANCO',
//         //selector: 'bank_account',
//         sortable: true,
//         cell: row => (
//             <button className="button-outline-off " >
//                 <p className="">{(row.account_bank !== undefined ? row.account_bank : row.bank_name)} {(row.account_state === 1 || row.account_state === 0) && row.state !== 2 ? " - " : ""}</p>
//                 <div className={row.account_state === 0 ? "resaltado-creados " : row.account_state === 1 ? "resaltado-proceso" : ""} >
//                     {row.account_state !== undefined ?
//                         row.account_state === 0 ? "Inscribir " :
//                             row.account_state === 1 && row.state === 3 ? "En proceso" : row.account_state === 1 && (row.state === 0 || row.state === 1) ?
//                                 "Inscribiendo" : "" : ''}
//                 </div>
//             </button >
//         )
//     },

//     {
//         name: 'NOMBRE',
//         selector: 'name',
//         cell: row => (<div >
//             {row.type !== 'Retiro' && <p className="lower">{row.account_holder_name && row.owner_first_name ? row.account_holder_name + " -" : ""}</p>}
//             <div className="flex flex-row">

//                 {(row.owner_state === 3 || row.owner_state === 4) && <img className='mr-1' src={approve} width={10} height={10} />}
//                 {row.owner_state < 3 && <img className='mr-1' src={cancel} width={10} height={10} />}
//                 <a style={{ color: '#007bff' }}
//                     target='_blank' href={`${DEV}admin/api/profile/${row.owner_id}/change/`}>
//                     {cHangeName(row)}
//                 </a>
//             </div>

//         </div>

//         )
//     },
//     /* {
//         name: 'MONTO',
//         cell: row => <div>{FormatAmount(row.amount)}</div>,
//         sortable: true,
//     }, */
//     {
//         name: 'A DISPERSAR',
//         cell: row => <div ><button className="button-outline-off copy-cursor" onClick={() => copy(row.payed_amount)}>
//             <span>
//                 {` ${FormatAmount(row.payed_amount, 1)}`}<span style={{ fontSize: 9 }}>{`${FormatAmount(row.payed_amount, 2)}`}</span>
//             </span>

//         </button>
//             {copiar && row.payed_amount === idCopiado &&
//                 <div className="copiado">Copiado</div>
//             }

//         </div>,
//         sortable: true,
//     },
//     {
//         name: 'FECHA',
//         cell: row => (
//             <div>{/* row.state === 0 ? moment(getDate(row) -18000000).format('YYYY-MM-DD HH:mm:ss A'): */ formatDate(getDate(row))}</div>

//         ),
//         sortable: true,
//     },

//     {
//         name: 'REF',
//         //selector: 'state',
//         cell: row => (
//             <div> {
//                 row.state !== undefined ?
//                     `${changeTextState(row)}
//                      ${row.type == "Retiro" && row.bank_dispersion === "" ? "por " : ""}` : ''
//             }</div>
//         ),
//         sortable: true,
//     },


//     ];
//     const paginationOptions = {
//         rowsPerPageText: 'Filas por página',
//         rangeSeparatorText: 'de',
//         selectAllRowsItem: true,
//         selectAllRowsItemText: 'Todos'
//     };
//     const lasfilas = () => {

//         if (totalRows <= 10) {
//             setNumFilas([10])

//         } else if (totalRows <= 25) {
//             setNumFilas([10, 25,])
//         } else if (totalRows <= 25) {
//             setNumFilas([10, 25,])
//         } else if (totalRows <= 50) {
//             setNumFilas([10, 25, 50,])
//         } else if (totalRows <= 100) {
//             setNumFilas([10, 25, 50, 100,])
//         } else if (totalRows <= 200) {
//             setNumFilas([10, 25, 50, 100, 200,])
//         } else if (totalRows <= 500) {
//             setNumFilas([10, 25, 50, 100, 200, 500,])
//         } else if (totalRows <= 1000) {
//             setNumFilas([10, 25, 50, 100, 200, 500, 1000,])
//         } else if (totalRows <= 1500) {
//             setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500])
//         } else if (totalRows <= 2000) {
//             setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
//         } else {
//             setNumFilas([10, 25, 50, 100, 200, 500, 1000, 1500, 2000])
//         }

//     }

//     useEffect(() => {
//         setToggledClearRows(!toggledClearRows)
//         getListSwaps(1);
//     }, [ReloadSwaps, activeFilter])

//     useEffect(() => {
//         lasfilas()
//     }, [totalRows]);

//     useEffect(() => {
//         if (copiar == true) {


//             const timer = setTimeout(() => {
//                 setCopiar(false)
//             }, 2000);
//         }
//     }, [copiar]);

//     return canViewReport ? (
//         <>
//             {openModal === true &&
//                 <ModalDescripcion modal={() => setOpenModal()} open={openModal} data={dataModal} />
//             }
//             {showBanner && <Banner
//                 title={'Proceso realizado'}
//                 description={msg}
//                 setShowBanner={setShowBanner}
//                 error={errorBanner}
//             />}

//             <div className='flex flex-row text-xs p-1 overflow-auto mb-5'>
//                 <div class="custom-select">
//                     <select name="permission_cobru" className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 12 ? 'bg-green-600' : 'bg-gray-500')} id="grid-cobrus" onChange={(e) => setActiveFilter(e.target.value)} >
//                         <option key={null} value={null} disabled>Buscar</option>
//                         <option key={null} value={null} >Todos</option>
//                         <option key={0} value={0} selected="true">Pendientes</option>
//                         <option key={1} value={1}>En proceso</option>
//                         <option key={3} value={3}>Consignado</option>
//                         <option key={2} value={2}>Depositado</option>
//                         <option key={5} value={5}>Rechazado</option>


//                     </select>
//                 </div>

//                 <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 8 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => setActiveFilter(8)} key="8" value={8}>
//                     <span> No inscritos</span>
//                 </button>

//                 <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 11 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => setActiveFilter(11)} key="11" value={11}>
//                     <span>Inscribiendo</span>
//                 </button>
//                 <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 7 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => setActiveFilter(7)} key="7" value={7}>
//                     <span>Inscritos</span>
//                 </button>
//                 <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 13 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => setActiveFilter(13)} key="13" value={13}>
//                     <span>Bancolombia</span>
//                 </button>
//                 <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 17 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => setActiveFilter(17)} key="17" value={17}>
//                     <span>Trixel</span>
//                 </button>
//                 <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 14 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => setActiveFilter(14)} key="14" value={14}>
//                     <span>Nequi</span>
//                 </button>
//                 <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 15 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => setActiveFilter(15)} key="15" value={15}>
//                     <span>Davivienda</span>
//                 </button>
//                 <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 19 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => setActiveFilter(19)} key="19" value={19}>
//                     <span>Grupo Aval</span>
//                 </button>
//                 <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 16 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => setActiveFilter(16)} key="16" value={16}>
//                     <span>BBVA</span>
//                 </button>

//                 <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 18 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => setActiveFilter(18)} key="18" value={18}>
//                     <span>BBVA ACH</span>
//                 </button>

//                 <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 12 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => setActiveFilter(12)} key="12" value={12}>
//                     <span>Otros bancos</span>
//                 </button>

//                 <button className={"focus:outline-none hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (activeFilter == 6 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => setActiveFilter(6)} key="6" value={6}>
//                     <span>Fraude</span>
//                 </button>



//             </div>
//             <DataTable
//                 title={``}
//                 noHeader={true}
//                 clearSelectedRows={toggledClearRows}
//                 paginationResetDefaultPage={toggledClearRows}
//                 data={listWithdrawFilter}

//                 columns={columns}


//                 selectableRowsHighlight={true}
//                 fixedHeader={true}
//                 noDataComponent={"No hay movimientos disponibles"}

//                 pagination={true}
//                 paginationComponentOptions={paginationOptions}

//                 progressPending={pending}
//                 progressComponent={<Circular />}

//                 paginationTotalRows={totalRows}
//                 paginationPerPage={newPerPage}
//                 paginationServer
//                 paginationRowsPerPageOptions={numFilas}
//                 onChangePage={getListSwaps}
//                 onChangeRowsPerPage={handlePerRowsChange}

//             />
//         </>
//     ) : (
//         <NoView />
//     )

// }

// export default RetirosMutu;

