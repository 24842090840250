import React, { useState, useEffect, useContext, useRef } from 'react'
import Numeral from "numeral";
import axios from "axios";
import styled from "styled-components";
import { AuthContext } from "../../context/AuthContext";
import { TitleCase, FormatAmount, isEmpty } from "../common/functions"
import S3 from "react-aws-s3"
import { FormatPhone } from "../common/functions"
import moment from 'moment';
import Banner from '../../components/Banner';
import DataTable from 'react-data-table-component';
import File from '../../assets/files/format_reload.xlsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BsCheckCircle } from "react-icons/bs";
import { FunctionContext } from "../../context/FuntionsContext";
import ModalEvidencia from "../layouts/modalEvidencia"
import useGeolocation from "react-hook-geolocation";
import ModalRecarga from "../layouts/ModalRecarga"
import { RiCloseCircleFill } from "react-icons/ri";
import AxioInstance from '../../axios/AxiosConfig';
import { FaFileExcel } from "react-icons/fa";
import Dropzone from 'react-dropzone';

const Recarga = (props) => {
    const DEV = localStorage.getItem('DEV');

    const { latitude, longitude } = useGeolocation();
    const [dataModalConfirm, setdataModalConfirm] = useState([]);

    const [ActivarModal, setActivarModal] = useState(false);
    const { isSuperUser } = useContext(AuthContext)
    const { setShowModalRecarga, showModalRecarga, renderNotifications,
        setRenderNotifications } = useContext(FunctionContext)
    const [alias, SetAlias] = useState("")
    const [Lote, setLote] = useState("")
    const [isLoadingDrop, setIsLoadingDrop] = useState(false)


    const [imagen, setImagen] = useState(null)
    const [pagination, setPagination] = useState(true);
    const { getAuthenticatedHeaders, permissions, setPendingRecargas, getAccessToken } = useContext(AuthContext);
    const [number, setNumber] = useState(0)
    const [user, setUser] = useState("");
    const [url, setUrl] = useState("");

    const [data, setData] = useState();
    const fileInput = useRef(null)

    const [Modal, setModal] = useState(false)

    const [pending, setPending] = useState(false);
    const [msg, setMsg] = useState(false);


    const [bankAccount, setBankAccount] = useState("");
    const [monedas,] = useState([
        {
            name: "COP",
            id: "COP",
        },
        {
            name: "USD",
            id: "USD",
        },
    ]);
    const [moneda, setMoneda] = useState("COP")
    const [isLoading, setIsLoading] = useState(false)
    const [showBannerAccion, setShowBannerAccion] = useState(false)
    const [description, setDescription] = useState("")
    const [cantidadCaracter, setCantidadCaracter] = useState(0)
    const [loadingAction, setLoadingAction] = useState(false)
    const [error, setError] = useState("");
    const [urlimg, setUrlimg] = useState("");
    const [bankAccounts, setBankAccounts] = useState([]);
    const [valor, setValor] = useState(0)
    const [img, setImg] = useState(null)
    const [showBanner, setShowBanner] = useState(false)
    const [errorBanner, setErrorBanner] = useState(null)
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState(props.user.username);
    const [agregar, setAgregar] = useState(null)
    const [id, setId] = useState(null)
    const [errorBannerAccion, setErrorBannerAccion] = useState(null)
    const caracteres = (e) => {

        setDescription(e)
        setCantidadCaracter(e.length)
    }
    const Confirmar = (tipo, data) => {
        setActivarModal(false)
        setdataModalConfirm(data)
        setAgregar(tipo)
        setShowModal(true)
        setId(data.pk)
    }

    useEffect(() => {

        renderDataTable()
        getUserListBanks()
    }, [])
    const checkUserPassword = async () => {

        if (!password) {
            return;
        }
        const data = {
            username: props.user.username,
            password: password
        }
        AxioInstance.post(`/api/login`, { data }, {
            headers: await getAuthenticatedHeaders(),
        })
            .then(response => {
                if (response.data.status === 200) {
                    aceptarOeliminar()
                    setShowModal(false);
                    setLoadingAction(true)
                } else {
                    setShowModal(true);
                    setError("Contraseña incorrecta");
                }
            })
    }
    const paginationOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };
    const ModalBlur = styled.div`
    display: flex ;
    position: absolute ;
    width: 84% !important;
    height: 93% !important;
    background: rgba(47, 46, 44, 0.5);
    backdrop-filter: blur(5px) !important;
    z-index:999999 !important;
    justify-content: center;
    align-items:center;
`;
    const handleChangeWithDrawAmount = (amountValue) => {

        let amount = parseInt(
            amountValue.target.value.toString().replace(/\D/g, "")
        );
        setValor(amount)
        let withdrawAmount = Numeral(amount).format("0,0[.]00").replace(/,/g, ".");
        setNumber(withdrawAmount);
    };
    const getUserListBanks = async () => {
        try {
            const response = await AxioInstance.post(`/api/get_banks/`, {
                headers: await getAuthenticatedHeaders(),
            });

            if (response.data.status === 200) {
                let data = response.data.data;
                data.push({ id: 999, name: "Crypto" })
                setBankAccounts(data);
                setBankAccount(data[0].name);
            }
        } catch (error) {

        }
    };
    const onDrop = async (acceptedFiles) => {
        setShowBanner(false)
        let banner = {}
        if (!isEmpty(acceptedFiles)) {
            const DEV = localStorage.getItem('DEV');

            setIsLoadingDrop(true)
            const formData = new FormData();
            formData.append('file', acceptedFiles[0])
            formData.append('typeTask', 'reload')
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + await getAccessToken(),
                'x-api-key': DEV === "https://dev.cobru.co/" ? process.env.REACT_APP_API_KEY_DEV : process.env.REACT_APP_API_KEY

            };

            axios.post(DEV + 'panel/', formData, { headers }, {
            }).then(res => {

                if (res.data.result === "OK") {

                    setMsg('Recargas en proceso')
                    setShowBanner(true)
                    setErrorBanner(false)
                    setIsLoadingDrop(false)
                    renderDataTable()
                } else {
                    setMsg('No se pudo realizar la operacion')
                    setShowBanner(true)
                    setErrorBanner(true)
                    renderDataTable()

                }
            }).catch((e) => {

                setMsg('No se pudo realizar la operacion')
                setShowBanner(true)
                setErrorBanner(true)
                renderDataTable()

            })
        }
        setShowModalRecarga(false)
        setIsLoadingDrop(false)
    }
    const consultar = async (e) => {
        e.preventDefault();
        setLoading(true)

        const bank = bankAccount.toString().replace(/\D/g, "")
        let file = fileInput.current.files[0];
        let newFileName = `${user.toString().replace(/\D/g, "")}/${number}/${bank}/${moment().format("YYYY-MM-DD")}`

        const config = {
            bucketName: 'cobru-evidences',
            region: 'ca-central-1',
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_S3,
            secretAccessKey: process.env.REACT_APP_ACCESS_SECRET_KEY_S3,

        }


        const ReactS3Client = new S3(config)
        ReactS3Client
            .uploadFile(file, newFileName)
            .then(data => {
                setUrlimg(data.location)

                consultarapi(data.location)
            }

            )
            .catch(err => {

            }
            )
    };
    const GetUserByPhoneCcOrEmail = async (value) => {
        setUser(value)
        let user = ''

        if (value[0] == '(') {
            user = value.toString().replace(/\D/g, "");
        } else {
            user = value
        }

        const data = {
            search_method: "phone",
            search_value: user.toString().toLowerCase().replace(/ /g, ""),

        }
        if (user.length > 6) {


            const response = await AxioInstance.post(`/api/get_user_by_phone_cc_email/`, {
                headers: await getAuthenticatedHeaders(),
                data

            });
            SetAlias(response.data.data.alias)
        }
        /*  return response.error ? null : response.message; */
    }
    const consultarapi = async (img) => {

        const data = {
            superuser: isSuperUser,
            lati: latitude,
            url: url,
            currency_code: moneda,
            long: longitude,
            amount: valor,
            bank_withdraw: bankAccount,
            url_withdraw: urlimg != "" ? urlimg : img,
            user_id: user.toString().replace(/\D/g, ""),
            description_withdraw: description == "" ? "Recarga Billetera Cobru" : description
        }
        try {
            const response = await AxioInstance.post(`/api/withdraw_manual/`, {
                headers: await getAuthenticatedHeaders(),
                data
            });



            if (response.data.status === 200) {
                setdataModalConfirm({
                    pk: response.data.data.id,
                    name: null,
                    date_created: null,
                    amount: data.amount,
                    bank_withdraw: data.bank_withdraw,
                    owner: data.user_id,
                })
                setActivarModal(true)
                renderDataTable()


            } else if (response.data && response.data.data.error == "['Bad cobru amount']") {
                setShowBanner(true)
                setErrorBanner(true)
                setMsg('Monto invalido')
            } else {
                setShowBanner(true)
                setErrorBanner(true)
                setMsg('El usuario no se encontró')
            }



        } catch (error) {
            setShowBanner(true)
            setErrorBanner(true)
        }
        setLoading(false)
        setImg(null)
        setValor(0)
        setUrlimg("")
        setBankAccount("")
        setUser("")
        setUrl("")

        fileInput.current.value = ''
        setNumber(0)
        setDescription("")
        setShowModalRecarga(false)

    };

    const renderDataTable = async (activar = false) => {
        setIsLoading(true)
        setPending(true)
        try {
            const response = await AxioInstance.post(`/api/lista_recarga/`, {
                superuser: isSuperUser,
                headers: await getAuthenticatedHeaders(),

            });



            if (response.data.status === 200) {
                contarRecargas(response.data.data)
                let data = response.data.data.data;

                setData(data)
                if (activar === true) {
                    setActivarModal(true)

                }
            }
        } catch (error) {

        }
        setPending(false)
        setIsLoading(false)
    };
    const contarRecargas = (datas) => {
        var total = 0

        datas.data.map((item) => {

            try {
                if (item.state == 1) {
                    total += 1
                }

            } catch (error) {

            }
        });
        setPendingRecargas(total)
    }
    const aceptarOeliminar = async () => {

        const data = {
            cobru_id: id,
            approved: agregar,
            lati: latitude,
            long: longitude,
        }
        try {
            const response = await AxioInstance.post(`/api/status_withdraw_manual/`, {
                headers: await getAuthenticatedHeaders(),
                data

            });



            if (response.data.status === 200) {

                if (data.approved) {
                    setActivarModal(true)
                    renderDataTable()

                } else {
                    renderDataTable()
                    setErrorBannerAccion(false)
                    setShowBannerAccion(true)
                }

            } else {
                setErrorBannerAccion(true)
                setShowBannerAccion(true)
            }
        } catch (error) {

        }
        setRenderNotifications(!renderNotifications)
        setLoadingAction(false)
    };

    const handleChangeBanks = (event) => {
        event.preventDefault();


        setBankAccount(event.target.value);

    };
    const handleChangeMoneda = (event) => {
        event.preventDefault();


        setMoneda(event.target.value);

    };
    const optionValueSelectBank = () => {
        let items = []

        for (let i = 0; i < bankAccounts.length; i++) {

            items.push(
                <option key={bankAccounts[i].id}
                    value={TitleCase(bankAccounts[i].name)}>
                    {TitleCase(bankAccounts[i].name)}
                </option>
            );
        }
        return items;
    };
    const optionValueSelectMoneda = () => {
        let items = []

        for (let i = 0; i < monedas.length; i++) {

            items.push(
                <option key={monedas[i].id}
                    value={(monedas[i].name)}>
                    {(monedas[i].name)}
                </option>
            );
        }
        return items;
    };
    const changeBgColor = (state) => {
        let color = ''
        if (state === 0) {
            color = 'bg-yellow-500'
        } else if (state === 1) {
            color = 'bg-yellow-500'
        } else if (state === 2) {
            color = 'Color-red'
        } else if (state === 3) {
            color = 'bg-green-500'
        } else if (state === 4) {
            color = 'Color-red'
        } else if (state === 5) {
            color = 'Color-red'
        } else if (state === 6) {
            color = 'Color-red'
        }
        return color
    }
    const columns = [{
        name: 'REF',

        cell: row => (
            <div className={'flex-row flex items-center justify-start'}>
                <div
                    title={row.state === 1
                        ? "Pendiente"
                        : row.state === 2
                            ? "Rechazado"
                            : row.state === 3
                                ? "Aprobado"
                                : ""
                    }
                    className={changeBgColor(row.state) + ' rounded-full h-4 w-4 bg-green mr-3'}
                >

                </div>
                <div
                    style={{ fontWeight: 700 }}>
                    <p className={!!row.payer_redirect_url ? "links cursor-pointer" : "notlinks"} onClick={() => AbrirModal(row.payer_redirect_url)}>
                        {row.pk}
                    </p>
                </div>
            </div>),

        sortable: true,
    },
    {
        name: 'Nombre',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (
            <div>{row.name}</div>
        )
    },
    {
        name: 'User',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (
            <div >
                <a target='_blank' href={`${DEV}admin/api/profile/?q=${row.owner}`}>
                    {row.owner}
                </a>
            </div>
        )
    },
    {
        name: 'Banco',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (


            <p >{row.bank_withdraw}</p>
        )
    },
    {
        name: 'Moneda',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (


            <p >{row.currency_code}</p>
        )
    },
    {
        name: 'Monto',
        //selector: 'bank_account',
        sortable: true,
        cell: row => (
            <div>{FormatAmount(row.amount)}</div>

        )
    },

    /* {
        name: 'MONTO',
        cell: row => <div>{FormatAmount(row.amount)}</div>,
        sortable: true,
    }, */

    {
        name: 'Fecha',
        cell: row => (
            <div>{moment(row.date_created).format('YYYY-MM-DD HH:mm:ss A')}</div>

        ),
        //cell: row => <div>{moment(row.date).format('YYYY-MM-DD HH:mm:ss A')}</div>,
        sortable: true,
    },
    {
        name: 'Acciones',
        cell: row => (
            <div className="flex w-full ">

                {row.state === 1 && !loadingAction &&
                    <div className="px-0 py-0 w-full flex  flex-row items-center">
                        <button onClick={() => Confirmar(true, row)} className=" border-2 border-green-600 rounded-full w-full h-8 text-green-600 hover:text-white  hover:bg-green-600 button-outline-off">Aprobar</button>
                        <button className="button-outline-off my-1  rounded-full w-full h-8 text-red-600 hover:text-white hover:bg-red-600 button-outline-off" onClick={() => Confirmar(false, row)}>Rechazar</button>
                    </div>
                }

                {row.state === 1 && loadingAction && id === row.pk &&
                    <div className="mx-4">

                        <CircularProgress size={26} />

                    </div>
                }
            </div>
        ),
        //cell: row => <div>{moment(row.date).format('YYYY-MM-DD HH:mm:ss A')}</div>,
        sortable: true,
    },




    ];
    const Circular = () => (
        <div className="flex items-center justify-center">
            {
                <CircularProgress size={75} />
            }
        </div>
    )
    const AbrirModal = (e) => {

        if (e === null) {
            setShowBanner(true)
            setErrorBanner(true)
            setMsg('No hay ninguna evidencia')
        }
        else {
            setImagen(e)
            setModal(true)


        }
    }



    return (

        <>
            {showModalRecarga &&
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" >
                        <div className="" >

                            {!Lote && <div className="flex-col  flex bg-blue-400 h-1/3x md:w-full  rounded border-solid shadow-lg  " style={{ background: "#ffffff" }}>
                                <p className="text-right pr-5 pt-3 text-xl cursor-pointer " onClick={() => setShowModalRecarga(false)}>x</p>
                                <form className="w-full " >
                                    {isSuperUser == true &&
                                        <div className=" mx-3 my-2">
                                            <label className="mx-3">Usuario</label>
                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                placeholder="(301) 548-3222"
                                                pattern="[0-9]*"
                                                autoFocus={false}
                                                value={FormatPhone(user)}
                                                onChange={(e) => GetUserByPhoneCcOrEmail(e.target.value)}
                                            />
                                            <p className="text-xs ml-2">{alias}</p>
                                        </div>
                                    }

                                    <div className=" mx-3 my-2">
                                        <label className="mx-3">Banco</label>
                                        <select
                                            className="flex  w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 pl-3 "
                                            name={bankAccount}
                                            defaultValue=""
                                            autoFocus={false}
                                            value={bankAccount}
                                            custom={bankAccount.toString()}
                                            onChange={handleChangeBanks}
                                        >
                                            <option hidden value="">Seleccionar</option>
                                            {bankAccounts.length <= 0 ? (
                                                <option value={bankAccount}>
                                                    No hay cuentas registradas
                                                </option>
                                            ) : (
                                                optionValueSelectBank()
                                            )}
                                            ;
                                        </select>


                                    </div>
                                    <div className=" mx-3 my-2">
                                        <label className="mx-3">Moneda</label>
                                        <select
                                            className="flex  w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 pl-3 "
                                            name={moneda}
                                            defaultValue=""
                                            autoFocus={false}
                                            value={moneda}
                                            custom={moneda}
                                            onChange={handleChangeMoneda}
                                        >
                                            <option hidden value="">Seleccionar</option>
                                            {monedas.length <= 0 ? (
                                                <option value={moneda}>
                                                    No hay cuentas registradas
                                                </option>
                                            ) : (
                                                optionValueSelectMoneda()
                                            )}
                                            ;
                                        </select>


                                    </div>
                                    <div className="w-full ">
                                        <div className="mx-3 my-2" >
                                            <label className="mx-3">Monto a recargar</label>
                                            <input
                                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                id="withdraw_amount"
                                                value={"$" + number}
                                                onChange={handleChangeWithDrawAmount}
                                                type="tel"
                                                keyboard="numeral"
                                                pattern="[0-9]*"
                                            ></input>

                                        </div>
                                    </div>

                                    <div className="w-full ">
                                        <div className="mx-3 my-2 ">
                                            <label className="mx-3">Evidencia</label>
                                            <input
                                                className="appearance-none  block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500  "
                                                accept="image/*,application/pdf"
                                                type="file"
                                                ref={fileInput}
                                                autoFocus={false}
                                                onChange={(e) => setImg(e.target.value)}


                                            />


                                        </div>
                                    </div>
                                    <div className="w-full ">
                                        <div className="mx-3 my-2">
                                            <label className="mx-3 flex justify-between items-center"><span>Descripción<span className="text-gray-700">{` (opcional)`}</span>  </span>{description.length !== 0 && <span className="text-gray-700 text-xs">{`${cantidadCaracter}/40`}</span>}</label>

                                            <textarea
                                                className="appearance-none block h-20  w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500  "
                                                type="text"
                                                maxLength="40"
                                                placeholder="Recarga Billetera Cobru"
                                                onChange={(e) => caracteres(e.target.value)}
                                            />


                                        </div>
                                    </div>
                                    <div className="w-full ">
                                        <div className="mx-3 my-2">
                                            <label className="mx-3 flex justify-between items-center"><span>URL<span className="text-gray-700">{` (opcional)`}</span>  </span>{description.length !== 0 && <span className="text-gray-700 text-xs">{`${cantidadCaracter}/40`}</span>}</label>

                                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                placeholder="https://webhook.com/notify/"
                                                type="url"
                                                autoFocus={false}
                                                value={(url)}
                                                onChange={(e) => setUrl(e.target.value)}
                                            />


                                        </div>
                                    </div>
                                    <div className="w-full ">
                                        <div className="mx-3 my-2">
                                            {((moneda === "COP" && valor >= 10000) || (moneda === "USD" && valor >= 1)) && img !== null && loading === false && bankAccount !== "" ?

                                                <button
                                                    autoFocus={false}
                                                    type="reset"
                                                    className="w-full  bg-green-600 hover:bg-green-600 border-green-600 hover:green-600 text-sm border-4 text-white py-2 rounded-full button-outline-off "
                                                    onClick={(e) => consultar(e)}
                                                > Recarga {number != 0 ? `$${number}` : ""}
                                                </button> : (
                                                    <button
                                                        autoFocus={false}
                                                        className="w-full flex-shrink-0  text-sm border-4 text-white py-2 rounded-full btn-retiros button-outline-off"
                                                        type="button"

                                                    >{!loading ? `Recarga ${number != 0 ? `$${number}` : ""}` : "recargando..."}

                                                    </button>)}
                                            {<p onClick={() => setLote(true)} className="font-body text-lg links text-center py-2 mt-2 cursor-pointer">Recargas en lote</p>}
                                        </div>

                                    </div>

                                </form>
                            </div>}
                            {Lote &&
                                <div className="w-full md:w-full bg-white p-4 text-center   mr-2">
                                    <p className="text-right pr-5 pt-3 text-xl cursor-pointer " onClick={() => setShowModalRecarga(false)}>x</p>

                                    <div className="text-center py-4">
                                        <FaFileExcel size="2em" className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-green-600" />
                                        <a className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-gray-700" href={File} target="blank">
                                            <svg className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-gray-700 fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                                            <span>Descargar aquí plantilla de Recargas </span>
                                        </a>

                                        <div className="mt-5 border-dotted border-2 border-gray-600 text-center py-4 text-gray-800 cursor-pointer">
                                            <Dropzone
                                                onDrop={onDrop}
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                                                {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {!isDragActive && acceptedFiles.length == 0 && '¡Haga clic aquí o arrastre un archivo xlsx para cargarlo!!'}
                                                        {isDragActive && !isDragReject && "¡Sueltalo!"}
                                                        {isDragReject && "Tipo de archivo no aceptado, revisa la plantilla ejemplo!"}
                                                        <ul className="list-group mt-2">
                                                            {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
                                                                <li className="list-group-item list-group-item-success" key={acceptedFile.name}>
                                                                    se subio el archivo: {acceptedFile.name}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                )}
                                            </Dropzone>
                                            {isLoadingDrop &&
                                                <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            }
                                        </div>
                                    </div>


                                </div>

                            }
                        </div>
                    </div>
                    <div className=" fixed inset-0 z-40 " style={{
                        background: 'rgba(47, 46, 44, 0.5)',
                        backdropFilter: 'blur(10px)',
                    }}></div>
                </>
            }



            <div className="md:grid md:grid-cols-12 h-auto gap-2 gap-y-2">

                {Modal && <ModalEvidencia img={imagen} setModal={setModal} />}
                {showBanner &&
                    <div className="col-span-12 ml-2">
                        <Banner
                            title={'Proceso realizado'}
                            description={errorBanner ? msg : "Se ha enviado la recarga"}
                            setShowBanner={setShowBanner}
                            error={errorBanner}
                        />
                    </div>
                }
                {showBannerAccion &&
                    <div className="col-span-12 ml-2">
                        <Banner
                            title={'Proceso realizado'}
                            description={errorBannerAccion ? `no se pudo realizar la accion` : `se ${agregar ? "acepto" : "Rechazo"} la recarga`}
                            setShowBanner={setShowBannerAccion}
                            error={errorBannerAccion}
                        />
                    </div>
                }

                <div className="col-span-12 shadow-lg rounded w-full h-auto px-2 py-2 bg-white">

                    <DataTable

                        data={data}
                        columns={columns}

                        selectableRowsHighlight={true}
                        fixedHeader={true}
                        pagination={pagination}
                        paginationComponentOptions={paginationOptions}
                        progressPending={pending}
                        progressComponent={<Circular />}
                        noHeader={true}
                        noDataComponent={"No hay Recargas"}
                    />


                </div>
                {ActivarModal && <ModalRecarga retiroPk={"1222"} setShowModal={setActivarModal} info={dataModalConfirm} />}
                {showModal &&
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-2 mx-auto max-w-xs">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="relative p-6 flex-auto">

                                        <p className="my-4 text-gray-600 text-lg leading-relaxed">Ingresa tu contraseña</p>
                                        <input aria-label="Password" name="password" value={password} onChange={e => setPassword(e.target.value)} type="password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Contraseña"></input>
                                        {error &&
                                            <div className="relative flex justify-center py-3 px-4 mx-auto w-auto"><p className="text-red-500 text-xs italic">{error} </p></div>
                                        }
                                    </div>

                                    <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 rounded-full"
                                            type="button"
                                            style={{ transition: "all .15s ease" }}
                                            onClick={() => setShowModal(false)}
                                        >
                                            Cancelar
                                        </button>
                                        <button
                                            className="bg-green-600 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 rounded-full"
                                            type="button"
                                            style={{ transition: "all .15s ease" }}
                                            onClick={checkUserPassword}
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" fixed inset-0 z-40 " style={{
                            background: 'rgba(47, 46, 44, 0.5)',
                            backdropFilter: 'blur(10px)',
                        }}></div>
                    </>
                }

            </div>

        </>
    )
}

export default Recarga
