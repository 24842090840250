import React, { useState, useContext } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { BsFillCalendarFill } from "react-icons/bs";
import moment from 'moment';
import { localeDataPicker } from '../common/functions';
import axios from 'axios';
import Banner from '../../components/Banner';
import { CSVLink } from "react-csv";
import NoView from '../layouts/NoView';
import { AuthContext } from '../../context/AuthContext';
//import { getAuthenticatedHeaders} from '../common/functions';
import AxioInstance from '../../axios/AxiosConfig';

const ReportesDate = (props) => {


    const getPermissions = localStorage.getItem('userDetails')

    const [showBanner, setShowBanner] = useState(false);
    const [errorBanner, setErrorBanner] = useState(false);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [value, setValue] = useState("transactions");
    const [start_date, setStart_date] = useState(moment().startOf('month'));
    const [end_date, setEnd_date] = useState(moment().endOf('month'));
    const ranges = {
        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Últimos 7 Dias': [moment().subtract(6, 'days'), moment()],
        'Últimos 30 Días': [moment().subtract(29, 'days'), moment()],
        'Último Mes': [moment().startOf('month'), moment().endOf('month')],
        'Mes Anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    };
    const locale = localeDataPicker;
    const maxDate = moment();
    const [label, setLabel] = useState("");
    const csvLink = React.useRef();
    const { getAuthenticatedHeaders, permissions } = useContext(AuthContext)

    //const canViewReport = props.canViewReport;
    const canViewReport = permissions.permission_reports;

    React.useEffect(() => {
        let start = start_date.format('MMM D YYYY');
        let end = end_date.format('MMM D YYYY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        setLabel(label);

    }, [start_date, end_date]);


    const handleEventSelect = (event) => {
        setValue(event.target.value);
    }

    const handleEvent = (event, picker) => {
        if (event.type === 'apply') {
            setStart_date(picker.startDate);
            setEnd_date(picker.endDate);
        }
    }

    const handleClickReport = (event) => {
        callApiReport(start_date.format('YYYY-MM-DD'), end_date.format('YYYY-MM-DD'), value);
        event.preventDefault();

    }

    const callApiReport = async (date_start, date_end, type) => {
        setIsLoading(true);
        AxioInstance.post(`/api/reports/nequi`, { date_start, date_end, headers: await getAuthenticatedHeaders(), })
            .then(response => {
                if (response.data.status === 200) {
                    if (response.data.statusText === "OK") {
                        setErrorBanner(false)
                        setShowBanner(true)

                    }
                    setData(response.data.data.data.reportURL);

                    downloadCSV(response.data.data.data.reportURL)
                    setData(response.data.data.data.reportURL)

                    setIsLoading(false);
                } else {

                    setIsLoading(false);
                    setError(error)
                }

            }).catch(error => setIsLoading(false), setError(error));

    }

    const downloadCSV = async (data) => {

        const link = document.createElement('a');
        if (data == null) return;
        const filename = `${'cobru-' + label + '.csv'}`;
        link.setAttribute('href', (data));

        link.setAttribute('download', filename);
        link.click();
        //window.location.reload();

    }
    return canViewReport ? (
        <>
            {showBanner && <Banner
                title={'Proceso realizado'}
                description={`Reporte generado`}
                setShowBanner={setShowBanner}
                error={errorBanner}
            />}
            <div className="flex md:flex-row flex-wrap p-4 bg-white p-4">

                <div className="w-full h-12">
                    <div className="w-full md:w-2/6 bg-white p-4 text-center ">

                        <p className="font-body text-lg text-gray-800 text-left py-2">Reporte de Nequi</p>
                    </div>
                </div>

                <div className="w-full md:w-2/6 bg-white p-4 text-left ">
                    <div className="max-w-sm rounded overflow-hidden">
                        <div className="px-2 py-4">
                            <div className="font-bold text-xl mb-2 mt-3 text-black">
                                <DateRangePicker
                                    initialSettings={{
                                        startDate: start_date,
                                        endDate: end_date,
                                        ranges: ranges,
                                        locale: locale,
                                        maxDate: maxDate
                                    }}
                                    onEvent={handleEvent}
                                >
                                    <button>
                                        <div className="px-1 text-gray-700 float-left">
                                            <BsFillCalendarFill />
                                        </div>
                                        <span className="font-bold text-xs float-left">{label}</span>
                                        <div className="float-left text-gray-700">
                                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                        </div>
                                    </button>
                                </DateRangePicker>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full md:w-2/6 bg-white p-4 text-center">
                    <div className="max-w-sm rounded overflow-hidden">
                        <div className="px-2 py-4">
                            <div className="font-bold text-xl mb-2 text-black">
                                <a href={data} target={"_blank"} >
                                    <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-sm" onClick={handleClickReport} disabled={isLoading}>{(isLoading) ? 'Generando...' : 'Generar '}</button>
                                    <CSVLink
                                        data={data}
                                        filename={'cobru-' + label + '.csv'}
                                        className="hidden"
                                        ref={csvLink}
                                        target="_blank"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    ) : (
        <NoView />
    )

}

export default ReportesDate;