import React, { useState, useEffect, useContext, useRef } from 'react';

import { FaFileExcel } from "react-icons/fa";
import File from '../../assets/files/cobru_pro_plantilla.xlsx';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { orderBy } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isEmpty, FormatAmount, FormatPhoneNumber } from '../common/functions';
import NoView from '../layouts/NoView';
import { AuthContext } from '../../context/AuthContext';
import { FunctionContext } from '../../context/FuntionsContext';
import Banner from '../../components/Banner';
import ModalCobrar from "../layouts/ModalCobrar"
import { BsFillCalendarFill } from "react-icons/bs";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { localeDataPicker } from '../common/functions';
import ModalManualCobrus from "../../containers/layouts/ModalManualCobrus"
import ModalMAnualDescripcion from "../layouts/ModalManualDescripcion"
import useGeolocation from "react-hook-geolocation";
import AxioInstance from '../../axios/AxiosConfig';

const Cobrus = (props) => {
    const { latitude, longitude } = useGeolocation();

    const locale = localeDataPicker;
    const maxDate = moment();
    const [DescripcionManualModal, setDescripcionManualModal] = useState(false)
    const [label, setLabel] = useState("");
    const [manualData, setManualData] = useState({});
    const [ModalTotalRows, setModalTotalRows] = useState(0);
    const [isLoadingDrop, setIsLoadingDrop] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [bannerError, setBannerError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [inicialModal, setInicialModal] = useState(true);

    const [topup, setTopup] = useState(0);
    const [start_date, setStart_date] = useState(moment(), moment());
    const [end_date, setEnd_date] = useState(moment());
    const ranges = {
        'Hoy': [moment(), moment()],
        'Últimos 7 Dias': [moment().subtract(6, 'days'), moment()],
        'Últimos 30 Días': [moment().subtract(29, 'days'), moment()],
        'Último Mes': [moment().startOf('month'), moment().endOf('month')],
        'Mes Anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    };


    const [bannerContent, setBannerContent] = useState({
        title: '',
        description: '',
    });
    const [loadingTable, setLoadingTable] = useState(true);
    const [cobrus, setCobrus] = useState(props.cobrus);
    const pendingTasks = useState(props.pendingTasks);
    const [statuCobru, setStatuCobru] = useState(1);
    const [totalWebCobrus, setTotalWebCobrus] = useState(0);
    const [totalWebCobrusProcessed, setTotalWebCobrusProcessed] = useState(0);
    const [totalWebCobrusNotProcessed, setTotalWebCobrusNotProcessed] = useState(0);
    const [totalPendingTasks, setTotalPendingTasks] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isActiveFilter, setIsActiveFilter] = useState(true);
    const [msncobrus, setMsncobrus] = useState();
    const [processingCobrusMsn, setProcessingCobrusMsn] = useState(0);
    const [processingCobrus, setProcessingCobrus] = useState(false);
    const { getAuthenticatedHeaders, getAccessToken, permissions } = useContext(AuthContext)
    const { setBuscarCobrar, buscarCobrar, buscar, CobrarLayout, opcionCobrus, ModalManual, setModalManuel } = useContext(FunctionContext)
    //const canViewCobrus = props.canViewCobrus;
    const canViewCobrus = permissions.permission_cobru;
    /* const headers = { 
        'Authorization': 'Bearer ' + token,
    }; */


    //Pagination
    const [allCobrus, setAllCobrus] = useState([]);
    const [currentCobrus, setCurrentCobrus] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setpagination] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const changeCountRef = useRef(0);

    useEffect(() => {
        if (changeCountRef.current >= 1) {
            fetchCobrus(1, statuCobru)
        } else {
            // Incrementa el contador de cambios en la referencia
            changeCountRef.current += 1;
        }

    }, [start_date])
    React.useEffect(() => {
        let start = start_date.format('MMM D YYYY');
        let end = end_date.format('MMM D YYYY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        setLabel(label);

    }, [start_date, end_date]);
    let COBRU_ME = process.env.REACT_APP_API_URL.toString() === 'https://prod.cobru.co/' ? 'https://cobru.me/' : process.env.REACT_APP_API_URL
    const onDrop = async (acceptedFiles) => {
        setShowBanner(false)
        let banner = {}
        if (!isEmpty(acceptedFiles)) {
            const DEV = localStorage.getItem('DEV');

            setIsLoadingDrop(true)
            const formData = new FormData();
            formData.append('file', acceptedFiles[0])
            formData.append('typeTask', 'cobru')
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + await getAccessToken(),
                'x-api-key': DEV === "https://dev.cobru.co/" ? process.env.REACT_APP_API_KEY_DEV : process.env.REACT_APP_API_KEY


            };

            axios.post(DEV + 'panel/', formData, { headers }, {
            }).then(res => {
                if (res.data.result === "OK") {
                    banner = {
                        title: 'Ok',
                        description: 'Cobrus en proceso'
                    }

                    setIsLoadingDrop(false)
                    setBannerContent(banner)
                    setShowBanner(true)
                    setBannerError(false)

                } else {
                    banner = {
                        title: 'Ocurrio un error',
                        description: 'No se pudo realizar la operacion',
                    }
                    setBannerContent(banner)
                    setShowBanner(true)
                    setBannerError(true)


                }
            }).catch((e) => {
                banner = {
                    title: 'Ocurrio un error',
                    description: 'No se pudo realizar la operacion',
                }
                setBannerContent(banner)
                setShowBanner(true)
                setBannerError(true)


            })
        }
        setIsLoadingDrop(false)
    }

    const processCobruBatch = () => {

        if (totalWebCobrusNotProcessed > 0) {

            setProcessingCobrusMsn(true);
            setMsncobrus("Mensajes enviados..")

            AxioInstance.get(`/api/send_cobru_sms/`, {
                params: {
                    token: getAccessToken
                }
            }).then(response => {

                setProcessingCobrusMsn(false);

            }).catch(error => {

            });
        } else {
            setMsncobrus("No hay cobrus que deban ser procesados")
        }

    }
    const handleEvent = (event, picker) => {
        setInicialModal(false)

        if (event.type === 'apply') {

            setStart_date(picker.startDate);
            setEnd_date(picker.endDate);
        }
    }
    const countCobrus = async (cobrus) => {
        let totalWebCobrus = 0;
        let totalWebCobrusProcessed = 0;
        let totalWebCobrusNotProcessed = 0;

        cobrus.map(cobru => {
            if (cobru.platform === "web") {
                totalWebCobrus += 1;
                totalWebCobrusProcessed += cobru.notified_sms ? 1 : 0;
                totalWebCobrusNotProcessed += cobru.notified_sms ? 0 : 1;
            }

            return cobru;
        });
        setTotalWebCobrus(totalWebCobrus);
        setTotalWebCobrusProcessed(totalWebCobrusProcessed);
        setTotalWebCobrusNotProcessed(totalWebCobrusNotProcessed);

    }
    function esTextoONumero(valor) {
        return isNaN(valor);
    }
    useEffect(() => {
        if (buscar) {
            if (esTextoONumero(buscarCobrar) !== false && opcionCobrus != 4 & opcionCobrus != 7) {
                window.alert('El campo solo acepta números');
            } else {
                fetchCobrus()
                setInicialModal(false)
            }
        }

    }, [buscar])
    const countPendingTasks = async (pendingTasks) => {

        let totalPendingTasks = 0;
        let processingCobrus = false;
        pendingTasks.map(item => {
            if (item.state === 0 && item.task_type === "cobru_batch") {
                totalPendingTasks += 1;
            }
            if (item.state === 0 && item.task_type === "notification_batch") {
                processingCobrus = true;
            }
            return item;
        })

        setTotalPendingTasks(totalPendingTasks);
        setProcessingCobrus(processingCobrus);

    }

    const handleFilter = async (filter) => {
        await setBuscarCobrar('')
        if (filter == 4) {
            fetchManualCobrus()
        } else {

            fetchCobrus(1, filter)
        }
        /*  if (filter !== 0) {
          let filteredCobrus = currentCobrus.filter((cobru) => {
            return cobru.state === filter
          }); */


        setStatuCobru(filter);

        setIsActiveFilter(true);



    };


    useEffect(() => {
        setIsLoading(true);
        if (cobrus) {

            setAllCobrus(cobrus);
            countCobrus(cobrus);
            countPendingTasks(pendingTasks);
            setIsLoading(false);

        }
    }, [pendingTasks])





    const ConvertirJson = async (datajson, busqueda) => {
        try {
            var data = await JSON.parse(datajson)

            if (busqueda === 'efecty' && data.efecty === true) {
                return true
            } else if (busqueda === 'BCH' && data.BCH === true) {
                return true

            }
            else if (busqueda === 'BTC' && data.BTC === true) {
                return true

            }
            else if (busqueda === 'CUSD' && data.CUSD === true) {
                return true

            }
            else if (busqueda === 'DAI' && data.DAI === true) {
                return true

            }
            else if (busqueda === 'DASH' && data.DASH === true) {
                return true

            }
            else if (busqueda === 'NEQUI' && data.NEQUI === true) {
                return true

            }
            else if (busqueda === 'baloto' && data.baloto === true) {
                return true


            }
            else if (busqueda === 'bancolombia_transfer' && data.bancolombia_transfer === true) {
                return true

            }
            else if (busqueda === 'corresponsal_bancolombia' && data.corresponsal_bancolombia === true) {
                return true

            }
            else if (busqueda === 'credit_card' && data.credit_card === true) {
                return true

            }
            else if (busqueda === 'dale' && data.dale === true) {
                return true

            }
            else if (busqueda === 'efecty' && data.efecty === true) {
                return true

            }
            else if (busqueda === 'exito' && data.exito === true) {
                return true
            }
            else if (busqueda === 'super_giros' && data.super_giros === true) {

            }
            else {
                return false
            }

        } catch (err) {
            // ⛔️ Unexpected token o in JSON at position 1

        }


    }
    const OpenModalData = (data) => {
        setOpenModal(true)
        setDataModal(data)
    }
    const OpenModalManual = (data) => {
        setDescripcionManualModal(true)
        setDataModal(data)
    }

    //data table columns
    const columns = [{
        name: 'REF',
        sortable: true,
        selector: 'pk',
        grow: 1,
        cell: row => (
            <div className='flex-row flex items-center justify-start  ' title={row.state === 0 || row.state === 1 ? 'Pendiente' : row.state === 5 ? 'Rechazado' : row.state === 3 ? 'Aprobado' : row.state === 4 ? 'Reversado' : ''}>
                <div className={"rounded-full h-4 w-4  " + (row.state === 0 || row.state === 1 ? 'bg-yellow-500' : row.state === 5 ? 'bg-red-500' : row.state === 3 ? 'bg-green-500' : row.state === 4 ? 'bg-red-500' : '')}></div>
                <p onClick={() => OpenModalData(row)} className='ml-3 links cursor-pointer'>{row.pk}</p>

            </div>
        )
    },
    {
        name: 'DESCRIPCION',
        grow: 5,
        wrap: true,
        cell: row => (
            <div className="text-gray-700 py-3 flex items-center">
                <span className='ml-3'> {row.description.slice(0, 60)}{row.description.length > 60 ? '...' : ''}.</span>
            </div>
        )
    },
    {
        name: 'VALOR',
        grow: 1,
        cell: row => (
            <div className="text-gray-700 py-3 flex items-center">
                <span>
                    {` ${FormatAmount(row.amount, 1)}`}<span style={{ fontSize: 9 }}>{`${FormatAmount(row.amount, 2)}`}</span>
                </span>

            </div>
        )
    },
    {
        name: 'URL',
        grow: 1,
        cell: row => (
            <div className=" py-3 flex items-center  text-gray-700">
                <span> <a>{row.url}</a></span>
            </div>
        )
    },
    {
        name: 'FECHA',
        sortable: true,
        selector: 'date_created._i',
        grow: 2,
        cell: row => (
            <div className="text-gray-700 py-3 flex items-center">
                <span> {moment(row.date_created).format('DD/MM/YYYY HH:mm A')}</span>
            </div>
        )
    },


    ];
    const columnsManual = [{
        name: 'REF',
        sortable: true,
        selector: 'pk',
        grow: 1,
        cell: row => (
            <div className='flex-row flex items-center justify-start  ' title={row.cobru_state === 0 || row.cobru_state === 1 ? 'Pendiente' : row.cobru_state === 5 ? 'Rechazado' : row.cobru_state === 3 ? 'Aprobado' : ''}>
                <div className={"rounded-full h-4 w-4  " + (row.cobru_state === 0 || row.cobru_state === 1 ? 'bg-yellow-500' : row.cobru_state === 5 ? 'bg-red-500' : row.cobru_state === 3 ? 'bg-green-500' : '')}></div>
                <p onClick={() => OpenModalManual(row)} className='ml-3 links cursor-pointer'>{row.pk}</p>

            </div>
        )
    },
    {
        name: 'DESCRIPCION',
        grow: 5,
        wrap: true,
        cell: row => (
            <div className="text-gray-700 py-3 flex items-center">
                <span className='ml-3'> {row.note}</span>
            </div>
        )
    },
    {
        name: 'USUARIO',
        grow: 1,
        cell: row => (
            <div className="text-gray-700 py-3 flex items-center">
                <span> {(row.user.username)}</span>
            </div>
        )
    },
    {
        name: 'VALOR',
        grow: 1,
        cell: row => (
            <div className="text-gray-700 py-3 flex items-center">
                <span> {FormatAmount(row.cobru_amount)}</span>
            </div>
        )
    },
    {
        name: 'URL',
        grow: 1,
        cell: row => (
            <div className=" py-3 flex items-center  text-gray-700">
                <span> <a>{row.cobru_url}</a></span>
            </div>
        )
    },
    {
        name: 'FECHA',
        sortable: true,
        selector: 'date_created._i',
        grow: 2,
        cell: row => (
            <div className="text-gray-700 py-3 flex items-center">
                <span> {moment(row.created_at).format('DD/MM/YYYY HH:mm A')}</span>
            </div>
        )
    },


    ];

    const customStyles = {
        header: {
            style: {
                color: '#000',
                minHeight: '45px'

            },
        },
        headRow: {
            style: {
                whiteSpace: 'nowrap',
                fontSize: '0.75rem',
                textAlign: 'left',

            },
        },
        headCells: {
            style: {
                paddingLeft: '3rem',
                paddingRight: '3rem'
            },
        },
        cells: {
            style: {
                borderStyle: 'dashed',
                borderTopWidth: '1px',
                whiteSpace: 'nowrap',
                paddingLeft: '3rem',
                paddingRight: '3rem'
            },
        },
    };


    //server side pagination
    const fetchManualCobrus = async (page, filtersss) => {

        setLoading(true);
        var cobru_id = ''
        var url = ''
        var pin = ''
        var user = ''
        var cus = ''
        var document_number = ''
        var authorization = ""
        if (opcionCobrus == 1) {
            cobru_id = buscarCobrar
        } else if (opcionCobrus == 2) {
            document_number = buscarCobrar
        } else if (opcionCobrus == 3) {
            pin = buscarCobrar
        } else if (opcionCobrus == 4) {
            url = buscarCobrar
        } else if (opcionCobrus == 5) {
            user = buscarCobrar
        } else if (opcionCobrus == 6) {
            cus = buscarCobrar
        } else if (opcionCobrus == 7) {
            authorization = buscarCobrar
        } else {
            url = ""
        }

        const data = {
            pageNum: !!page ? page : 1,
            perPage: perPage,
            url: url,
            authorization: authorization,
            document_number: document_number,
            cobru_id: cobru_id,
            state: buscarCobrar === '' ? !!filtersss ? filtersss : 1 : null,

            pin: pin,
            username: user,
            cus: cus,
            date_start: (url !== "" || document_number !== "" || cobru_id !== "") ? null : inicialModal ? null : start_date.format('YYYY-MM-DD'),
            date_end: (url !== "" || document_number !== "" || cobru_id !== "") ? null : inicialModal ? null : end_date.format('YYYY-MM-DD')
        }
        const response = await AxioInstance.post(`/api/review_summary/`, {
            headers: await getAuthenticatedHeaders(),
            data
        });
        if (response.data.status === 200 || response.data.status === 201) {

            setManualData(response.data.data.results)

            setModalTotalRows(response.data.data.count)
            setLoading(false);
            setLoadingTable(false)

        } else {
            setCurrentCobrus([]);
            setTotalRows(0)
            setLoading(false);
            setLoadingTable(false)
        }


    };

    const CobruManual = async (data) => {
        const formData = new FormData();
        formData.append('file', data.img)
        formData.append('url', data.url)
        formData.append('payment_method', data.PaymentMethod)
        formData.append('note', data.note.length == 0 ? "Cobru pagado manual" : data.note,)
        formData.append('latitude', latitude)
        formData.append('longitude', longitude)

        const DEV = localStorage.getItem('DEV');

        const datos = {
            url: data.url,
            payment_method: data.PaymentMethod,
            note: data.note.length == 0 ? "Cobru pagado manual" : data.note,
            latitude: latitude,
            longitude: longitude,
            file: data.img,
        }

        let banner = {}
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + await getAccessToken(),
            'x-api-key': DEV === "https://dev.cobru.co/" ? process.env.REACT_APP_API_KEY_DEV : process.env.REACT_APP_API_KEY

        };

        const response = axios.post(DEV + 'cobru/review_and_approved/', formData, { headers }, {
            data: datos,
            headers: await getAuthenticatedHeaders(),
        }, {

        });
        if (response.data?.result === "OK") {
            banner = {
                title: 'Ok',
                description: 'Cobru Pagado'
            }


            setBannerContent(banner)
            setShowBanner(true)
            setBannerError(false)

        } else if (response.data?.code_transaction === "COBRU_DOES_NOT_EXIST") {
            banner = {
                title: 'Ocurrio un error',
                description: "Este cobro no existe.",
            }
            setModalManuel(false)
            setBannerContent(banner)
            setShowBanner(true)
            setBannerError(true)
        }
        else {
            banner = {
                title: 'Ocurrio un error',
                description: 'No se pudo realizar la operacion',
            }
            setModalManuel(false)
            setBannerContent(banner)
            setShowBanner(true)
            setBannerError(true)


        }

    }
    const fetchCobrus = async (page, filtersss) => {

        setLoading(true);
        var cobru_id = ''
        var url = ''
        var pin = ''
        var cus = ''
        var user = ''
        var document_number = ''
        var authorization = ""
        if (opcionCobrus == 1) {
            cobru_id = buscarCobrar
        } else if (opcionCobrus == 2) {
            document_number = buscarCobrar
        } else if (opcionCobrus == 3) {
            pin = buscarCobrar
        } else if (opcionCobrus == 4) {
            url = buscarCobrar
        } else if (opcionCobrus == 5) {
            user = buscarCobrar
        } else if (opcionCobrus == 6) {
            cus = buscarCobrar
        } else if (opcionCobrus == 7) {
            authorization = buscarCobrar
        } else {
            url = ""
        }


        const data = {
            pageNum: !!page ? page : 1,
            perPage: perPage,
            url: url,
            authorization: authorization,
            document_number: document_number,
            cobru_id: cobru_id,
            state: buscarCobrar === '' ? !!filtersss ? filtersss : 1 : null,

            pin: pin,
            username: user,
            cus: cus,
            date_start: (url !== "" || document_number !== "" || cobru_id !== "") ? null : inicialModal ? null : start_date.format('YYYY-MM-DD'),
            date_end: (url !== "" || document_number !== "" || cobru_id !== "") ? null : inicialModal ? null : end_date.format('YYYY-MM-DD')
        }
        const response = await AxioInstance.post(`/api/cobrus/all`, {
            headers: await getAuthenticatedHeaders(),
            data
        });

        if (response.data.status === 200 || response.data.status === 201) {

            setCurrentCobrus(response.data.data.data);
            setTotalRows(response.data.data.count)
            setLoading(false);
            setLoadingTable(false)

        } else {
            setCurrentCobrus([]);
            setTotalRows(0)
            setLoading(false);
            setLoadingTable(false)
        }


    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const data = {
            state: 1,
            pageNum: !!page ? page : 1,
            perPage: perPage,

            date_start: start_date,
            date_end: end_date
        }

        const response = await AxioInstance.post(`/api/cobrus/all`, {
            headers: await getAuthenticatedHeaders(),
            data
        });
        setTotalRows(response.data.data.count)

        setCurrentCobrus(response.data.data.data);
        setPerPage(newPerPage);
        setLoading(false);
        setLoadingTable(false)
    };

    const handlePageChange = page => {
        fetchCobrus(page, statuCobru);
    };


    //data table filter
    const handleSort = (column, sortDirection) => {
        // simulate server sort
        setLoading(true);

        // instead of setTimeout this is where you would handle your API call.
        setTimeout(() => {
            setCurrentCobrus(orderBy(currentCobrus, column.selector, sortDirection));
            setLoading(false);
        }, 100);
    };




    return canViewCobrus === 1 || canViewCobrus === 3 ? (

        <>
            {canViewCobrus !== 1 && CobrarLayout ?
                <div className="flex md:flex-row flex-wrap p-1">
                    <div className="w-full md:w-1/2 bg-white p-4 text-center text-gray-200">
                        <p className="font-body text-lg text-gray-800 text-left py-2">Cobrus en lote</p>
                        <div className="text-center py-4">
                            <FaFileExcel size="2em" className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-green-600" />
                            <a className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-gray-700" href={File} target="blank">
                                <svg className="w-full sm:w-auto md:w-full lg:w-full xl:w-full text-gray-700 fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                                <span>Descargar aquí plantilla Envios </span>
                            </a>

                            <div className="mt-5 border-dotted border-2 border-gray-600 text-center py-4 text-gray-800 cursor-pointer">
                                <Dropzone
                                    onDrop={onDrop}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {!isDragActive && acceptedFiles.length == 0 && '¡Haga clic aquí o arrastre un archivo xlsx para cargarlo!!'}
                                            {isDragActive && !isDragReject && "¡Sueltalo!"}
                                            {isDragReject && "Tipo de archivo no aceptado, revisa la plantilla ejemplo!"}
                                            <ul className="list-group mt-2">
                                                {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => (
                                                    <li className="list-group-item list-group-item-success" key={acceptedFile.name}>
                                                        se subio el archivo: {acceptedFile.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    )}
                                </Dropzone>
                                {isLoadingDrop &&
                                    <img alt="loading.." className="relative flex justify-center py-3 px-4 mx-auto w-auto" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        </div>


                    </div>


                </div> :
                ""
            }
            {showBanner && bannerContent && (
                <div className="w-65">
                    <Banner title={bannerContent.title} error={bannerError} description={bannerContent.description} setShowBanner={setShowBanner} />
                </div>
            )

            }
            <div className="flex md:flex-row flex-wrap p-1 overflow-x-auto text-xs">
                {/* <button className={" button-outline-off hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 ml-2 " + ((!isActiveFilter && statuCobru === 0) ? 'bg-green-600' : 'bg-gray-500')} onClick={handleFilter} key="0" value="0">
                    <span>Todos</span>
                </button> */}
                <button className={" button-outline-off hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuCobru === 1 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => handleFilter(1)} key="1" value="1">
                    <span>Creados</span>
                </button>
                <button className={"button-outline-off hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuCobru === 3 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => handleFilter(3)} key="3" value="3">
                    <span>Pagados</span>
                </button>
                <button className={"button-outline-off hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuCobru === 5 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => handleFilter(5)} key="5" value="5">
                    <span>Expirados</span>
                </button>
                <button className={"button-outline-off hover:bg-green-600 text-white font-bold py-1 px-4 rounded-full h-8 mr-1 mt-3 " + (isActiveFilter && statuCobru === 4 ? 'bg-green-600' : 'bg-gray-500')} onClick={() => handleFilter(4)} key="4" value="4">
                    <span>Manual</span>
                </button>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }} className='text-white font-bold py-1  px-4 rounded-full h-8 mr-1 mt-3 bg-gray-500'>

                    <DateRangePicker
                        initialSettings={{
                            startDate: start_date,
                            endDate: end_date,
                            ranges: ranges,
                            locale: locale,
                            maxDate: maxDate
                        }}
                        onEvent={handleEvent}
                    >
                        <button style={{ alignItems: 'center', display: 'flex', }} className='button-outline-off'>
                            <div className="px-1 text-white float-left">
                                <BsFillCalendarFill />
                            </div>
                            <span className="font-bold text-xs float-left">{label}</span>
                            <div className="float-left text-white">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </button>
                    </DateRangePicker>

                </div>




            </div>
            {DescripcionManualModal && <ModalMAnualDescripcion data={dataModal} modal={() => setDescripcionManualModal()} open={DescripcionManualModal} />}
            {ModalManual && <ModalManualCobrus CobruManual={(e) => CobruManual(e)} setModalManuel={setModalManuel} />}
            {openModal === true &&
                <ModalCobrar modal={() => setOpenModal()} open={openModal} data={dataModal} />
            }
            {statuCobru != 4 &&

                <div style={{ marginTop: '20px' }}>
                    <DataTable
                        data={currentCobrus}
                        columns={columns}
                        customStyles={customStyles}
                        noHeader={true}
                        fixedHeader={true}
                        highlightOnHover={true}
                        pagination={pagination}
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50]}
                        noDataComponent={"Toca algun filtro"}


                        paginationComponentOptions={
                            {
                                noRowsPerPage: false,
                                rowsPerPageText: 'Filas por página',
                                rangeSeparatorText: 'de'
                            }
                        }
                        onSort={handleSort}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressPending={loading}
                        progressComponent={
                            <div className="flex md:flex-row flex-wrap p-4">
                                <div className="w-full">
                                    <div style={{ height: '50vh' }}>
                                        <div className='h-full w-full flex items-center justify-center'>
                                            <CircularProgress size={75} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    />
                </div>
            }
            {statuCobru === 4 &&
                <div style={{ marginTop: '20px' }}>
                    <DataTable
                        data={manualData}
                        columns={columnsManual}
                        customStyles={customStyles}
                        noHeader={true}
                        fixedHeader={true}
                        highlightOnHover={true}
                        pagination={pagination}
                        paginationServer
                        paginationTotalRows={ModalTotalRows}
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50]}
                        noDataComponent={"No hay movimientos disponibles"}


                        paginationComponentOptions={
                            {
                                noRowsPerPage: false,
                                rowsPerPageText: 'Filas por página',
                                rangeSeparatorText: 'de'
                            }
                        }
                        onSort={handleSort}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressPending={loading}
                        progressComponent={
                            <div className="flex md:flex-row flex-wrap p-4">
                                <div className="w-full">
                                    <div style={{ height: '50vh' }}>
                                        <div className='h-full w-full flex items-center justify-center'>
                                            <CircularProgress size={75} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </div>
            }

        </>
    ) : (
        <NoView />
    )
};

export default Cobrus;