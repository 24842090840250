
import React, { useState, useEffect, useContext } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { BsFillCalendarFill } from "react-icons/bs";
import Apexcharts from 'react-apexcharts';
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';
import { localeDataPicker, FormatAmount } from '../common/functions';
import NoView from '../layouts/NoView';
import { AuthContext } from '../../context/AuthContext';
import AxioInstance from '../../axios/AxiosConfig';

const Resumen = (props) => {

    const [cobrus, setCobrus] = useState(props.cobrus)
    const [state0, setState0] = useState(props.state0)
    const [state1, setState1] = useState(props.state1)
    const [state3, setState3] = useState(props.state3)
    const [amount0, setAmount0] = useState(0)
    const [amount1, setAmount1] = useState(0)
    const [amount3, setAmount3] = useState(0)
    const [totalAmount, setTotalAmount] = useState('$0')

    const [totalCobrus, setTotalCobrus] = useState('$0')
    const [balance, setBalance] = useState(props.totalBalance.balance)
    const [pendingBalance, setPendingBalance] = useState(props.totalBalance.pendingBalance)
    const [pendingSaved, setPendingSaved] = useState(props.totalBalance.pendingSaved)
    const [balance_saved, setbalance_saved] = useState('$0')
    const [pendingTasks, setPendingTasks] = useState([])
    const [labelArea, setLabelArea] = useState('')
    const [labelPie, setLabelPie] = useState('')
    const [labelBox, setLabelBox] = useState('')
    const [labelCobrus, setLabelCobrus] = useState('')
    const [startDate, setStartDate] = useState(() => moment().startOf('month'))
    const [endDate, setEndDate] = useState(() => moment().endOf('month'))
    const [maxDate, setMaxDate] = useState(() => moment())
    const [ranges, setRanges] = useState({
        'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Últimos 7 Dias': [moment().subtract(6, 'days'), moment()],
        'Últimos 30 Días': [moment().subtract(29, 'days'), moment()],
        'Último Mes': [moment().startOf('month'), moment().endOf('month')],
        'Mes Anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    })
    //CHART DATA 
    const [series, setSeries] = useState([{
        name: "RECAUDOS",
        data: []
    }])
    const [options, setOptions] = useState({
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },

        title: {
            text: 'Recaudos',
            align: 'center',
        },
        tooltip: {
            theme: 'dark'
        },
        labels: [],
        xaxis: {
            type: 'datetime',
        },
        yaxis: {
            opposite: false
        },
        legend: {
            horizontalAlign: 'right'
        }
    })
    //PIE CHART CONFIG
    const [seriesPie, setSeriesPie] = useState([])
    const [optionsPie, setOptionsPie] = useState({
        chart: {
            width: 400,
            type: 'pie',
        },
        legend: {
            position: 'top',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '10px',
            offsetX: 0,
            offsetY: 0,
        },
        labels: ['Baloto', 'Efecty', 'PSE', 'Tarjeta de Credito', 'Cobru', 'Otros'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 400
                },
                legend: {
                    position: 'bottom',
                    left: 0,
                }
            }
        }]
    })

    const { getAuthenticatedHeaders, permissions } = useContext(AuthContext)
    const canViewResumen = permissions.permission_dashboard

    useEffect(() => {
        if (!isEmpty(cobrus)) {
            updateDataChartSale(startDate, endDate, cobrus);
            updateDataChartPaymentMethod(startDate, endDate, cobrus);
            updateTotalDataChartCobrusPaid(startDate, endDate, cobrus);
        }
    }, [startDate, endDate, cobrus])

    const isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    const handleEvent = (event, picker) => {
        if (event.type === 'apply') {
            setStartDate(picker.startDate)
            setEndDate(picker.endDate)

            updateDataChartSale(startDate, endDate, cobrus);
        }
    }

    const handleEventPaymentMethod = (event, picker) => {
        if (event.type === 'apply') {
            setStartDate(picker.startDate)
            setEndDate(picker.endDate)
            updateDataChartPaymentMethod(startDate, endDate, cobrus);
        }
    }

    const handleEventCobrusPaid = (event, picker) => {

        if (event.type === 'apply') {
            setStartDate(picker.startDate.utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
            setEndDate(picker.endDate.subtract(5, 'hours').utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))

            updateTotalDataChartCobrusPaid(startDate, endDate, cobrus);
        }
    }
    const getBalance = async () => {

        try {
            const response = await AxioInstance.post(`/api/getBalance`, {
                headers: await getAuthenticatedHeaders(),
            });

            if (response.data.status === 200) {
                let pendingBalance = "$0";
                let pendingSaved = "$0";
                let data = response.data.data;

                if (data.pendingBalance > 0) {
                    pendingBalance = FormatAmount(Math.round(data.pendingBalance));
                };
                if (data.saved > 0) {
                    pendingSaved = FormatAmount(Math.round(data.saved));
                };

                setBalance(
                    FormatAmount(data.balance)
                )

            }

        } catch (error) {

        }
    };
    const updateTotalDataChartCobrusPaid = (startDate, endDate, cobrus) => {

        let startCobrus = startDate.format('MMM D YYYY');
        let endCobrus = endDate.format('MMM D YYYY');
        let labelCobrus = startCobrus + ' - ' + endCobrus;
        if (startCobrus === endCobrus) {
            setLabelCobrus(startCobrus)
        } else {
            setLabelCobrus(labelCobrus)
        }

        let totalAmount = 0;
        let items = _.filter(cobrus, function (o) {
            return (o.date_payed >= startDate && o.date_payed <= endDate && o.state === 3)
        });

        let totalCobrus = items.length;

        items.forEach(element => {
            let digits = element.amount.toString().replace(/\D/g, "");
            totalAmount += parseInt(digits);
        });

        totalAmount = FormatAmount(totalAmount);
        totalCobrus = items.length;

        setTotalAmount(totalAmount)
        setTotalCobrus(totalCobrus)
    }
    const TotalCrados = () => {


    }
    const TotalEnProceso = () => {


    }
    const TotalConsignado = () => {


    }
    useEffect(() => {
        getBalance()
        getState(0)
        getState(1)
        getState(3)
    }, [])
    const updateDataChartSale = (startDate, endDate, cobrus) => {

        let startArea = startDate.format('MMM D YYYY');
        let endArea = endDate.format('MMM D YYYY');
        let labelArea = startArea + ' - ' + endArea;
        if (startArea === endArea) {
            setLabelArea(startArea)
        } else {
            setLabelArea(labelArea)
        }

        let labels = [];
        let amounts = [];
        let items = _.filter(cobrus, function (o) {
            return (o.date_payed >= startDate && o.date_payed <= endDate && o.state === 3)
        });



        items.forEach(element => {
            amounts.push(parseInt(element.amount.replace(/\D/g, '')));
            labels.push(moment(element.date_created._i).format('YYYY-MM-DD'));
        });

        setSeries([{
            name: "RECAUDOS",
            data: amounts
        }])
        setOptions({
            labels: labels,

        })

    }


    const updateDataChartPaymentMethod = (startDate, endDate, cobrus) => {
        let startPie = startDate.format('MMM D YYYY');
        let endPie = endDate.format('MMM D YYYY');
        let labelPie = startPie + ' - ' + endPie;
        if (startPie === endPie) {
            setLabelPie(startPie)
        } else {
            setLabelPie(labelPie)
        }

        const items = _.filter(cobrus, (o) => {
            return (o.date_payed >= startDate && o.date_payed <= endDate /* && o.state === 3 */)

        })

        /*  let items = _.filter(cobrus, function(o){
             return (o.fields.date_payed >= startDate && o.fields.date_payed <= endDate && o.fields.state === 3)
         }); */

        let baloto = 0;
        let efecty = 0;
        let pse = 0;
        let tc = 0;
        let cobru = 0;
        let otros = 0;

        items.forEach(element => {


            switch (element.payment_method) {
                case "baloto":
                    baloto++;
                    break;
                case "efecty":
                    efecty++;
                    break;
                case "credit_card":
                    tc++;
                    break;
                case "pse":
                    pse++;
                    break;
                case "cobru":
                    cobru++;
                    break;
                default:
                    otros++;
            }
        });

        setSeriesPie([baloto, efecty, pse, tc, cobru, otros])


    }
    const getState = async (state) => {

        try {
            const response = await AxioInstance.post(`/api/get_state/`, {
                headers: await getAuthenticatedHeaders(),
                state: state
            });
            if (response.data.status === 200) {
                if (response.data.data.data[0].state === 0) {
                    setAmount0(response.data.data.data[0].total_amount)
                    setState0(response.data.data.data[0].total_count);
                }
                else if (response.data.data.data[0].state === 1) {
                    setAmount1(response.data.data.data[0].total_amount)
                    setState1(response.data.data.data[0].total_count);
                } else {
                    setAmount3(response.data.data.data[0].total_amount)
                    setState3(response.data.data.data[0].total_count);
                }

            }

        } catch (error) {

        }
    };

    function formatearMontoEnPesos(monto) {
        // Convertir el monto a un número y asegurarnos de que sea válido
        const montoNumerico = parseFloat(monto);
        if (isNaN(montoNumerico)) {
            return "Monto inválido";
        }

        // Redondear el monto a dos decimales
        const montoRedondeado = montoNumerico.toFixed(2);

        // Verificar si el monto es igual a 0.00
        if (montoRedondeado === "0.00") {
            return "0";
        }

        // Eliminar ceros a la derecha si existen
        const montoFormateado = montoRedondeado.replace(/\.?0+$/, '');

        // Agregar separadores de miles y el símbolo de peso colombiano
        const separadorMiles = ".";
        return "$ " + montoFormateado.replace(/\B(?=(\d{3})+(?!\d))/g, separadorMiles);
    }
    return (
        canViewResumen ? (
            <>
                <div className="flex md:flex-row flex-wrap">
                    {/* <div className="w-full md:w-3/5 p-2 text-center text-gray-200">
                        <div className="flex flex-wrap bg-white shadow-lg rounded-lg">
                            <div className="w-full p-3 md:w-1/3">
                                <div className="text-gray-700 text-left">
                                    <p className="text-lg text-left">Ventas</p>
                                </div>
                            </div>
                            <div className="w-full p-3 md:w-2/3">
                                <div className="text-gray-700 md:text-center text-right">
                                    <div className="md:text-right text-center">
                                        <DateRangePicker
                                            initialSettings={{
                                                startDate: startDate,
                                                endDate: endDate,
                                                ranges: ranges,
                                                locale: localeDataPicker,
                                                maxDate: maxDate
                                            }}
                                            onEvent={handleEvent}
                                        //onCallback={handleCallback}
                                        >
                                            <button>
                                                <div className="px-1 text-gray-700 float-left text-lg">
                                                    <BsFillCalendarFill />
                                                </div>
                                                <span className="font-bold text-xs float-left">{labelArea}</span>
                                                <div className="float-left text-gray-700">
                                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>
                                            </button>


                                        </DateRangePicker>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full p-1">
                                <div id="chart">
                                    <Apexcharts options={options} series={series} type="area" height={210} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/*  <div className="w-full md:w-2/5 p-2 text-center text-gray-700">
                        <div className="flex flex-wrap bg-white shadow-lg rounded-lg">
                            <div className="w-full p-2">
                                <div className="text-gray-700 text-left">
                                    <p className="text-lg text-left">Medios de Pago</p>
                                </div>
                            </div>
                            <div className="w-full p-2 pb-1">

                                <div className="text-gray-700 text-rigth">
                                    <div className="text-xs text-rigth">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="w-full" >
                                {
                                    seriesPie != "" &&
                                    <div id="chart" style={{ height: 229 }}>


                                        <Apexcharts options={optionsPie} series={seriesPie} type="pie" height={229} sytle={{ width: "100%" }} />


                                    </div>
                                }
                                {
                                    seriesPie == "" &&
                                    <div id="chart" style={{ height: 229 }}>


                                        <Apexcharts options={optionsPie} series={seriesPie} type="pie" height={"100%"} />


                                    </div>
                                }

                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="flex md:flex-row flex-wrap pl-2">
                    <div className="w-full md:w-1/4 p-4 text-center pl-0">
                        <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                            <div className="px-2 py-4">
                                <div className="font-bold text-xl mb-2 mt-10 text-black">
                                    {balance}
                                </div>
                                <p className="text-gray-700 text-sm">
                                    Saldo disponible
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-1/4 p-4 text-center pl-0">
                        <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                            <div className="w-full px-1 py-2">
                                <div className="text-gray-700 text-rigth">
                                    <div className="text-xs text-rigth">
                                        <DateRangePicker
                                            initialSettings={{
                                                startDate: startDate,
                                                endDate: endDate,
                                                ranges: ranges,
                                                locale: localeDataPicker,
                                                maxDate: maxDate
                                            }}
                                            onEvent={handleEventCobrusPaid}
                                        >
                                            <button>
                                                <div className="px-1 text-gray-700 float-left">
                                                    <BsFillCalendarFill />
                                                </div>
                                                <span className="font-bold text-xs float-left">{labelCobrus}</span>
                                                <div className="float-left text-gray-700">
                                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                </div>
                                            </button>
                                        </DateRangePicker>
                                    </div>
                                </div>
                            </div>
                            <div className="px-2 py-4">
                                <div className="font-bold text-xl mb-2 text-black">
                                    {totalAmount}
                                </div>
                                <p className="text-gray-700 text-sm">
                                    {totalCobrus} Cobrus pagados
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-1/4 p-4 text-center pl-0">
                        <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                            <div className="px-2 py-4">
                                <div className="font-bold text-xl mb-2 text-black mt-10">
                                    {pendingBalance}
                                </div>
                                <p className="text-gray-700 text-sm">
                                    Saldo en canje
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/4 p-4 text-center pl-0">
                        <div className="max-w-sm bg-white rounded overflow-hidden shadow-lg">
                            <div className="px-2 py-4">
                                <div className="font-bold text-xl mb-2 text-black mt-10">
                                    {props.balance_saved ? FormatAmount(props.balance_saved) : '$0'}
                                </div>
                                <p className="text-gray-700 text-sm">
                                    Saldo en bolsa
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        ) : (
            <NoView />
        )

    )

}

export default Resumen